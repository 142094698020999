// @flow
import * as React from 'react';

// Components
import CircularProgress from '@material-ui/core/CircularProgress';

import { Caption } from '@components/Typography';

// Styles
import styles from './Loading.scss';

export const Loading = () => (
  <>
    <CircularProgress />
    <Caption className={styles.Caption}>Uploading...</Caption>
  </>
);

export default Loading;
