// @flow
import * as React from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { ThemeProvider } from '@material-ui/core/styles';

// Config
import { THEME } from '@config';

// Views
import Main from '@views/Main';
import Builder from '@views/Builder';
import Profile from '@views/Profile';

// Components
import Loader from '@components/Loader';

// Hooks
import useApp from './hooks';

// Styles
import styles from './App.scss';
import SearchResult from './views/Search/SearchResult';

type AppTypes = {
  pages: Array<Object>,
  loading?: Boolean
};

const App = ({ pages, loading }: AppTypes) => {

  const { pathname } = useApp()

  React.useEffect(() => {
    window.scrollTo(0,0);
    console.log('new');

    // Накой спросишь ты, поверь так нужно, отвечу я.
    // Мега фича, для перехода между модулями портала
    return(() => {
      if (!window.location.pathname.includes('search-result') && !window.location.pathname.includes('wiki')) {
        window.location.reload()
      }
    })
  }, [pathname]);

  return (
    <ThemeProvider theme={THEME}>
      <div className={styles.Root}>
        <Switch>
          {/* <Route path="/guidelines">
            <div> GuideLine page </div>
          </Route> */}
          {/* <Route exact path="/wiki">
            <Redirect exact push to={`/wiki/${get(pages, '[0].slug')}`} />
          </Route> */}
          <Route path="/wiki/:any">
            <Builder />
          </Route>
          <Route path="/search-result">
            <SearchResult />
          </Route>
          <Route exact path="/wiki" component={Main} />
          {/* <Route component={Profile} /> */}
        </Switch>
        {loading && <Loader className={styles.Loader} />}
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: Object): Object => ({
  loading: get(state, 'loader.loading', false),
  pages: get(state, 'boards.list.wiki.pages', []),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
