// @flow

// Libraries
import classNames from 'classnames';
import React, { Element } from 'react';
import { Field } from 'redux-form';

// Components
import Dropzone from '@components/Dropzone';

import Base from './Base';

// Styles
import styles from './Input.scss';

type PropTypes = {
  name: String,
  validate?: Function[],
  classes?: any,
  className?: String,
  meta?: Object
}

const renderField = (props: any): Element<'div'> => {
  const {
    input,
    meta: { touched, error, invalid },
    classes,
    className,
  } = props;
  return (
    <Base {...props}>
      <Dropzone
        {...input}
        {...props}
        className={classNames(
          className,
          styles.Dropzone,
          {
            [styles.DropzoneIsError]: touched && invalid
          }
        )}
        classes={classes}
        error={touched && invalid}
      />
      {
        touched && error &&
        <span className={classNames(styles.ErrorMessage)}>{error}</span>
      }
    </Base>
  );
};

const FormDropzone = (props: PropTypes): Element<Field> => {
  const {
    name,
    validate,
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      type="file"
      component={renderField}
      {...props}
    />
)};

export default FormDropzone;
