// @flow

// Libraries
import React, { Element } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';
import Form from '@components/Form';

// Styles
import styles from './EditMode.scss';

type PropTypes = {
  onSubmit: Function;
  children: any;
}

const EditMode = ({ onSubmit, children }: PropTypes): Element<Box> => {
  return (
    <Box className={styles.Box}>
      <Form onSubmit={onSubmit}>
        { children }
      </Form>
    </Box>
  )
};

const EditModeForm = reduxForm({
  form: 'editCards',
  touchOnChange: true,
})(EditMode);

const mapStateToProps = (state: Object): Object => ({
  formValues: get(state, 'form.editCards.values'),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(EditModeForm);

