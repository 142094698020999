// Libraries
import { loader } from 'graphql.macro';
import { useMemo, useState } from 'react';
import { useMutation } from 'react-apollo';

// GraphQL
const assetMutation = loader('../graphql/mutations/setFavoriteSingleAsset.gql');
const collectionMutation = loader('../graphql/mutations/setFavoriteCollection.gql');

type Props = {
  id: string,
  type: string,
  isFavorite: boolean
};

export const useToggleFavorites = ({
  id,
  type,
  isFavorite: initIsFavorite
}: Props) => {
  const [isFavorite, setIsFavorite] = useState(initIsFavorite);

  const assetType = type.split('::').pop();
  const mutation = useMemo(() => {
    switch (assetType) {
      case 'SingleAsset':
        return assetMutation;
      case 'Collection':
        return collectionMutation;
      default:
        // Need something as stub, can't use null
        return assetMutation;
    }
  }, [assetType]);

  const [mutationFavorite, { loading, error }] = useMutation(mutation, {
    fetchPolicy: 'no-cache',
    onCompleted: res => {
      if (assetType === 'Collection') {
        setIsFavorite(res?.setFavoriteCollection?.collection?.isFavorite);
      } else if (assetType === 'SingleAsset') {
        setIsFavorite(res?.setFavoriteSingleAsset?.singleAsset?.isFavorite);
      }
    },
    onError: error => {
      console.error(error);
    }
  });

  const updateAsset = async newIsFavorite => {
    if (!['SingleAsset', 'Collection'].includes(assetType)) {
      return null;
    }

    const { data } = await mutationFavorite({
      variables: {
        input: {
          id,
          isFavorite: newIsFavorite
        }
      }
    });

    switch (assetType) {
      case 'SingleAsset':
        return data?.setFavoriteSingleAsset?.singleAsset?.isFavorite;
      case 'Collection':
        return data?.setFavoriteCollection?.collection?.isFavorite;
      default:
        return null;
    }
  };

  const addToFavorites = () => {
    return updateAsset(true);
  };
  const removeFromFavorites = () => {
    return updateAsset(false);
  };

  const toggleIsFavorite = () => {
    return updateAsset(!isFavorite);
  };

  return {
    addToFavorites,
    removeFromFavorites,
    toggleIsFavorite,
    isFavorite,
    loading,
    error
  };
};
