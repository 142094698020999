// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get, find, isFunction } from 'lodash';

// Assets
import arrow from '@assets/icons/small_arrow.svg';

// Styles
import styles from './Selector.scss';

// Types
type Types = {
  onChangeHandler: (typeStyle: string) => void;
  data: any,
  value: string,
  classes: {
    root?: string,
    textBlock?: string,
    dropDown?: string,
    dropDownItem?: string,
    static?: string,
  };
};

const Selector = ({ data, onChangeHandler, classes, value }: Types) => {
    
  const [isOpen, setOpenState] = React.useState(false );
  const currentAction = find(data, {value});

  const changeStyleHandler = (typeStyle: string, title: string, icon?: string) => {
    isFunction(onChangeHandler) && onChangeHandler(typeStyle);
    setOpenState(false);
  };

  return (
    <div className={classNames(styles.Root, classes?.root)}>
      <div onClick={() => {setOpenState(!isOpen)}} className={classNames(styles.Static, classes?.static, classes?.textBlock)}>
        <div>
          {get(currentAction, 'icon', '')}
          <span>{get(currentAction, 'title', '')}</span>
        </div>
        <img src={arrow} alt="arrow" />
      </div>

      { isOpen && (
        <div className={classNames(styles.DropDown, classes?.dropDown)}>
          {data.map((item) => (
            <div
              key={item.id}
              className={classNames(styles.DropDownItem, classes?.dropDownItem, {[styles.DropDownItemActive]: currentAction.title === item.title})}
              onClick={() => {changeStyleHandler(item.value, item.title, item.icon)}}
            >
              {item.icon && get(item, 'icon', '')}
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  )
};

// Exports
export default Selector;
