// @flow

// Libraries
import React, { Element } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';

// Components
import SearchResultForm from './SearchForm';

const Search = ({ classnames, hide, isLogin }: any): Element<SearchResultForm> => {
  const history = useHistory();

  const onSubmit = (formData) => {
    history.push(`/search-result/${encodeURI(get(formData, 'search'))}`);
  };

  return (
    <SearchResultForm
      hide={hide}
      isLogin={isLogin}
      classnames={classnames}
      onSubmit={onSubmit}
    />
  )
};

export default Search;
