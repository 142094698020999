// @flow

// Libraries
import * as React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import arrayMove from 'array-move';
import { get, set } from 'lodash';

// Components
import Slide from '../Dropzone';

// Styles
import styles from './Carousel.scss';

// Types
import { TYPES } from '@components/Dropzone/data';
import { getEntity } from '@services/boards/data/entities';

// Context
import LongReadContext from '../../containers/LongRead/context';

// Icons
import { ReactComponent as PrevIcon } from '@assets/icons/Carousel/slider_arrow_prev.svg';
import { ReactComponent as NextIcon} from '@assets/icons/Carousel/slider_arrow_next.svg';

// Types
type Types = {
  selected: Boolean,
  withCaption?: Boolean,
  onUpdate: () => void,
  data?: Object,
};

const Carousel = React.forwardRef(({
  selected,
  onUpdate,
  data,
  ...props
}: Types, ref) => {

  const CONTEXT = React.useContext(LongReadContext);

  const isEdit = get(CONTEXT, 'isEdit');

  const slides = get(data, 'entities', []);
  let sliderRef = React.useRef();

  const SETTINGS = {
    // adaptiveHeight: true,
    arrows: false,
    draggable: true,
    infinite: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    appendDots: dots => (
      <div className={styles.CarouselAction}>
        <div
          className={classNames(
            styles.PrevButton,
            {
              [styles.PrevButtonIsDisabled]: get(dots, '[0].props.className')
            }
          )}
          onClick={() => sliderRef.slickPrev()}
        >
          <PrevIcon />
        </div>

        <div className={styles.Dots}>
          {dots}
        </div>

        {isEdit && (<button className={styles.AddSlideButton} onClick={addSlideHandler}>+</button>)}

        <div
          className={classNames(
            styles.NextButton,
            {
              [styles.NextButtonIsDisabled]: get(dots, `[${dots.length - 1}].props.className`)
            }
          )}
          onClick={() => sliderRef.slickNext()}
        >
          <NextIcon />
        </div>
      </div>
    ),
    customPaging: i => (
      <div
        key={i}
        className={classNames(styles.Dot)}
      />
    ),
  };

  React.useEffect(() => {
    if (slides.length > 2) {
      sliderRef && sliderRef.slickGoTo(slides.length + 1)
    }
  }, [slides]);

  const addSlideHandler = () => {
    const newSlides = [
      ...slides,
      getEntity({
        type: TYPES.IMAGE,
      })
    ];
    onUpdate({
      entities: newSlides
    });
  };

  const moveSlideHandler = (method: string, slideIndex: string) => {
    onUpdate({
      entities: arrayMove(
        [...slides],
        slideIndex,
        slideIndex + (method === 'forward' ? 1 : -1))
    });
  };

  const updateSlideHandler = slideIndex => props => {
    const newSlides = [...slides];
    const currentSlide = get(newSlides, `[${slideIndex}]`, {});
    set(newSlides, `[${slideIndex}]`, {
      ...currentSlide,
      ...props
    });
    onUpdate({
      entities: newSlides
    });
  };
  return (
    <div
      className={styles.Root}
      ref={ref}
    >
      <Slider ref={ref => {sliderRef = ref}} {...SETTINGS}>
        {slides.map((item, index) => {
          if (true) {
            return (
              <Slide
                selected={selected}
                slidesLength={slides.length}
                slideIndex={index}
                key={get(item, 'id')}
                data={item}
                onUpdate={updateSlideHandler(index)}
                onSlide={moveSlideHandler}
                {...props}
              />
            )
          }
        })}
      </Slider>
    </div>
  )
});

// Exports
export default Carousel;
