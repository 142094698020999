// @flow

// Libraries
import * as React from 'react';
import classNames from "classnames";
import Carousel from "react-slick";
import { get, set, map } from 'lodash';

// Components
import Slide from '../Dropzone';
import Slider from '@material-ui/core/Slider';

// Styles
import styles from './Slider.scss';

// Context
import LongReadContext from '../../containers/LongRead/context';

type SliderImagesTypes = {
  selected: Boolean,
  block: {
    getEntityAt: (number) => void,
  },
  contentState: {
    getEntity: (string) => void,
    replaceEntityData: (string) => void,
  },
  onUpdate: () => void,
  data?: Object
};

const SliderImages = React.forwardRef(({
  selected,
  contentState,
  block,
  data,
  onUpdate,
  ...props
}: SliderImagesTypes, ref) => {

  const CONTEXT = React.useContext(LongReadContext);
  const isEdit = get(CONTEXT, 'isEdit');

  const [value, setValue] = React.useState(1);

  let carouselRef = React.useRef();
  const slides = get(data, 'entities', []);

  React.useEffect(() => {
    if (!carouselRef || isEdit) { return; }
    const slidesNodes = get(carouselRef, 'innerSlider.list.children[0].children', []);
    const calculated = value * 0.01;
    const incrased = 1 - calculated;
    map(slidesNodes, (slide, index) => {
      set(slidesNodes, `[${index}].style.opacity`, index === slidesNodes.length - 1 ? calculated : incrased);
    });

  }, [carouselRef, value]);

  const SETTINGS = {
    adaptiveHeight: true,
    arrows: false,
    draggable: isEdit,
    infinite: false,
    dots: isEdit,
    fade: !isEdit,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    appendDots: dots => (
      <div className={styles.CarouselAction}>
        <button
          className={classNames(styles.PrevButton)}
          onClick={() => carouselRef.slickPrev()}
        />

        <div className={styles.Dots}>
          {dots}
        </div>

        <button
          className={classNames(styles.NextButton)}
          onClick={() => carouselRef.slickNext()}
        />
      </div>
    ),
    customPaging: i => (
      <div
        key={i}
        className={classNames(styles.Dot)}
      />
    ),
  };

  const updateSlideHandler = slideIndex => props => {
    const newSlides = [...slides];
    const currentSlide = get(newSlides, `[${slideIndex}]`, {});
    set(newSlides, `[${slideIndex}]`, {
      ...currentSlide,
      ...props
    });
    onUpdate({
      entities: newSlides
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      className={
        classNames(
          styles.Root,
          {
            [styles.RootIsRead]: !isEdit
          }
        )
      }
      ref={ref}
    >
      <Carousel
        ref={ref => { carouselRef = ref}}
        {...SETTINGS}
      >
        {slides.map((item, index) => (
          <Slide
            selected={selected}
            key={get(item, 'id')}
            data={item}
            onUpdate={updateSlideHandler(index)}
            {...props}
          />
        ))}
      </Carousel>
      { !isEdit
        && (
          <Slider
            className={styles.Slider}
            aria-label="show"
            value={value}
            onChange={handleChange}
          />
        )}
    </div>
  )
});

// Exports
export default SliderImages;
