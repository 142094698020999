// @flow
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

// Reducers
import services from '@services';

// Config
import { APP_NAME, APP_VERSION } from '@config';

const composeEnhancers: Function =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const migrations = {
  [APP_VERSION]: services
};

const persistedReducer = persistReducer(
  {
    key: APP_NAME,
    storage,
    version: APP_VERSION,
    blacklist: ['tab'],
    stateReconciler: hardSet,
    migrate: createMigrate(migrations),
  },
  services,
);

export default (): Object => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(
      applyMiddleware(thunk),
    ),
  );
  const persistor = persistStore(store);

  return { persistor, store };
};
