// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get, filter } from 'lodash';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import BaseDropzone from '@components/Dropzone';

import Selector from '../Selector';

// Styles
import styles from './Dropzone.scss';

// Hooks
import useDropzone from './hooks';

// Data
import { DO_OPTIONS } from './data';

type PropTypes = {
  className?: String,
  style?: Object,
  classes: {
    root?: string,
    imageContainer?: string,
    image?: string,
    editPanelContainer?: string,
    dropZone?: string,
    label?: string,
    editButtons?: {
      edit?: string,
      delete?: string,
    }
  },
  // type: String,
  onMouseMove?: Function,
  onMouseLeave?: Function,
  selected?: Boolean,
  data?: Any,
  onUpdate?: Function,
  withDo?: Boolean,
  doValue?: String,
  slideIndex?: Number,
  slidesLength?: Number,
  onSlide?: Function,
  withCaption?: Boolean,
  CONTEXT?: Any,
};

const Dropzone = ({
  className,
  style,
  classes,
  // type,
  onMouseMove,
  onMouseLeave,
  selected,
  data,
  onUpdate,
  withDo = false,
  doValue,
  slideIndex,
  slidesLength,
  onSlide,
  withCaption = true,
  ...props
}: PropTypes) => {
  const type = get(data, 'type');
  const {
    CONTEXT,
    loading,
    handleSlide,
    handleDo,
    handleLink,
    handleCaption,
    handleDrop,
    handleDelete,
  } = useDropzone({
    onUpdate,
    onSlide,
    data,
  });

  const isEdit = get(CONTEXT, 'isEdit');

  return (
    <div
      className={classNames(
        className,
        classes?.root,
        styles.Root,
        styles[`RootType${type}`],
        {
          [styles.RootIsSelected]: selected,
          [styles.RootIsEdit]: isEdit,
        }
      )}
      style={style}
      // ref={ref}
      // onMouseMove={onMouseMove}
      // onMouseLeave={onMouseLeave}
      // {...props}
    >
      {
        withDo
          && (isEdit 
            ? (
              <Selector
                data={DO_OPTIONS}
                onChangeHandler={handleDo}
                classes={{
                  root: selected ? styles.SelectActive : styles.Select,
                  textBlock: styles.TextBlock,
                  dropDown: styles.DropDown,
                  dropDownItem: styles.DropDownItem,
                  static: styles.StaticItem
                }}
                value={get(data, 'action')}
              />
            )
            : (
              <div className={styles.doIcon}>
                {get(filter(DO_OPTIONS, { value: get(data, 'action') }), '[0].icon')}
              </div>
            ))
      }
      <BaseDropzone
        className={classNames(
          classes?.dropzone,
          styles.Dropzone,
        )}
        loading={loading}
        type={type}
        onDropped={handleDrop}
        onLink={handleLink}
        onDelete={handleDelete}
        onSlide={handleSlide}
        data={data}
        disabled={!isEdit}
        onFocus={handlePrevent((event) => {
          CONTEXT.setReadOnlyState(true);
        })}
        onBlur={handlePrevent((event) => {
          CONTEXT.setReadOnlyState(false);
        })}
        slideIndex={slideIndex}
        slidesLength={slidesLength}
        {...props}
      />
      {withCaption && (
        !isEdit
          ? (
            <div className={styles.Caption}>
              {get(data, 'caption')}
            </div>
          )
          : (
            <input
              // onKeyUp={(event) => {
              //   event.preventDefault();
              //   event.stopPropagation();
              //   if (event.which === 13) {
              //     handleCaption(event.target.value);
              //     event.currentTarget.blur();
              //   }
              // }}
              disabled={!isEdit}
              onFocus={(event) => {
                event.preventDefault();
                event.stopPropagation();
                CONTEXT.setReadOnlyState(true);
              }}
              onBlur={(event) => {
                event.preventDefault();
                event.stopPropagation();
                CONTEXT.setReadOnlyState(false);
              }}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                handleCaption(get(event, 'currentTarget.value'));
              }}
              className={styles.Caption}
              type="text"
              placeholder="Enter caption text"
              style={{
                background: selected ? 'rgba(35, 209, 174, 0.24)' : 'transparent'
              }}
              defaultValue={get(data, 'caption')}
            />
          )
      )}
      <div className={styles.CoverBg} data-show={selected} />
    </div>
  )
};

// Exports
export default Dropzone;
