export const Block = {
  UNSTYLED: 'unstyled',
  IMAGE: 'IMAGE',
  DOUBLEIMAGE: '2IMAGE',
  TRIPPLEIMAGE: '3IMAGE',
  DOUBLEIMAGEWITDO: '2IMAGEWITHDO',
  TRIPPLEIMAGEWITDO: '3IMAGEWITHDO',
  SLIDER: 'SLIDER',
  CAROUSEL: 'CAROUSEL',
  BOLDTEXT: 'BOLD',
  LIST: 'unordered-list-item',
  LINK: 'LINK'
};

export const HANDLED = 'handled';

export const NOT_HANDLED = 'not_handled';

export const HOVER_TIMER = 50;

export const ContinuousBlocks = [
  Block.UNSTYLED,
  Block.IMAGE,
  Block.DOUBLEIMAGE,
  Block.TRIPPLEIMAGE,
  Block.DOUBLEIMAGEWITDO,
  Block.TRIPPLEIMAGEWITDO,
  Block.SLIDER,
  Block.CAROUSEL,
];

export default {
  Block
}
