export const VARIANT = {
  HORISONTAL: 'horisontal',
  VERTICAL: 'vertical'
}

export const MODE = {
  READ: 'read',
  EDIT: 'edit',
}

export const TYPE = {
  TEXT: 'text',
  LINK: 'link',
  ASSET: 'asset',
  COLLECTION: 'collection'
}
