import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ApolloProvider } from 'react-apollo';

// App
import App from './App';

// Store
import createStore from './store';
import { HISTORY, CLIENT } from '@config';

// Styles
// $FlowFixMe
// import '@fortawesome/fontawesome-pro/css/all.min.css';

// Utils
import * as serviceWorker from '@utils/serviceWorker';

// Create store
const { persistor, store } = createStore();

const $root: ?HTMLElement = document.getElementById('root');

$root &&
  ReactDOM.render(
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <ApolloProvider client={CLIENT}>
          <Router history={HISTORY}>
            <App />
          </Router>
        </ApolloProvider>
      </Provider>
    </PersistGate>,
    $root
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
