// @flow

// Libraries
import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import { get, isFunction } from 'lodash';

// Components
import Button from '@components/Button';

// Styles
import styles from './ActionButton.scss';

// Context
import AddButtonContext from '../AddButton/context';

// Types
type PropTypes = {
  item: {
    title: String,
    icon: String,
    subMenu: Array<{
      type: String,
      icon: String,
      title: String,
    }>
  }
}

const ActionButton = ({ item }: PropTypes) => {
  
  const { selectedItem, setSelectedItem, handleClickItem } = React.useContext(AddButtonContext);

  const [isOpenSubMenu, setOpenState] = React.useState(false);

  React.useEffect(() => {
    setOpenState(item === selectedItem);
  }, [selectedItem]);

  const submitSubItemAction = (item) => {
    setOpenState(false);
    isFunction(handleClickItem) && handleClickItem(item);
  };

  const handleClick = (item) => {
    item.subMenu ? setSelectedItem(item === selectedItem ? false : item) : submitSubItemAction(item);
  };

  return (
    <div className={styles.Root}>
      <Button
        className={styles.ItemButton}
        variant="text"
        onClick={() => handleClick(item)}
      >
        <img src={item.icon} alt={`icon-${item.title}`} />
        {item.title}
      </Button>
      {!!item.subMenu && isOpenSubMenu && (
        <div className={styles.SubMenu}>
          {item.subMenu.map((item) => {
            const {icon, title} = item;
            return (
              <div className={styles.SubMenuItem} key={uuid4()} onClick={() => handleClick(item)}>
                <img src={icon} alt="submenuIcon" />
                <span>{title}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
};

// Exports
export default ActionButton;
