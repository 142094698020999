export const ERROR_CONNECTION = 'Connection error, please try again later.';

export const ERROR_FIELD_REQUIRED = 'Required field.';
export const ERROR_FIELD_NUMBER = 'Only numbers are allowed.';

export const ERROR_LOGIN_FORMAT = 'E-mail entered incorrectly.';

export const ERROR_DATE_ADULT = 'You must be over 18 to access the site.';
export const ERROR_DATE_FORMAT = 'The date must be in the format dd.mm.yyyy.';

export const ERROR_EMAIL_FORMAT = 'E-mail must be in the format ivanov@example.ru.';

export const ERROR_PASSWORD_CHANGE = 'An error occurred while changing the password.';
export const ERROR_PASSWORD_MIN = 'The password must contain at least 3 characters.';
export const ERROR_PASSWORD_EQUAL = 'Password mismatch.';

export const ERROR_INCORRECT_DATA = 'Incorrect data entered.';
export const ERROR_BAD_CREDENTIALS = ERROR_INCORRECT_DATA;
export const ERROR_NOT_REGISTERED = 'This e-mail is not registered.';
export const ERROR_RESTORE_FAILED = 'Recovery error.';

export const ERROR_BAD_TOKEN = 'Invalid token.';
export const ERROR_EMPTY_TOKEN = 'An empty token.';

export const ERROR_IS_REGISTERED = 'This e-mail is already registered.';

export const ERROR_MANY_ATTEMPTS = 'Data request limit exceeded. Please try again later.';

export const ERROR_FILE_MAX_SIZE = 'The maximum file size should not exceed 5MB.';
export const ERROR_FILE_INCORRECT = 'The correct file must be specified.';

export const ERROR_USER_BLOCKED = 'User is blocked';

export const MESSAGES_MAP = {
  'User restore failed': ERROR_RESTORE_FAILED,
  'User not found': ERROR_INCORRECT_DATA,
  'User blocked': ERROR_USER_BLOCKED,
  validation_error: ERROR_INCORRECT_DATA,
  bad_token: ERROR_BAD_TOKEN,
  bad_user_token: ERROR_BAD_TOKEN,
  'Invalid username or password': ERROR_BAD_CREDENTIALS,
  'Invalid username or pin': ERROR_BAD_CREDENTIALS,
  bad_credentials: ERROR_BAD_CREDENTIALS,
  is_registered: ERROR_IS_REGISTERED,
  'The input.email has already been taken.': ERROR_IS_REGISTERED,
  'token is empty': ERROR_EMPTY_TOKEN,
  too_many_attempts: ERROR_MANY_ATTEMPTS
};
