// @flow
import classNames from 'classnames';
import { get } from 'lodash';
import * as React from 'react';

// Style
import style from './Base.scss';

const Base = (props: PropTypes): React.Element<'div'> => {
  const { children, maxLength, input, meta, classes } = props;
  const { value = '' } = input;
  const { error } = meta;
  const { length } = typeof value === 'number' ? value.toString() : value;

  const getLimitDisplay = () => {
    if (!maxLength) { return; }

    const limit: null | number = maxLength ? maxLength - length : null;

    return limit >= 0 && `${length}/${maxLength} symbols`;
  };

  return (
    <div
      className={classNames(get(classes, 'base'), style.Root)}
    >
      {children}
      {!error && !!maxLength && (
        <span className={style.Additional}>
          {getLimitDisplay()}
        </span>
      )}
    </div>
  );
};

export default Base;

export type PropTypes = {
  children: any,
  max?: number,
};
