// @flow

// Libraries
import React, { useEffect, useState, Element } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { change } from 'redux-form';
import {connect, useDispatch, useSelector} from 'react-redux';
import { filterPages } from '@services/boards/actions';
import { get } from 'lodash';

// Components
import Pagination from '@components/Pagination/Pagination';
import Page from '@containers/Page';
import Card from '@components/Card/Card';
import { TYPE, VARIANT } from '../../components/Card/data';

// Styles
import stylesMain from '../Main/Main.scss'
import styles from './SearchResult.scss';
import Search from './Search';
import {compose} from "recompose";

const countDefaultItems = 8;

const SearchResult = ({ isLogin }: any): Element<Page> => {
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const dispatch = useDispatch();
  const pages = useSelector(state => get(state, 'boards.list.wiki.pages'));
  const items = useSelector(state => get(state, 'boards.list.wiki.filtered'));
  const [data, setData] = useState(items);
  const page = Number(history.location.search.replace('?page=', ''));
  function getStarterElement(): number {
    return page === 0
      ? page
      : countDefaultItems * page - countDefaultItems;
  }

  function getLastElement(): number {
    return page === 0
      ? countDefaultItems
      : countDefaultItems * page;
  }

  useEffect(() => {
    items && setData(items.slice(getStarterElement(), getLastElement()));
  }, [items]);

  useEffect(() => {
    const query = decodeURI(pathname.replace('/search-result/', ''));
    query && dispatch(filterPages(query));
    dispatch(change('searchResult', 'search', query));
    if (!query) {
      setData(null);
    }
  }, [pathname, pages, search]);

  return (
    <Page
      classes={{
        content: stylesMain.Content,
        footer: data && data.length > 2 ? stylesMain.Footer : styles.Footer
      }}
    >
      {/* <Search classnames={{root: styles.Search}} /> */}
      <>
        <p className={styles.ResultsCount}>
          {
            !items || items.length === 0 || !data
              ? 'Nothing found'
              : `Found ${items.length} results`
          }
        </p>
        {
          <div className={styles.DataWrapper}>
            {data && data.map((result, index) => (
              <Card
                classes={{
                  root: styles.CardRoot,
                  title: styles.Card_Title,
                  description: styles.CardDescription,
                  name: styles.CardName
                }}
                variant={VARIANT.HORISONTAL}
                type={TYPE.TEXT}
                data={result}
                key={result.id}
                index={index}
              />
            ))}
          </div>
        }
        {
          (items && items.length > 8 && data) &&
          <Pagination count={items.length} />
        }
      </>
    </Page>
  )
};


const mapStateToProps = (state: Object): Object => ({
  isLogin: get(state, 'session.token', false),
});

// Exports
export default compose(connect(mapStateToProps, null))(SearchResult);
