// @flow
import classNames from 'classnames';
import * as React from 'react';

// Components
import Link from '@components/Link';
import BaseButton from '@material-ui/core/Button';

// Styles
import COLOR, { getColorClassName, type ColorType } from '@styles/colors';
import styles from './Button.scss';

const VARIANT = {
  CONTAINED: 'contained',
  OUTLINED: 'outlined',
  TEXT: 'text',
};

export type PropTypes = {
  children?: React.Node,
  classes?: {
    root?: String
  },
  className?: String,
  color?: ColorType,
  disabled?: Boolean,
  fullWidth?: Boolean,
  loaded?: Boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => ?void,
  to?: String,
  type?: String,
  variant?: 
    typeof VARIANT.CONTAINED
    | typeof VARIANT.OUTLINED
    | typeof VARIANT.TEXT,
  disableRipple?: Boolean
};

const Button = ({
  children,
  classes = {},
  className,
  color = COLOR.PRIMARY,
  disabled,
  fullWidth,
  loaded,
  onClick,
  to,
  type = 'button',
  variant = VARIANT.TEXT,
  disableRipple = true,
  ...props
}: PropTypes): React.Element<'button'> => {
  return (
    <BaseButton
      className={classNames(
        className,
        classes.root,
        styles.Root,
        getColorClassName('root', color, styles),
        {
          [styles.RootIsLoaded]: loaded,
          [styles.RootIsFullWidth]: fullWidth
        },
        {
          [styles.RootVariantContained]: variant === VARIANT.CONTAINED,
          [styles.RootVariantOutlined]: variant === VARIANT.OUTLINED,
          [styles.RootVariantText]: variant === VARIANT.TEXT,
        },
      )}
      disabled={disabled}
      onClick={onClick}
      to={to}
      type={type}
      // component={to ? Link : 'button'}
      color={color}
      variant={variant}
      disableRipple={disableRipple}
      {...props}
    >
      {loaded ? (
        <i className={classNames(styles.Loader, 'far fa-spinner-third')} />
      ) : (
        <>
          {children}
        </>
      )}
    </BaseButton>
  );
};

export default Button;
