//Libraries
import classNames from "classnames";
import { get } from "lodash";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Link, NavLink, useLocation } from "react-router-dom";
import { setActiveTab } from "../../services/tab/tab";

import styles from "./TableMenu.scss";

const Tab = ({ title, link }): JSX.Element | null => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const activeTab = useSelector(state => get(state, 'tab'));
  const isEdit = useSelector(state => get(state, 'session.isEdit', false));
  const className = classNames({
    [styles.Tab]: true,
    [styles.isEdit]: isEdit
  });

  if (isEdit) {
    return null;
  }

  if (!title && !isEdit) {
    return null;
  }

  if (!title && isEdit) {
    return (
      <div className={styles.Empty}>+Table Title</div>
    );
  }

  const setActive = (tab) => {
    dispatch(setActiveTab(tab));
    // if (tab === activeTab) {
    //   dispatch(setActiveTab(null));
    // }
    // else {
    //   dispatch(setActiveTab(tab));
    // }
  }

  return (
    <NavLink 
      to={'#'} 
      onClick={() => setActive(title)}
      className={classNames({
      [className]: true,
      [styles.Active]: activeTab === title
    })}>
      {title}
    </NavLink>
  )
};

export default memo(Tab);
