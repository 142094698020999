// @flow

// Libraries
import React, { Element, useState } from 'react';
import { SortableHandle } from 'react-sortable-hoc';

// Components
import { Card as Item, CardContent, CardMedia } from '@material-ui/core';
import { Delete } from '../../../containers/modals/main';

// import Dropzone from '@components/Dropzone';
import { Dropzone } from '@components/Form';
import Button from '@components/Button';

// Icons
import SaveIcon from '@material-ui/icons/Save';

import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg';
import { ReactComponent as DrugAndDrop } from '@assets/icons/drug_and_drop.svg';

import EditModeContent from '../EditModeContent';

// Hooks
import useEditCard from './hooks';

// Styles
import styles from './EditCard.scss';

const DragHandle = SortableHandle((): Element<'div'> => (
  <div className={styles.DrugAndDrop}><DrugAndDrop /></div>
));

const EditCard = (props: any): Element<Item> => {
  const {
    field,
    data,
    handleDeletePage,
    handleUpdatePage,
    handleDrop,
    handleDelete,
    isEdited,
    id,
    preview,
    title,
    name,
    description,
    isExternal,
    tab,
    fileMeta,
  } = useEditCard(props);
  const [isModal, setIsModal] = useState(false);

  const onDelete = () => {
    handleDeletePage({ variables: { id } });
    setIsModal(false);
  }

  return (
    <Item className={styles.EditCard}>
      <DragHandle />
      {
        // preview
        //   ?
        //     (
              
        //     )
        //   : <div className={styles.EmptyImg} />
      }
      <div className={styles.CardMediaContainer}>
        <CardMedia
          className={styles.EditModeCardMedia}
          // image={preview}
        >
          <Dropzone
            className={styles.Dropzone}
            name={`${field}.preview`}
            loading={false}
            type="thumbnail"
            onDropped={handleDrop}
            onDelete={handleDelete}
            data={{
              src: preview
            }}
          />
        </CardMedia>
      </div>
      <CardContent className={styles.EditModeContent}>
        <EditModeContent result={field} data={data} />
      </CardContent>
      <div className={styles.Actions}>
        {
          isEdited && (
            <Button
              size="small"
              startIcon={<SaveIcon />}
              className={styles.Save}
              onClick={(): void => handleUpdatePage({
                variables: {
                  id,
                  title,
                  name,
                  description,
                  isExternal,
                  tab,
                  ...fileMeta
                } 
              })}
            />
          )
        }
        <Button
          size="small"
          startIcon={<TrashIcon />}
          className={styles.Trash}
          onClick={(): void => setIsModal(true)}
        />
      </div>
      <Delete
          name="card"
          title={title}
          opened={isModal}
          handleClose={() => setIsModal(false)}
          handleDelete={onDelete}
        />
    </Item>
  )
}

export default EditCard;
