// @flow

// Libraries
import React, { Element } from 'react';
import classNames from 'classnames';
import Container from '@material-ui/core/Container';

// Components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { CookieConsent } from '../../components/CookieConsent';

// Styles
import styles from './Page.scss';

// Types
type PAGE_PROPS_TYPES = {
  children: any,
  classes: {
    root?: String,
    content?: String,
    header?: String,
    footer?: String
  },
  isHideNav?: Boolean,
  isHasSandwich?: Boolean,
  actionPanel?: any,
  onMode?: Function,
  menuItems?: Array,
};

const Page = ({
  children,
  classes,
  isHideNav,
  isHasSandwich,
  actionPanel,
  onMode,
  menuItems,
  ...props
}: PAGE_PROPS_TYPES): Element<'div'> => {
  return (
    <div
      className={
        classNames(
          classes.root,
          styles.Root
        )
      }
      {...props}
    >
      <Header
        className={classNames(classes.header)}
        isHideNav={isHideNav}
        isHasSandwich={isHasSandwich}
        actionPanel={actionPanel}
        onMode={onMode}
        menuItems={menuItems}
      />
      <Container
        className={
          classNames(
            classes.content,
            styles.Content,
          )
        }
        maxWidth="lg"
      >
        {children}
      </Container>
      <Footer className={classNames(classes.footer)} />
      <CookieConsent/>
    </div>
  )
};

export default Page;
