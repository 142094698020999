// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { get } from 'lodash';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Button from '@components/Button';

// Styles
import styles from './Preview.scss';

// Data
import { TYPES } from '../data';

// Contexts
import DropzoneContext from '../context';

type PropTypes = {
  classes: {
    root?: string,
    imageContainer?: string,
    image?: string,
    editPanelContainer?: string,
    dropZone?: string,
    label?: string,
    editButtons?: {
      edit?: string,
      delete?: string,
    }
  },
};

export const Preview = React.forwardRef(({
  classes,
  ...props
}: PropTypes, ref) => {
  
  const {
    setIsModal,
    handleEdit,
    handleDelete,
    external,
    data,
    slideIndex,
    slidesLength,
    handleSlide,
    disabled
  } = React.useContext(DropzoneContext);
  return (
    <div
      className={
        classNames(
          classes?.root,
          styles.Root,
          {
            [styles.RootIsDisabled]: disabled,
          }
        )
      }
      style={{
        backgroundImage: `url('${get(data, 'preview') || get(data, 'src')}')`,
      }}
    >
      {
        get(data, 'type') === TYPES.VIDEO && (
          <ReactPlayer
            controls
            className={styles.Video}
            url={get(data, 'src')}
            onClick={handlePrevent()}
          />
        )
      }
      <ButtonGroup
        onClick={handlePrevent()}
        className={classNames(classes?.actionsPanel, styles.ActionsPanel)}
        variant="contained"
        disableElevation
        disableRipple
      >
        {
          slidesLength > 0 && (
            [
              <Button
                key="ButtonBack"
                onClick={handlePrevent(() => handleSlide('back', slideIndex))}
                variant="text"
                className={classNames(
                  classes?.actionsPanelButtons?.back,
                  styles.ButtonBack,
                )}
                disabled={slideIndex === 0}
              />
              ,
              <Button
                key="ButtonForward"
                onClick={handlePrevent(() => handleSlide('forward', slideIndex))}
                variant="text"
                className={classNames(
                  classes?.actionsPanelButtons?.forward,
                  styles.ButtonForward
                )}
                disabled={slideIndex + 1 === slidesLength}
              />
            ]
          )
        }
        <Button
          onClick={handlePrevent((event) => { handleEdit(); })}
          variant="text"
          className={classNames(classes?.actionsPanelButtons?.edit, styles.ButtonEdit)}
        />
        {external && (
          <Button
            onClick={handlePrevent((event) => { setIsModal(true); })}
            variant="text"
            className={classNames(classes?.actionsPanelButtons?.edit, styles.ButtonEditLink)}
          />
        )}
        <Button
          onClick={handlePrevent((event) => { handleDelete(); })}
          variant="text"
          className={classNames(classes?.actionsPanelButtons?.delete, styles.ButtonDelete)}
        />
      </ButtonGroup>
    </div>
  )
});

// Exports
export default Preview;
