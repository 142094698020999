// @flow

// Libraries
import React, { Element }  from 'react';

// Styles
import styles from './Sandwich.scss';

type PropsType = {
  isOpenSandwich: boolean,
  setOpenSandwich: boolean
}

const Sandwich = ({ isOpenSandwich, setOpenSandwich }: PropsType): Element<'div'> => {
  return (
    <div
      className={styles.Root}
      data-show-sandwich={isOpenSandwich}
      onClick={(): void => setOpenSandwich(!isOpenSandwich)}
    >
      <i />
      <i />
      <i />
    </div>
  )
}

export default Sandwich;
