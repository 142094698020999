import React from 'react';
import { reduxForm } from 'redux-form';
import Form from '../../components/Form';

const EditMode = ({ onSubmit, children }) => {
  return (
    <Form onSubmit={onSubmit}>
      { children }
    </Form>
  )
};

const EditModeForm = reduxForm({
  form: 'editTabs',
  touchOnChange: true,
})(EditMode);

export default EditModeForm;
