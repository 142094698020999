import { AnyAction } from 'redux';

const initialState = {
  currentLightbox: {},
  currentLightboxSharingLink: null
};

export const SET_CURRENT_LIGHTBOX = 'SET_CURRENT_LIGHTBOX ';
export const SET_CURRENT_LIGHTBOX_SHARING_LINK =
  'SET_CURRENT_LIGHTBOX_SHARING_LINK';

export default (
  state = initialState,
  action: AnyAction
): ReturnType<typeof state> => {
  switch (action.type) {
    case SET_CURRENT_LIGHTBOX:
      return {
        ...state,
        currentLightbox: action.payload.currentLightbox
      };
    case SET_CURRENT_LIGHTBOX_SHARING_LINK:
      return {
        ...state,
        currentLightboxSharingLink: action.payload
      };
  
    default:
      return state;
  }
};

export const setCurrentLightbox = (lightbox): AnyAction => {
  return {
    type: SET_CURRENT_LIGHTBOX,
    payload: { currentLightbox: lightbox }
  };
};

export const setCurrentLightboxSharingLink = (sharingLink): AnyAction => {
  return {
    type: SET_CURRENT_LIGHTBOX_SHARING_LINK,
    payload: sharingLink
  };
};
