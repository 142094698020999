// @flow

// Libraries
import * as React from 'react';
import { EditorState, convertFromRaw, RichUtils, SelectionState } from 'draft-js';
import { get, find, filter } from 'lodash';
import { useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

// Utils
import { moveSelectionToStart } from '@utils/editorUtils';
import { parse } from '@utils/url';
import decorators from '@utils/editorDecorators';

// Hooks
import {
  useUpdatePage,
  useBlockerPage
} from '@hooks/data';

type BUILDER_PROPS_TYPES = {
  buffers?: Object,
  pages: Array<Object>,
  updateBoards?: Function,
  updateBuffers?: Function,
  updateSession?: Function,
  isEdit?: Boolean,
  session?: Object
};

export default ({ buffers, pages, updateBoards, updateBuffers, updateSession, isEdit, session }: BUILDER_PROPS_TYPES) => {

  const {
    load: loadUpdatePage,
    loading: loadingUpdatePage,
    result: resultUpdatePage,
    error: errorUpdatePage,
  } = useUpdatePage();

  const {
    load: loadBlockerPage,
    loading: loadingBlockerPage,
    result: resultBlockerPage,
    error: errorBlockerPage,
  } = useBlockerPage({
    isLocked: true
  });

  const {
    load: loadUnBlockerPage,
    loading: loadingUnBlockerPage,
    result: resultUnBlockerPage,
    error: errorUnBlockerPage,
  } = useBlockerPage({
    isLocked: false
  });

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [limitedEditorState, setLimitedEditorState] = React.useState(EditorState.createEmpty());
  
  const editorRef = React.useRef(null);
  const limitedEditorRef = React.useRef(null);

  const [render, setRender] = React.useState();
  const [limitedRender, setLimitedRender] = React.useState();

  const [readOnly, setReadState] = React.useState(true);
  const [limitedReadOnly, setLimitedReadState] = React.useState(false);
  
  const location = useLocation();
  
  const [page, setPage] = React.useState();
  const [selection, setSelection] = React.useState();
  
  const [anchor, setAnchor] = React.useState();

  const isLogin = !!get(session, 'id', false);

  const {
      parsedPath,
      // pathname
    } = parse(location.href);

  const filteredPaths = filter(parsedPath, item => !!item);
  const filteredPathsLength = filteredPaths.length;

  // const updatedAt = isBuffer ? get(buffers, `[${get(page, 'id')}].updatedAt`) : get(page, 'updatedAt');
  // const updatedAt = get(page, 'updatedAt');

  const handleLoadContent = () => {
    const content = get(page, 'content');

    const currentSelection = editorState.getSelection();
    setSelection(currentSelection.getFocusOffset());

    const newEditorState = content ? EditorState.createWithContent(convertFromRaw(content), decorators) : EditorState.createEmpty();

    // setEditorState(newEditorState);

    setEditorState(EditorState.forceSelection(
      newEditorState,
      newEditorState.getSelection() 
    ));
    // const afterSelectionMove = moveSelectionToStart(newEditorState);
    // setEditorState(EditorState.forceSelection(
    //   afterSelectionMove,
    //   afterSelectionMove.getSelection()
    // ));
    
  };

  const handleInitPage = () => {
    const originalPage = find(pages, (item) => get(item, 'slug') === filteredPaths[1]);
    const pageId = get(originalPage, 'id');
    const bufferedPage = get(buffers, `[${pageId}]`);

    const isContinued = 
      isLogin 
      && get(originalPage, 'isLocked');
      // && get(originalPage, 'user.id') === get(session, 'id'); // Проверка id текущего пользователя с id пользователя, который создавал страницу

    const selectedPage = isContinued && bufferedPage ? bufferedPage : originalPage;
    const selectedSection = find(
      get(selectedPage, 'sections'),
      item => get(item, 'slug') === filteredPaths[2]
    );
    
    updateSession({ isEdit: isContinued });
    
    setAnchor(
      filteredPathsLength > 2
        ? selectedSection
        : selectedPage
    );
    setPage(selectedPage);
  };

  React.useEffect(() => {
    handleLoadContent();
  },[page]);

  React.useEffect(() => {
    const anchorNode = document.querySelector(`[data-offset-key = "${get(anchor, 'id')}-0-0"]`);
    const headerHeight = document.getElementsByTagName('header')[0]['offsetHeight'];
    const anchorCR = anchorNode ? anchorNode.getBoundingClientRect() : { top: window.scrollY };
    animateScroll.scrollMore(get(anchorCR, 'top', window.scrollY) - headerHeight, {
      duration: 0,
    });
  },[anchor, editorRef]);

  React.useEffect(() => {
    handleInitPage();
  },[location, pages]);

  React.useEffect(() => {
    const buffer = get(buffers, `[${get(page, 'id')}]`);
    const reloadAt = get(buffer, 'reloadAt');
    const isBuffer = !!buffer;
    if (!isBuffer || reloadAt) {
      handleInitPage(); 
    }
  },[get(buffers, `[${get(page, 'id')}].reloadAt`)]);

  React.useEffect(() => {
    setReadState(!isEdit);
  },[isEdit]);

  React.useEffect(() => {
    if (loadingUpdatePage || !resultUpdatePage) { return; }
    handleCancel();
  },[loadingUpdatePage, resultUpdatePage]);

  React.useEffect(() => {
    if (loadingUnBlockerPage || !resultUnBlockerPage) { return; }
    const id = get(page, 'id');
    // get(editorRef, 'current') && editorRef.current.blur();
    updateBuffers(filter(buffers, item => item.id !== id ), true);
  },[loadingUnBlockerPage, resultUnBlockerPage]);

  React.useEffect(() => {
    if (!render) { return; }
    setEditorState(EditorState.forceSelection(
      render,
      render.getSelection() 
    ));
    setReadOnlyState(false);
  },[render]);

  React.useEffect(() => {
    if (!limitedRender) { return; }
    setLimitedEditorState(EditorState.forceSelection(
      limitedRender,
      limitedRender.getSelection() 
    ));
    setLimitedReadOnlyState(false);
  },[limitedRender]);

  const forceRender = (contentState, changeType) => {
    editorRef.current.focus();
    const forcedState = EditorState.push(editorState, contentState, changeType);
    setRender(forcedState);
  };

  const setReadOnlyState = (stateValue) => {
    setReadState(stateValue);
  };

  const editorFocusHandler = () => {
    editorRef && editorRef.current.focus();
  };

  const limitedForceRender = (contentState, changeType) => {
    limitedEditorRef.current.focus();
    const forcedState = EditorState.push(limitedEditorState, contentState, changeType);
    setLimitedRender(forcedState);
  };

  const setLimitedReadOnlyState = (stateValue) => {
    setLimitedEditorState(limitedEditorState);
    setLimitedReadState(stateValue);
  };

  const limitedEditorFocusHandler = () => {
    limitedEditorRef && limitedEditorRef.current.focus();
  };

  const handlePublish = () => {
    const id = get(page, 'id');
    const content = get(buffers, `[${id}].content`);
    const stringified = JSON.stringify(content);
    loadUpdatePage({
      variables: {
        id,
        content: stringified
        // isExternal,
      } 
    });
  };

  const handleCancel = () => {
    const id = get(page, 'id');
    // updateBuffers(filter(buffers, item => item.id !== id ), true);
    loadUnBlockerPage({ variables: { id } });
  };

  const handleBlocker = () => {
    const id = get(page, 'id');
    loadBlockerPage({ variables: { id } });
  };

  const handleMode = (mode) => {
    const id = get(page, 'id');
    mode ? loadBlockerPage({ variables: { id } }) : loadUnBlockerPage({ variables: { id } });
  };

  return {
    editorState,
    setEditorState,
    readOnly,
    isEdit,
    isLogin,
    setReadOnlyState,
    editorRef,
    forceRender,
    render, setRender,
    limitedContext: {
      editorState: limitedEditorState,
      setEditorState: setLimitedEditorState,
      readOnly: limitedReadOnly,
      isEdit,
      render: limitedRender,
      setRender: setLimitedRender,
      setReadOnlyState: setLimitedReadOnlyState,
      editorRef: limitedEditorRef,
      forceRender: limitedForceRender,
      editorFocusHandler: limitedEditorFocusHandler
    },
    editorFocusHandler,
    pages,
    page,
    handleInitPage,
    handleLoadContent,
    handlePublish,
    handleCancel,
    handleBlocker,
    handleMode,
    buffers
  };
};
