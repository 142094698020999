// ENTITIES
export const WIKI_ENTITIES = {
  BLOCK: 'block',
  IMAGE: 'image',
  TWO_IMAGES: 'two_images',
  THREE_IMAGES: 'three_images',
  TWO_DO_IMAGES: 'two_do_images',
  THREE_DO_IMAGES: 'three_do_images',
  VIDEO: 'video',
  SLIDER: 'slider',
  CAROUSEL: 'carousel',
  HORISONTAL_CARDS: 'horisontal_cards',
  VERTICAL_CARDS: 'vertical_cards',
  ASSET_BUTTON: 'asset_button',
  SEPARATED_LINK: 'separated_link',
  FIGMA_FRAME: 'figma_frame'
};

// ACTIONS
export const BUFFERS_UPDATE = 'SERVICES/BOARDS/BUFFERS_UPDATE';
export const BOARDS_UPDATE = 'SERVICES/BOARDS/BOARDS_UPDATE';
export const FILTER_PAGES = 'SERVICES/BOARDS/FILTER_PAGES';
export const DELETE_PAGE = 'SERVICES/BOARDS/DELETE_PAGE';
export const MOVE_PAGES = 'SERVICES/BOARDS/MOVE_PAGES';
export const CREATE_PAGE = 'SERVICES/BOARDS/CREATE_PAGE';

export const DELETE_SECTION = 'SERVICES/BOARDS/DELETE_SECTION';
export const CREATE_SECTION = 'SERVICES/BOARDS/CREATE_SECTION';
export const UPDATE_SECTION = 'SERVICES/BOARDS/UPDATE_SECTION';

export const UPSERT_SECTIONS = 'SERVICES/BOARDS/UPSERT_SECTIONS';

export const UPDATE_TABS = 'UPDATE_TABS';
