// Libraries
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Actions
import { setFavoriteAssets } from '../services/favorites';

// Selectors
import { selectFavoriteAssets } from '../services/favorites';

// GraphQl
const query = loader('../graphql/queries/getFavoriteAssets.gql');

export const useGetFavoriteAssets = () => {
  const dispatch = useDispatch();
  const assets = useSelector(selectFavoriteAssets);

  const [getAssets, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    onCompleted: ({ assetsConnection }) => {
      if (assetsConnection?.nodes) {
        dispatch(setFavoriteAssets(assetsConnection.nodes));
      }
    },
    onError: error => console.error(error)
  });

  const handleGetAssets = () => {
    getAssets({
      variables: {
        inFavorites: true,
        orderBy: 'favoritedAt_DESC'
      }
    });
  };

  useEffect(() => {
    handleGetAssets();
  }, []);

  return {
    getFavoriteAssets: handleGetAssets,
    loading: loading,
    favoriteAssets: assets
  };
};
