//Libraries
import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
import classNames from 'classnames';

//Assets
import { ReactComponent as Logo } from '@assets/icons/cookie_consent_logo.svg';

//Styles
import styles from './CookieConsent.scss';

const options = {
  location: "bottom",
  buttonText: "Accept and close",
  cookieName: "CookiesConsent",
  buttonStyle: {
    padding: "10px 15px",
    fontSize: "16px", 
    borderRadius: "3px", 
    background: "transparent",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#fff",
    color: "#fff",
  },
  expires: 150
}

export default () => {
  const [hidden, setHidden] = useState(false);
  
  useEffect(() => {
    console.warn('Cookies consent:', Cookies.get('CookiesConsent'));
    setHidden(Cookies.get('CookiesConsent'));
  }, []);

  const onAccept = () => {
    setHidden(Cookies.get('CookiesConsent'));
  }

  return (
    <div className={classNames({
      [styles.Root]: true,
      [styles.Hidden]: hidden
    })}>
      <Container
        maxWidth="lg"
        classes={{
          root: styles.Wrapper
        }}
      >
        <CookieConsent
          {...options}
          disableStyles={true}
          containerClasses={styles.Container} 
          contentClasses={styles.Content}
          buttonClasses={styles.Button}
          onAccept={onAccept}
        >
          <div className={styles.Info}>
            <div>
              <Logo className={styles.Logo}/>
            </div>
            <span>
              We use cookies to make your experience of our websites better. By using and further navigating this website you accept this. 
              {/* Detailed information about the use of cookies on this website is available by clicking on <Link to={'#'}>more information</Link>. */}
            </span>
          </div>
        </CookieConsent>
      </Container>
    </div>
  )
};
