// Libraries
import {v4 as uuidv4} from 'uuid';

// Icons
import { ReactComponent as BoldIcon } from '@assets/icons/editorPanel/bold.svg';
import { ReactComponent as ListIcon } from '@assets/icons/editorPanel/list.svg';
import { ReactComponent as LinkIcon } from '@assets/icons/editorPanel/link.svg';

export const blockTypeData = [
  {
    id: uuidv4(),
    title: 'Headline',
    value: 'header-two'
  },
  {
    id: uuidv4(),
    title: 'Subhead',
    value: 'header-four'
  },
  {
    id: uuidv4(),
    title: 'Paragraph',
    value: 'unstyled'
  },
];

export const lineTypeData =  [
  {
    id: uuidv4(),
    title: '',
    type: 'BOLD',
    icon: BoldIcon,
    handler: "convertToBoldHandler"
  },
  {
    id: uuidv4(),
    title: '',
    icon: ListIcon,
    type: 'unordered-list-item',
    handler: "convertToListHandler"
  },
  {
    id: uuidv4(),
    title: '',
    icon: LinkIcon,
    type: 'LINK',
    handler: "linkButtonHandler"
  }
];
