// @flow
import * as React from 'react';

// Components
import { Loading } from './Loading';
import { Preview } from './Preview';

// Styles
import styles from './Thumbnail.scss';

// Data
import { VARIANTS } from '../data';

// Icons
import { ReactComponent as EditIcon } from '@assets/icons/pensil.svg';

type LabelPropTypes = {
  variant?: String
};

export const Thumbnail = ({
  variant = VARIANTS.DEFAULT
}: LabelPropTypes) => {
  switch (variant) {
    case VARIANTS.PREVIEW:
      return (
        <Preview 
          classes={{
            root: styles.Preview,
            actionsPanel: styles.ActionsPanel,
            actionsPanelButtons: {
              edit: styles.ActionsPanelButtonsEdit,
              delete: styles.ActionsPanelButtonsDelete
            }
          }}
        />
    );
    case VARIANTS.HOVER:
      return <div className={styles.Hover}><EditIcon /></div>;
    case VARIANTS.LOADING:
      return <Loading />;
    default:
      return <div className={styles.Default}><EditIcon /></div>;
  }
};

export default Thumbnail;
