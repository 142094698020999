// @flow

// Libraries
import React, { Element } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

// Utilites
import { requiredField } from '../../utils/validators';

// Components
import { Actions, Checkbox, Input, TextArea } from '../../components/Form';
import Button from '../../components/Button';
import Select from '../../components/Form/components/Select';

// Styles
import styles from './EditMode.scss';

const EditModeContent = ({ result, data }: any): Element<'div'> => {
  const TAB_OPTIONS = useSelector(state => get(state, 'boards.list.sys.mapped[11].tabs', []).filter(tab => tab).map(tab => tab.title));
  const history = useHistory();

  return (
    <div className={styles.Container}>
      <Input
        classes={{
          base: styles.TitleBase,
          input: styles.TitleInput
        }}
        name={`${result}.title`}
        placeholder="Enter name"
        type="text"
        validate={[requiredField]}
        variant="outlined"
        maxLength={45}
      />
      <TextArea
        classes={{
          base: styles.DescriptionBase
        }}
        name={`${result}.description`}
        placeholder="Enter short description"
        variant="outlined"
        maxLength={100}
      />
      <div className={styles.SubmitContainer}>
        <Checkbox className={styles.Checkbox} name={`${result}.isExternal`} label='Show for external users' />
        <div>
          <Select name={`${result}.tab`} options={TAB_OPTIONS} placeholder={'Select table item'}/>
          <Actions classes={{root: styles.Actions}}>
            <Button
              type="button"
              disableRipple
              onClick={() => history.push(get(data, 'link'))}
            >
              Edit content
            </Button>
          </Actions>
        </div>
      </div>
    </div>
  )
};

export default EditModeContent;
