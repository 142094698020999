// @flow

// Libraries
import * as React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid4 } from 'uuid';
import { get, isFunction, findIndex } from 'lodash';
import classNames from 'classnames';

// Components
import Button from '@components/Button';
import { Card as BaseCard } from '@components/Card';


// Styles
import styles from './Pagination.scss';

// Types
type PropTypes = {
  page?: Object,
  pages?: [Object]
}

const Pagination = ({ page, pages }: PropTypes) => {
  const isLogin = useSelector(state => !!get(state, 'session.token', false));

  const pageIndex = findIndex(pages.filter(p => !isLogin ? p.isExternal : p), { id: get(page, 'id') });
  const prevPage = get(pages.filter(p => !isLogin ? p.isExternal : p), `[${pageIndex - 1}]`);
  const nextPage = get(pages.filter(p => !isLogin ? p.isExternal : p), `[${pageIndex + 1}]`);

  return (
    <div className={styles.Root}>
      {
        [
          prevPage,
          nextPage
        ].map((item, index) => (
          <BaseCard
            key={uuid4()}
            classes={{
              root: classNames(
                styles.CardRoot,
                {
                  [styles.CardRootDisabled]: !item,
                  [styles.CardRootPrevious]: !index,
                  [styles.CardRootNext]: index
                }
              ),
              title: styles.CardTitle,
              additional: styles.CardAdditional,
              content: styles.CardContent,
              description: styles.CardDescription,
              media: styles.CardMedia,
            }}
            variant="horisontal"
            type="link"
            mode="read"
            data={{
              ...item,
              additional: index > 0 ? 'Next page' : 'Previous page'
            }}
          />
        ))
      }
    </div>
  )
};

// Exports
export default Pagination;
