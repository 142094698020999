// @flow
import { has, values } from 'lodash';

// Utils
import { capitalizeFirstLetter } from '@utils/string';

export const DANGER = 'danger';
export const DEFAULT = 'default';
export const PRIMARY = 'primary';
export const SUCCESS = 'success';
export const WARNING = 'warning';

export const COLOR = {
  DANGER,
  DEFAULT,
  PRIMARY,
  SUCCESS,
  WARNING
};

export const COLOR_LIST = values(COLOR);

export const getColorClassName: Function = (
  id: string = 'root',
  color: string = PRIMARY,
  style: Object
): ?string => {
  const path: string = `${capitalizeFirstLetter(
    id
  )}Color${capitalizeFirstLetter(color)}`;

  return COLOR_LIST.indexOf(color) > -1 && has(style, path)
    ? style[path]
    : null;
};

export type ColorType =
  | typeof DANGER
  | typeof DEFAULT
  | typeof PRIMARY
  | typeof SUCCESS;

export default COLOR;
