// @flow

// Libraries
import * as React from 'react';
import { EditorState } from 'draft-js';
import { get, set } from 'lodash';

// Data
import BLOCK_VARIANTS from './data';

// Context
import LongReadContext from '../LongRead/context';

type BLOCK_CONTAINER_PROPS_TYPES = {
  block?: Object,
  contentState: Object,
  data?: Object,
};

export default ({
  block,
  contentState,
  data,
  ...props
}: BLOCK_CONTAINER_PROPS_TYPES) => {
  const CONTEXT = React.useContext(LongReadContext);

  // const getEntityAt = props.block.getEntityAt(0);
  // if (!props.contentState || !getEntityAt){ return; }
  // const entity = props.contentState.getEntity(getEntityAt);

  const entity = block?.getEntityAt && block.getEntityAt(0);

  // const currentEntity = CONTEXT.currentActiveElement;
  const currentEntity = entity && contentState && contentState.getEntity(entity);
  const type = get(currentEntity, 'getType') && currentEntity.getType();
  const entityData = get(currentEntity, 'getData') && currentEntity.getData();
  const entities = get(entityData, 'entities', []);

  const [inSelectedRange, setSelectedRangeState] = React.useState(false);
  const blockRef = React.useRef(null);

  React.useEffect(() => {
    const entity = get(blockRef, 'current');
    if (!entity){ return; }
    const selectionState = window.getSelection();
    if (selectionState.type === 'Range' && selectionState.containsNode(entity)) {
      setSelectedRangeState(true);
    } else {
      setSelectedRangeState(false);
    }
  }, [CONTEXT.editorState]);

  const handleState = (payload) => {
    const newState = contentState.replaceEntityData(
      block.getEntityAt(0),
      {
        ...entityData,
        ...payload,
      }
    );
      
    // CONTEXT.setEditorState(EditorState.push(CONTEXT.editorState, newState));
    // CONTEXT.setEditorState(newState);
    CONTEXT.forceRender(newState);
  };

  const handleDelete = (key) => {
    const blockMap = contentState.getBlockMap();
    const newBlockMap = blockMap.remove(key || block?.key);
    const newState = contentState.merge({
      blockMap: newBlockMap
    });
    CONTEXT.forceRender(newState, 'remove-range');
    // CONTEXT.setEditorState(EditorState.push(CONTEXT.editorState, newState, 'remove-range'));
  };

  const handleUpdate = index => ({ file, ...props }) => {
    const newEntities = [...entities];
    const newEntityData = {
      ...get(newEntities, `[${index}]`, {}),
      ...props
    };
    set(newEntities, `[${index}]`, newEntityData);
    handleState({
      entities: newEntities
    });
  };

  const handleRemove = index => () => {
    if (entities.length < 2){ return handleDelete(); }
    entities.splice(index, 1);
    handleState({
      entities
    });
  };

  const handleAdd = (props) => {
    handleState({
      entities: [
        ...get(entityData, 'entities'),
        props
      ]
    });
  };

  const variant = get(BLOCK_VARIANTS, `[${type}]`, BLOCK_VARIANTS['IMAGE']);
  const Component = get(variant, 'component');
  const extraProps = get(variant, 'props', {});

  return {
    CONTEXT,
    type,
    entities,
    blockRef,
    inSelectedRange, setSelectedRangeState,
    Component,
    extraProps,
    handleUpdate,
    handleRemove,
    handleDelete,
    handleAdd,
  };
};
