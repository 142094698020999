import {
  BUFFERS_UPDATE,
  BOARDS_UPDATE,
  CREATE_PAGE,
  DELETE_PAGE,
  FILTER_PAGES,
  MOVE_PAGES,
  DELETE_SECTION,
  CREATE_SECTION,
  UPDATE_SECTION,
  UPSERT_SECTIONS,
  UPDATE_TABS
} from '@services/boards/types';

export const updateBuffers = (payload, isHard) => ({ type: BUFFERS_UPDATE, payload, isHard });

export const updateBoards = payload => ({ type: BOARDS_UPDATE, payload });

export const updateTabs = payload => ({ type: UPDATE_TABS, payload });

export const filterPages = (keyword) => {
  return {
    type: FILTER_PAGES,
    payload: {
      keyword
    }
  };
};

export const deletePage = (id) => {
  return {
    type: DELETE_PAGE,
    payload: {
      id
    }
  };
};

export const movePages = (oldIndex, newIndex) => {
  return {
    type: MOVE_PAGES,
    payload: {
      oldIndex,
      newIndex
    }
  };
};

export const createPage = () => {
  return {
    type: CREATE_PAGE
  };
};

export const createSection = (payload) => {
  return {
    type: CREATE_SECTION,
    payload
  };
};

export const updateSection = (payload) => {
  return {
    type: UPDATE_SECTION,
    payload
  };
};

export const deleteSection = (payload) => {
  return {
    type: DELETE_SECTION,
    payload
  };
};

export const upsertSections = (payload) => {
  return {
    type: UPSERT_SECTIONS,
    payload
  };
};
