// @flow

// Libraries
import React, { Element } from 'react';
import { Field } from 'redux-form';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';

//Components
import { requiredField } from '../../../utils/validators';

// Styles
import styles from './Captcha.scss';

const RECAPTCHA_SITE_KEY = '#';

type FormCaptchaProps = {
  name: String,
  className?: String,
}

const renderCaptcha = (props: any): Element<'div'> => {
  const {
    input,
    className,
  } = props;

  return (
    <div className={classNames(styles.ReCAPTCHA, className)}>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={input.onChange}
      />
    </div>
  );
}

export default ({ name }: FormCaptchaProps): Element<Field> => (
  <Field name={name} component={renderCaptcha} validate={[requiredField]}/>
);
