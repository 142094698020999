import { get, set, forEach, trim, snakeCase, map } from 'lodash';
import {v4 as uuidv4} from 'uuid';

const IMAGES_PATH = '/images/temp/wiki/pages/';

export const TABS = new Array(3).fill(null);

const SYS_PAGES_DATA = [
  {
    name: 'main_banner',
    title: 'Kaspersky Brand Guide',
    description: 'Welcome to the Kaspersky Brand Wiki! A place where you can find all tools and assets required for creating brand materials.',
    content: "{\"firstButton\":{title:\"About our Brand Story\",link:\"brand\"}, \"secondButton\":{title:\"Brand Team approval here\",link:\"team\"}}",
  },
];

const WIKI_PAGES_DATA = [
  {
    title: 'Brand Story',
    description: '',
  },
  {
    title: 'Basic brand elements',
    description: 'More about elements that builds our design system',
  },
  {
    title: 'Illustration',
    description: 'Where it can be used, rules for drawing illustrations, types of illustrations for different goals',
  },
  {
    title: 'Photos',
    description: 'Two types of photos Menu.scss, terms of use',
  },
  {
    title: 'Video',
    description: 'About our brand videos',
  },
  {
    title: 'Iconography',
    description: 'Rules for building and using icons',
  },
  {
    title: 'Digital',
    description: 'Elements that builds our design system in Web',
  },
  {
    title: 'Exhibition guidelines',
    description: '',
  },
  {
    title: 'Midori',
    description: 'All about our maskot Midori-kuma',
  }
];

const getSlug = (text) => snakeCase(trim(text));

export const pageTemplate = (data) => {
  const name = get(data, 'name');
  const title = get(data, 'title');
  const description = get(data, 'description', '');
  const slug = getSlug(title);
  return {
    id: get(data, 'id', uuidv4()),
    // preview: slug && `${IMAGES_PATH}${slug}.png`,
    preview: get(data, 'imageUrl'),
    name,
    title,
    description,
    link: `/wiki/${slug}`,
    slug,
    isSaved: get(data, 'isSaved', true),
    isExternal: get(data, 'isExternal', false),
    tab: get(data, 'tab', null),
    content: null,
    sections: [],
    createdAt: get(data, 'createdAt'),
    updatedAt: get(data, 'updatedAt'),
    tabs: TABS
  };
};

export const sectionTemplate = (page, data = {}) => {
  const pageSlug = getSlug(get(page, 'title'));
  const sectionSlug = getSlug(get(data, 'text'));
  const key = get(data, 'key', uuidv4());
  return {
    id: key,
    blockKey: key,
    title: get(data, 'text', ''),
    isUntitled: get(data, 'isUntitled', false),
    link: `/wiki/${pageSlug}/${sectionSlug}`,
    slug: sectionSlug,
    content: { blocks: [], entityMap: [] },
    page: get(page, 'id')
  };
};

export const GENERATOR = (pages: Array) => {
  const generated = {
    pages: [],
    mapped: {},
    filtered: [],
    isEdit: false
  };
  set(generated, 'pages', map(pages, (page: Object) => {
    // const slug = snakeCase(trim(get(page, 'title')));
    // const link = `/wiki/${slug}`;
    const content = get(page, 'content', {});
  
    // const sectionTemplate = (block: Object) => {
    //   const slug = snakeCase(trim(get(block, 'text')));
    //   return {
    //     id: get(block, 'key', uuidv4()),
    //     blockKey: get(block, 'key'),
    //     title: get(block, 'text'),
    //     link: `${link}/${slug}`,
    //     slug: slug,
    //     content: { blocks: [], entityMap: {} },
    //     page
    //   };
    // };
    
    const sectionsGenerator = (props: Object) => {
      const blocks = get(props, 'blocks', []);
      const entityMap = get(props, 'entityMap', []);
      const sections = [];
      let currentSection = null;
      let entityKey = 0;
      const createSection = (page, block) => {
        currentSection = sectionTemplate(page, block);
        sections.push(currentSection);
        // set(generated, `mapped[${currentSection.id}]`, currentSection);
        entityKey = 0;
      };

      forEach(blocks, (block) => {
        switch (get(block, 'type')) {
          case 'header-two':
            createSection(page, block);
            break;
          case 'atomic':
            entityMap[get(block, 'entityRanges[0].key')]
              && currentSection
              && currentSection.content.entityMap.push(entityMap[get(block, 'entityRanges[0].key')]);
            currentSection && currentSection.content.blocks.push(
              { 
                ...block,
                entityRanges: [
                  {
                    ...get(block, 'entityRanges[0]'),
                    key: entityKey++
                  }
                ]
              }
            );
            break;
          default:
            !currentSection && createSection(page, {
              type: 'header-two',
              isUntitled: true
            });
            currentSection.content.blocks.push({ ...block });
            break;
        }
      });
      return sections;
    };

    const filled = {
      ...pageTemplate(page),
      ...page,
      sections: sectionsGenerator(content),
    };
    set(generated, `mapped[${filled.id}]`, filled);

    return filled;
  }));

  return generated;
};

export default {
  wiki: {
    ...GENERATOR(WIKI_PAGES_DATA)
  },
  sys: {
    ...GENERATOR(SYS_PAGES_DATA)
  }
};
