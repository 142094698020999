// @flow
import classNames from 'classnames';
import * as React from 'react';
import BaseLink from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

// Styles
import styles from './Link.scss';

// Utils
import { isExternal } from '@utils/url';

type PropTypes = {
  children: React.Node,
  className?: string,
  classes?: {
    root?: string,
    icon?: string
  },
  to: string
};

const Link = React.forwardRef(({
  children,
  className,
  classes = {},
  to = ''
}: PropTypes, ref): React.Element<'a' | typeof RouterLink> => {
  const externalProps = {
    className: classNames(className, classes.root, styles.Root)
  };

  return isExternal(to) ? (
    <a ref={ref} {...externalProps} href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <BaseLink ref={ref} {...externalProps} to={to} component={RouterLink}>
      {children}
    </BaseLink>
  );
});

export default Link;
