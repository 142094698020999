// Libraries
import { loader } from 'graphql.macro';
import { useMutation } from 'react-apollo';
import { useEffect, useState } from 'react';

// GraphQL
const mutation = loader('../graphql/mutations/getAssetShareLink.gql');

export const useGetAssetShareLink = id => {
  const [link, setLink] = useState('#');
  const [lightboxId, setLightboxId] = useState(null);

  const [mutate, { loading, data, error }] = useMutation(mutation, {
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      if (result?.createAssetDownloadingInfo) {
        setLink(result.createAssetDownloadingInfo?.downloadingUrl);
        setLightboxId(result.createAssetDownloadingInfo?.lightbox?.id);
        window.location.href =
          result.createAssetDownloadingInfo?.downloadingUrl;
      }
    },
    onError: error => {}
  });

  // useEffect(() => {
  //   mutate({ variables: { id } });
  // }, [id, mutate]);

  const getAssetShareLink = () => {
    mutate({ variables: { id } });
  };

  return { link, lightboxId, getAssetShareLink };
};
