//Libraries
import React, { memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import classNames from 'classnames';

//Components
import Button from '../../components/Button';

//Assets
import { ReactComponent as DownloadAssetsIcon } from '@assets/icons/download_assets.svg';

// Config
import { API_URI } from '@config';

import styles from './DownloadAssets.scss';

const DownloadAssetsMemo = ({ setIsModal, setEditButton, mainBannerData }) => {
  const { pathname } = useLocation();
  const isLogin = useSelector(state => !!get(state, 'session.token', false));
  const isEdit = useSelector(state => get(state, 'session.isEdit', false));

  const formValues = useSelector(state => get(state, 'form.mainBanner.values'));
  const re = /(\/wiki\/?)$/;

  const downloadAssets = useMemo(() => {
    return {
      title: 'Download assets',
      link: `${API_URI.replace(/graphql/, '')}portal`
    }
  }, [mainBannerData]);

  const className = classNames({
    [styles.Button]: !isEdit && re.test(pathname),
    [styles.ButtonEdit]: isEdit && re.test(pathname)
  });

  if (!isLogin && !re.test(pathname)) {
    return null;
  };

  return (
    <Button
      variant="contained"
      fullWidth
      className={className}
      startIcon={
        !re.test(pathname) &&
        <span className={styles.Icon}>
          <DownloadAssetsIcon />
        </span>
      }
      onClick={
        isEdit
          ? () => {
              setEditButton('third');
              setIsModal(true);
            }
          : () => {
              window.location = get(mainBannerData, 'content.blocks.thirdButton.link') ?? downloadAssets.link;
            }
      }
    >
      {
        isEdit
          ? get(formValues, 'thirdButtonTitle') ?? 'Enter button name'
          : get(mainBannerData, 'content.blocks.thirdButton.title') ?? downloadAssets.title
      }
    </Button>
  )
};


export const DownloadAssets = memo(DownloadAssetsMemo);
