// @flow
import classNames from 'classnames';
import * as React from 'react';

// Styles
import styles from './Fieldset.scss';

type PropTypes = {
  children: React.Node,
  classes?: {
    root?: string
  }
};

const FormFieldset = ({
  children
}: PropTypes): React.Element<'div'> => (
  <div className={classNames(styles.Root)}>{children}</div>
);

export default FormFieldset;
