// @flow

// Libraries
import * as React from 'react';
import { get, find, without } from 'lodash';
import { useHistory } from "react-router-dom";

// Utils
import { parse } from '@utils/url';

type TreeTypes = {
  pages: Array<Object>,
  buffers?: Object,
  session?: Object,
};

export default ({ pages = [], buffers, session }: TreeTypes) => {
  const { parsedPath } = parse();
  const history = useHistory();
  const [ collapsed, setCollapsed ] = React.useState([]);

  const page = find(pages, (item) => get(item, 'slug') === parsedPath[2]);
  const isLogin = !!get(session, 'id', false);

  React.useEffect(() => {
    setCollapsed([page]);
  }, [page]);

  const handleClick = (page, toogle = true) => () => {
    const inCollapsed = find(collapsed, { id: page.id });
    setCollapsed(inCollapsed ? without(collapsed, inCollapsed) : [...collapsed, page]);
    history.push(get(page, 'link'));
  };

  return {
    pages,
    buffers,
    session,
    isLogin,
    collapsed,
    handleClick,
    page
  };
};
