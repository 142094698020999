import { AnyAction } from 'redux';
import { SET_ACTIVE_TAB } from './types';

const INITIAL_STATE: String | null = null;

export default (state = INITIAL_STATE, action: AnyAction): String => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return action.payload.tab;

    default:
      return state;
  }
};

export const setActiveTab = (tab: String): AnyAction => {
  return {
    type: SET_ACTIVE_TAB,
    payload: { tab }
  };
};
