// @flow
import url from 'url';
import { get, split } from 'lodash';

export const isExternal = (string: string): boolean => {
  const { host } = url.parse(string);
  return !!host && host !== window.location.host;
};

export const parse = (string = window.location.href ): array => {
  const parsed = url.parse(string);
  const parsedPath = split(get(parsed, 'pathname'), '/')
  return {
    ...url.parse(string),
    parsedPath
  };
};
