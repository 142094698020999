// @flow

// Libraries
import * as React from 'react';
import { EditorState } from 'draft-js';
import { get, find } from 'lodash';

// Context
import BuilderContext from '@views/Builder/context';

// Data
import { DELETE_SECTION_MODAL_ID } from '@containers/modals/sections/Delete';

type EditTypes = {
  pages: Array<Object>,
  updateBoards: () => void,
  openModal: () => void,
  closeModal: () => void,
  createSection: () => void,
  updateSection: () => void,
  isEdit: Boolean,
  isLogin: Boolean,
  opened: Boolean,
  change?: Function,
  reset?: Function,
  formValues?: Object
};

export default (props: EditTypes) => {
  const {
    updateBoards,
    openModal,
    closeModal,
    createSection,
    updateSection,
    isEdit,
    isLogin,
    opened,
    change,
    reset,
    formValues
  } = props;

  const {
    page,
    pages,
    buffers,
    editorState,
    setEditorState
  } = React.useContext(BuilderContext);

  const [section, setSection] = React.useState({});

  const currentPage = get(buffers, `[${get(page, 'id')}]`, page);

  const title = get(formValues, 'title', '');
  const content = get(formValues, 'content', '');

  const blockKey = get(opened, 'blockKey');
  const sections = get(currentPage, 'sections');
  // const section = get(opened, 'section');

  React.useEffect(() => {
    setSection(find(sections, { blockKey }));
  }, [blockKey]);

  // const sections = get(section, 'sections');

  const handleSubmit = (state) => {
    const data = {
      sectionData: {
        ...section,
        title,
        content,
      },
      page: {
        ...currentPage,
        updatedAt: Date.now(),
        reloadAt: Date.now(),
      }
    };
    section ? updateSection(data) : (title && content) && createSection(data);
    handleClose();
  };

  const handleClose = () => {
    reset();
    // setEditorState(EditorState.createEmpty());
    closeModal();
  };

  const handleDelete = () => {
    openModal(DELETE_SECTION_MODAL_ID, { blockKey: get(section, 'blockKey'), onClose: handleClose });
  };

  return {
    page: currentPage,
    pages,
    editorState,
    setEditorState,
    handleSubmit,
    handleClose,
    handleDelete,
    updateBoards,
    openModal,
    closeModal,
    isEdit,
    isLogin,
    opened,
    section,
    change,
    formValues
  };
};
