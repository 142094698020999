// Libraries
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { AssetCard } from '../../../../components/AssetCard';

// Actions
import { setFavoriteAssets } from '../../../../services/favorites';

// Selectors
import { selectFavoriteAssets } from '../../../../services/favorites';

// Hooks
import { useToggleFavorites } from '../../../../hooks/useToggleFavorites';
import { useGetAssetShareLink } from '../../../../hooks/useGetAssetShareLink';

// Icons
import { ReactComponent as RemoveIcon } from '@assets/icons/favorites/cross.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/favorites/download.svg';

// Styles
import styles from './Header.scss';

const FavoriteItem = asset => {
  const dispatch = useDispatch();
  const favoriteAssets = useSelector(selectFavoriteAssets);
  const { removeFromFavorites, loading: removing } = useToggleFavorites(asset);
  const { getAssetShareLink } = useGetAssetShareLink(asset.id);

  const handleRemoveFromFavorites = () => {
    if (removing) return;

    removeFromFavorites()
      .then(isFav => {
        if (isFav) {
          return;
        }
        dispatch(
          setFavoriteAssets(favoriteAssets.filter(a => a.id !== asset.id))
        );
      })
      .catch(err => console.error(err));
  };

  const handleDownload = () => {
    if (removing) return;

    if (asset.link && asset.type.includes('SingleAsset')) {
      const newWindow = window.open(asset.link, '_blank', 'noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      getAssetShareLink();
    }
  };

  return (
    <div className={styles.FavoritesItem}>
      <AssetCard
        {...asset}
        items={favoriteAssets}
        short
        shortClassName={{ Root: styles.AssetCard }}
      />
      <div className={styles.Buttons}>
        <div onClick={handleRemoveFromFavorites} className={styles.Button}>
          <RemoveIcon />
        </div>
        <div onClick={handleDownload} className={styles.Button}>
          <DownloadIcon />
        </div>
      </div>
    </div>
  );
};

export default memo(FavoriteItem);
