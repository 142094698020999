export const TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  THUMBNAIL: 'thumbnail'
};

export const FILE_TYPES = {
  [TYPES.THUMBNAIL]: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
  [TYPES.IMAGE]: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/x-icon'],
  [TYPES.VIDEO]: ['video/mp4', 'video/ogg', 'video/webm'],
};

export const VARIANTS = {
  DEFAULT: 'default',
  PREVIEW: 'preview',
  HOVER: 'hover',
  LOADING: 'loading',
};
