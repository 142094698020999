// Libraries
import React from 'react';
import { get, set, find, isFunction, forEach } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch, useSelector } from 'react-redux';

//Constants
import { TABS } from '../../services/boards/data/boards';

// Services
import { updateLoader } from '@services/loader';
import { updateBoards } from '@services/boards';

// GraphQL
const query = loader('src/graphql/queries/getBoards.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

const HANDLE_LOAD = (data?: Object, dispatch?: Function) => dispatch(updateBoards(data));

export const useGetBoards = ({ isLoader = true, onLoad = HANDLE_LOAD }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const stateBoards = useSelector(state => get(state, 'boards'));
  const session = useSelector(state => get(state, 'session'));

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    const nodes = get(data, 'wikiBoardsConnection.nodes', []);
    const boards = {};
    forEach(nodes, node => {
      const pages = get(node, 'wikiPageWikiBoardEntries', []).map(page => {
        const isLocked = get(page, 'isLocked');
        const pageUser = get(page, 'user');
        const updatedPage = {
          ...get(page, 'wikiPage'),
          content: JSON.parse(get(page, 'wikiPage.content', "{\"blocks\":[],\"entityMap\":{}}")),
          tabs: JSON.parse(get(page, 'wikiPage.content'))?.tabs ?? TABS
        };

        if (
          !isLocked
          || (
            isLocked
            && get(pageUser, 'id') !== get(session, 'id')
          )
        ) { return updatedPage; }
        
        const statePage = find(get(stateBoards, `[${get(node, 'name')}].pages`), { id: get(page, 'id') });
        
        return isLocked && statePage ? {
          ...statePage,
          isSaved: false
        } : updatedPage;
      });
      set(boards, `${get(node, 'name')}`, {
        id: get(node, 'id'),
        pages
      })
    });
    setNormalized(boards);
  }, [loading, data]);

  React.useEffect(() => {
    if (!normalized || !isFunction(onLoad)) { return; }
    onLoad(normalized, dispatch);
  }, [normalized, onLoad]);

  return {
    load,
    loading,
    result: normalized,
    error,
  };
};

export default useGetBoards;
