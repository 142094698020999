// @flow
import classNames from 'classnames';
import React, { Element, Node } from 'react';

// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import Button from '@components/Button';


// Styles
import styles from './Modal.scss';

export type PropTypes = {
  children?: Node,
  classes?: {
    root?: string,
    backdrop?: string,
    container?: string,
    content?: string,
    title?: string
  },
  isOpened?: boolean,
  onClose?: () => ?void,
  title?: any,
  actions?: any,
};

const Modal = ({
  children,
  classes = {},
  title,
  actions,
  onClose,
  isOpened,
  ...props
}: PropTypes): Element<Dialog> => (
  <Dialog
    className={classNames(classes.root, styles.Root)}
    onClose={onClose}
    open={!!isOpened}
    {...props}
    classes={{container: classes.container, paper: classes.container}}
  >
    <Button
      onClick={onClose}
      variant="text"
      className={classNames(
        classes?.close,
        styles.Close
      )}
    />
    <DialogTitle
      className={classNames(classes.title, styles.Title)}
    >
      {title}
    </DialogTitle>
    <DialogContent
      className={classNames(classes.content, styles.Content)}
    >
      {children}
    </DialogContent>
    <DialogActions
      className={classNames(classes.actions, styles.Actions)}
    >
      {actions}
    </DialogActions>
  </Dialog>
);

export default Modal;
