// Libraries
import * as React from 'react';

// Components
import Modal from '@components/Modal';
import { P } from '@components/Typography';
import Button from '@components/Button';

// Styles
import styles from './Delete.scss';

export const Delete = ({ handleDelete, opened, handleClose, name, title }) => {

  return (
    <Modal
      classes={{
          container: styles.ModalContainer,
          content: styles.ModalContent,
          actions: styles.ModalActions
        }}
      title={`Delete ${name}?`}
      onClose={handleClose}
      open={!!opened}
      maxWidth="md"
      fullWidth
      actions={(
        <>
          <Button
            className={styles.ModalButton}
            variant="outlined"
            type="button"
            onClick={handleClose}
          >
              Cancel
          </Button>
          <Button
            className={styles.ModalButton}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
              {`Delete ${name}`}
          </Button>
        </>
      )}
    >
      <P className={styles.ModalParagraph}>
        {`Are you sure to delete ${name}`} 
        {' '}
        <b>
          {title}
        </b>
        {' '}
        with all content?
      </P>
    </Modal>
  )
};
