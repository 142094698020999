// @flow

// Libraries
import React, { Element } from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

// Components
import Form, { Input } from '../../../components/Form';
import Fieldset from '../../../components/Form/components/Fieldset';
import { Box, Button } from '@material-ui/core';
import Actions from '../../../components/Form/components/Actions';
import { requiredField, checkValidEmail } from '../../../utils/validators';

// Styles
import stylesLogin from './Login.scss';
import styles from '../Profile.scss';

type PropTypes = {
  handleSubmit: Function;
  valid: boolean;
}

const LoginForm = ({ handleSubmit, valid }: PropTypes): Element<Box> => {
  return (
    <Box className={styles.FormContainer}>
      <Form onSubmit={handleSubmit}>
        <Fieldset>
          <Input
            name="email"
            placeholder="E-mail"
            type="text"
            validate={[requiredField, checkValidEmail]}
          />
          <Input
            name="password"
            placeholder="Password"
            type="password"
            validate={[requiredField]}
          />
        </Fieldset>
        <Box className={classNames(styles.ReCaptcha)}>
          Тут будет капча
        </Box>
        <Actions classes={{root: stylesLogin.Actions}}>
          <Button
            variant="contained"
            color="primary"
            className={classNames(styles.Button, stylesLogin.Button)}
            disabled={!valid}
            type="submit"
            disableRipple
          >
            Domain login
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classNames(styles.Button, styles.SecondaryButton)}
            disabled={!valid}
            type="submit"
            disableRipple
          >
            Login
          </Button>
        </Actions>
      </Form>
      <Box className={classNames(styles.IsTrouble)}>
        <p>Having trouble with logging in?</p>
        <p>
          <Link to="/reset">Reset Password</Link>
          {' '}
            or
          {' '}
          <Link to="/resend">Resend Confirmation Email</Link>
        </p>
      </Box>
    </Box>
  )
}

export default reduxForm({
  form: 'login'
})(LoginForm);
