// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

// Assets
import arrow from '@assets/icons/small_arrow.svg';

// Styles
import styles from './Selector.scss';

// Types
type Types = {
  onChangeHandler: (typeStyle: string) => void;
  data: any;
  selectedType: string;
  classes: {
    root?: string;
    textBlock?: string;
    dropDown?: string;
  };
};

const Selector = ({ data, onChangeHandler, classes, selectedType }: Types) => {
  const [isOpen, setOpenState] = React.useState(false );
  const [style, setStyle] = React.useState(data[0]);

  const changeStyleHandler = (typeStyle: string, title: string, icon?: string) => {
    onChangeHandler(typeStyle);
    setStyle({title, icon});
    setOpenState(false);
  };

  return (
    <div className={classNames(styles.Root, classes?.root)}>
      <button type="button" onClick={() => {setOpenState(!isOpen)}} className={classNames(styles.Static, classes?.textBlock)}>
        <div>
          {style.icon && (<img src={style.icon} alt="icon" />)}
          <span>{style.title}</span>
        </div>
        <img src={arrow} alt="arrow" />
      </button>

      { isOpen && (
        <div className={classNames(styles.DropDown, classes?.dropDown)}>
          {data.map((item, index) => (
            <div
              key={get(item, 'id', index)}
              className={classNames(styles.DropDownItem, {[styles.DropDownItemActive]: style.title === item.title})}
              onClick={(event) => { event.preventDefault(); changeStyleHandler(item.value, item.title, item.icon)}}
            >
              {item.icon && (<img src={item.icon} alt={item.title} />)}
              {item.title}
            </div>
          ))}
        </div>
      )}
    </div>
  )
};

// Exports
export default Selector;
