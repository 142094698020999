import Select from 'react-select'


// @flow

// Libraries
import React, { Element } from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';

//Components
import { requiredField } from '../../../utils/validators';

// Styles
import styles from './Select.scss';

type FormSelectProps = {
  name: String,
  className?: String,
  options: String[],
  placeholder?: String,
}

const renderSelect = (props: any): Element<'div'> => {
  const {
    input,
    options,
    className,
    placeholder
  } = props;

  const OPTIONS = options.map(option => {
    return {
      value: option,
      label: option
    }
  });

  const VALUE = !input.value?.length || !options.includes(input.value) ? null : {
    value: input.value,
    label: input.value
  };

  const STYLES = {
    menuPortal: base => ({ ...base, zIndex: 9, marginTop: '-10px'}),
    control: (base) => ({
      ...base,
      boxShadow: "none"
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const color = '#1D1D1B';
      const fontWeight = 'bold';
  
      return { ...provided, opacity, transition, color, fontWeight };
    }
  }

  return (
    <>
       <Select 
        options={OPTIONS}
        name={input.name}
        value={VALUE}
        onChange={({value}) => input.onChange(value)}
        className={styles.Select}
        classNamePrefix={styles.Select}
        defaultMenuIsOpen={false}
        placeholder={placeholder}
        menuPortalTarget={document.body} 
        styles={STYLES}/>
      {/* <select {...input} className={classNames(styles.Select, className)}>
        {placeholder && <option value="" disabled selected hidden>{placeholder}</option>}
        {options.map(option => <option value={option} key={option}>{option}</option>)}
      </select> */}
    </>
  );
}

export default ({ name, ...other }: FormSelectProps): Element<Field> => (
  <Field name={name} {...other} component={renderSelect} validate={[requiredField]}/>
);
