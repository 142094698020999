// @flow

// Libraries
import * as React from 'react';
import { get, find, isFunction } from 'lodash';
import { EditorState } from 'draft-js';

// Context
import BuilderContext from '@views/Builder/context';

type EditTypes = {
  updateBoards: () => void,
  closeModal: () => void,
  deleteSection: () => void,
  isLogin: Boolean,
  opened: Boolean,
};

export default (props: EditTypes) => {
  const { updateBoards, closeModal, deleteSection, isLogin, opened } = props;

  const {
    page,
    pages,
    buffers,
    // editorState,
    // setEditorState
  } = React.useContext(BuilderContext);

  const currentPage = get(buffers, `[${get(page, 'id')}]`, page);

  const blockKey = get(opened, 'blockKey');
  const onClose = get(opened, 'onClose');
  const sections = get(currentPage, 'sections');
  const section = find(sections, { blockKey });
  
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );
  

  // const sections = get(section, 'sections');

  const handleClose = () => {
    isFunction(onClose) && onClose();
    closeModal();
  };

  const handleCancel = (state) => {
    handleClose();
  };

  const handleDelete = (state) => {
    deleteSection({ sectionData: section, page: { ...currentPage, reloadAt: Date.now(), } });
    handleClose();
  };

  return {
    page: currentPage,
    pages,
    editorState,
    setEditorState,
    updateBoards,
    closeModal,
    isLogin,
    opened,
    blockKey,
    handleCancel,
    handleClose,
    handleDelete,
    section
  };
};
