// @flow

// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import { reduxForm } from 'redux-form';

// Components
import Modal from '@components/Modal';
import BaseForm from '@components/Form';
import Button from '@components/Button';

// Containers
import LongRead from '@views/Builder/containers/LongRead';

// Styles
import styles from './Edit.scss';

// Services
import { updateBoards, createSection, updateSection } from '@services/boards';
import { closeModal, openModal } from '@services/modals';

// Hooks
import useEdit from './hooks';

// Data
import { validate, asyncValidate } from './validators';

// Icons
import { ReactComponent as DeleteIcon } from '@assets/icons/delete_red.svg';

export const EDIT_SECTION_MODAL_ID = 'EDIT_SECTION_MODAL_ID';

const Edit = (props) => {
  const {
    // page,
    // pages,
    // editorState,
    // setEditorState,
    handleSubmit,
    handleClose,
    handleDelete,
    openModal,
    closeModal,
    // isEdit,
    // isLogin,
    opened,
    error,
    section,
    change,
    formValues
  } = useEdit(props);

  return (
    <Modal
      id={EDIT_SECTION_MODAL_ID}
      classes={{
          container: styles.ModalContainer,
          content: styles.ModalContent,
          actions: styles.ModalActions
        }}
      onClose={handleClose}
      open={!!opened}
      maxWidth="lg"
      fullWidth
      actions={(
        <>
          {section && (
            <Button
              className={styles.ModalButtonDelete}
              variant="outlined"
              color="secondary"
              type="button"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
            >
                Delete section
            </Button>
          )}
          <Button
            className={styles.ModalButton}
            variant="contained"
            type="button"
            onClick={handleSubmit}
            disabled={!formValues?.title || !formValues?.content}
          >
              Save changes
          </Button>
        </>
      )}
    >
      <BaseForm
        className={styles.Form}
        onSubmit={handleSubmit}
        name="reset"
        autoComplete="off"
      >
        <LongRead isLimited section={section} error={error} change={change} data={formValues} />
      </BaseForm>
    </Modal>
  )
};

const EditSectionForm = reduxForm<object, any>({
  form: 'editSection',
  destroyOnUnmount: true,
  // enableReinitialize: true,
  initialValues: {
    title: null,
    content: null
  },
  asyncValidate,
  validate,
  touchOnChange: true,
})(Edit);

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  isEdit: get(state, 'session.isEdit', false),
  isLogin: get(state, 'session.token', false),
  opened: get(state, `modals[${EDIT_SECTION_MODAL_ID}]`),
  formValues: get(state, 'form.editSection.values'),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  openModal: (id, data) => dispatch(openModal(id, data)),
  closeModal: () => dispatch(closeModal(EDIT_SECTION_MODAL_ID)),
  createSection: (data) => dispatch(createSection(data)),
  updateSection: (data) => dispatch(updateSection(data)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(EditSectionForm);
