// @flow

// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Modal from '@components/Modal';
import { P } from '@components/Typography';
import Button from '@components/Button';

// Styles
import styles from './Delete.scss';

// Services
import { updateBoards, deleteSection } from '@services/boards';
import { closeModal } from '@services/modals';

// Hooks
import useDelete from './hooks';

export const DELETE_SECTION_MODAL_ID = 'DELETE_SECTION_MODAL_ID';

const Delete = (props) => {
  const {
    opened,
    handleCancel,
    handleClose,
    handleDelete,
    section
  } = useDelete(props);
  return (
    <Modal
      id={DELETE_SECTION_MODAL_ID}
      classes={{
          container: styles.ModalContainer,
          content: styles.ModalContent,
          actions: styles.ModalActions
        }}
      title="Delete section?"
      onClose={handleClose}
      open={!!opened}
      maxWidth="md"
      fullWidth
      actions={(
        <>
          <Button
            className={styles.ModalButtonDelete}
            variant="outlined"
            type="button"
            onClick={handleCancel}
          >
              Cancel
          </Button>
          <Button
            className={styles.ModalButton}
            variant="contained"
            type="button"
            onClick={handleDelete}
          >
              Delete section
          </Button>
        </>
      )}
    >
      <P className={styles.ModalParagraph}>
        Are you sure to delete section 
        {' '}
        <b>
          {get(section, 'title')}
        </b>
        {' '}
        with all content?
      </P>
    </Modal>
  )
};

const mapStateToProps = (state: Object): Object => ({
  isLogin: get(state, 'session.token', false),
  opened: get(state, `modals[${DELETE_SECTION_MODAL_ID}]`),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  closeModal: () => dispatch(closeModal(DELETE_SECTION_MODAL_ID)),
  deleteSection: (sectionId) => dispatch(deleteSection(sectionId)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Delete);
