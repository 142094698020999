//Libraries
import React, { memo, useState } from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import { Card, CardContent } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { find, get } from "lodash";
import classNames from "classnames";

//Components
import Button from "../../components/Button";
import { Input } from "../../components/Form";
import { requiredField } from "../../utils/validators";
import Link from "../../components/Link/Link";
import { Delete } from "../../containers/modals/main";

// Icons
import SaveIcon from '@material-ui/icons/Save';
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg';
import { ReactComponent as DrugAndDrop } from '@assets/icons/drug_and_drop.svg';

//Actions
import { updateTabs } from "../../services/boards/actions";

// Styles
import styles from './TableMenu.scss';

const DragHandle = SortableHandle(() => (
  <div className={styles.DrugAndDrop}><DrugAndDrop /></div>
));

const SortableItem = SortableElement(
  ({ id, tabIndex, editMode, link, tab, onUpdateTabs, title }) => {
    const editTabs = useSelector(state => get(state, 'form.editTabs.values.items'));
    const { tabs } = useSelector(state => find(get(state, 'boards.list.sys.pages'), { name: 'main_banner' }));
    const dispatch = useDispatch();
    const [isModal, setIsModal] = useState(false);

    const onSave = () => {
      if (editMode) {
        const newTabs = [...tabs];
        newTabs[tabIndex] = editTabs.find(tab => tab.id === id);
        dispatch(updateTabs(newTabs.sort()));
        onUpdateTabs(newTabs.sort());
      }
    };

    const onDelete = () => {
      if (editMode) {
        const newTabs = [...tabs];
        newTabs.fill(null, tabIndex, tabIndex + 1);
        dispatch(updateTabs(newTabs.sort()));
        onUpdateTabs(newTabs.sort());
        setIsModal(false);
      }
    }
    
    return (
      <Card className={classNames({
        [styles.EditTab]: true,
        [styles.Disabled]: !editMode
      })}>
        <DragHandle />
        <CardContent className={styles.EditModeContent}>
          <Input
            classes={{
              base: styles.TitleBase,
              input: styles.TitleInput
            }}
            placeholder="Enter tab name"
            type="text"
            name={`${tab}.title`}
            validate={[requiredField]}
            variant="outlined"
            maxLength={100}
            outlined
          />
          <Input
            classes={{
              base: styles.LinkBase,
              input: styles.LinkInput
            }}
            placeholder="Enter tab link"
            type="text"
            name={`${tab}.link`}
            validate={[requiredField]}
            variant="outlined"
            maxLength={100}
            outlined
          />
          <div className={styles.EditContent}>
            {/* <Link to={link ?? '#'} className={styles.Link}>Edit content</Link> */}
          </div>
        </CardContent>
        <div className={styles.Actions}>
          {
            (
              <Button
                size="small"
                startIcon={<SaveIcon />}
                className={styles.Save}
                onClick={onSave}
              />
            )
          }
          <Button
            size="small"
            startIcon={<TrashIcon />}
            className={styles.Trash}
            onClick={() => setIsModal(true)}
          />
        </div>
        <Delete 
          name="tab"
          title={title}
          opened={isModal}
          handleClose={() => setIsModal(false)}
          handleDelete={onDelete}
        />
      </Card>
    )
  }
)

export const EditTab = memo(SortableItem);
