// Libraries
import React from 'react';
import { get } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';

type PROPS_TYPE = {};

// GraphQL
const query = loader('src/graphql/queries/getAssets.gql');

export const useGetAssets = (props: PROPS_TYPE) => {

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    if (loading || !data) { return; }
    setNormalized(get(data, 'assetsConnection'));
  }, [loading, data]);

  return {
    load,
    loading,
    result: normalized,
    error,
  };
};

export default useGetAssets;
