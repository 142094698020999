// @flow

// Libraries
import classNames from 'classnames';
import React, { Element } from 'react';
import { Field } from 'redux-form';

// Components
import TextField from '@components/TextField';

import Base from './Base';

// Styles
import styles from './Input.scss';

type PropTypes = {
  placeholder?: string,
  type?: string,
  name: string,
  variant: string,
  validate?: Function[],
  classes?: any,
  meta?: Object,
  outlined?: boolean
}

const renderField = (props: any): Element<'div'> => {
  const {
    input,
    placeholder,
    type,
    meta: { touched, error, invalid },
    classes,
    variant,
    outlined
  } = props;
  return (
    <Base {...props}>
      <TextField
        {...input}
        inputProps={props}
        label={placeholder}
        type={type}
        variant={variant}
        outlined={!!outlined}
        className={classNames(
          styles.Input,
          classes?.input,
          {
            [styles.Error]: touched && invalid
          }
        )}
        error={touched && invalid}
      />
      {
        touched && error &&
        <span className={classNames(styles.ErrorMessage)}>{error}</span>
      }
    </Base>
  );
};

const FormInput = ({
  placeholder,
  type,
  name,
  validate,
  classes,
  variant,
  ...props
}: PropTypes): Element<Field> => {
  return (
    <Field
      name={name}
      placeholder={placeholder}
      type={type}
      validate={validate}
      component={renderField}
      classes={classes}
      variant={variant}
      {...props}
    />
)};

export default FormInput;
