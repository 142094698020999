// Libraries
import React from 'react';
import { forEach, get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// Services
import { updateLoader } from '@services/loader';

// Hooks
import { useGetAsset } from '@hooks/data';

// GraphQL
const query = loader('src/graphql/mutations/createAsset.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

export const useCreateAsset = ({ isLoader = true, board }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const [normalized, setNormalized] = React.useState();
  const [uploading, setUploading] = React.useState(false);
  const [file, setFile] = React.useState();

  const {
    load: loadGetAsset,
    loading: loadingGetAsset,
    result: resultGetAsset,
    error: errorGetAsset,
  } = useGetAsset({ isLoader });

  const [load, {
    loading,
    data,
    error
  }] = useMutation(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading: loading || uploading || loadingGetAsset }));
  }, [loading, uploading, loadingGetAsset]);

  React.useEffect(() => {
    if (loadingGetAsset || !resultGetAsset) { return; }
    setUploading(false);
    setNormalized(resultGetAsset);
  }, [loadingGetAsset, resultGetAsset]);

  React.useEffect(() => {
    // const uploadURL = get(data, 'createWikiSingleAsset.uploadUrl');
    const uploadInfo = JSON.parse(get(data, 'createWikiSingleAsset.uploadInfo', '{}'));
    if (
      !file
      || loading
      || !uploadInfo
    ) { return; }
    const uploadURL = get(uploadInfo, 'url');
    const fields = get(uploadInfo, 'fields');
    const formData = new FormData();
    forEach(fields, (field, key) => {
      formData.append(key, field);
    });
    formData.append('file', file);
    setUploading(true);
    axios({
      method: 'post',
      url: uploadURL,
      data: formData,
      headers: {
        'Content-Type': get(file, 'type')
      },
    })
      .then(response => {
        // handle success
        loadGetAsset({
          variables: {
            id: get(data, 'createWikiSingleAsset.asset.id')
          }
        });
      })
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });
  }, [loading, data]);

  const hadleCreate = ({ file, ...props }) => {
    load({
      ...props,
      variables: {
        ...get(props, 'variables'),
        fileName: get(file, 'name'),
        fileSize: get(file, 'size'),
        fileContentType: get(file, 'type'),
      }
    });
    setFile(file);
  };

  return {
    load: hadleCreate,
    loading: loading || uploading || loadingGetAsset,
    result: normalized,
    error: error,
  };
};

export default useCreateAsset;
