// @flow

// Libraries
import * as React from 'react';
import { get, filter } from 'lodash';
import arrayMove from 'array-move';

// Context
import BuilderContext from '@views/Builder/context';

type OrderTypes = {
  pages: Array<Object>,
  updateBoards: () => void,
  upsertSections: () => void,
  openModal: () => void,
  closeModal: () => void,
  isEdit: Boolean,
  isLogin: Boolean,
  isOpened: Boolean,
};

export default (props: OrderTypes) => {
  const {
    page,
    buffers,
    editorState,
    setEditorState,
    handleInitPage,
    handleLoadContent,
  } = React.useContext(BuilderContext);

  const { updateBoards, upsertSections, openModal, closeModal, isEdit, isLogin, isOpened } = props;
  const [sections, setSections] = React.useState();

  const currentPage = get(buffers, `[${get(page, 'id')}]`, page);

  React.useEffect(() => {
    if (!isOpened) { return; }
    setSections([...get(currentPage, 'sections', [])]);
  }, [currentPage, isOpened]);

  const handleChange = () => {
    upsertSections({
      sections,
      page: {
        ...currentPage,
        updatedAt: Date.now(),
        reloadAt: Date.now(),
      }
    });
    closeModal();
  };

  const handleSort = ({oldIndex, newIndex}): void => {
    setSections(arrayMove(sections, oldIndex, newIndex));
  };

  const handleDelete = (id): void => {
    setSections(filter(sections, item => get(item, 'id') !== id));
  };

  return {
    page: currentPage,
    sections,
    editorState,
    setEditorState,
    handleSort,
    handleChange,
    handleDelete,
    updateBoards,
    openModal,
    closeModal,
    isEdit,
    isLogin,
    isOpened
  };
};
