// @flow
import * as React from 'react';

// Components
import { Loading } from './Loading';
import { Preview } from './Preview';

// Styles
// import styles from './Image.scss';

// Data
import { VARIANTS } from '../data';

type LabelPropTypes = {
  variant?: String
};

export const Image = ({
  variant = VARIANTS.DEFAULT
}: LabelPropTypes) => {
  switch (variant) {
    case VARIANTS.PREVIEW:
      return <Preview />;
    case VARIANTS.HOVER:
      return 'Drop image here';
    case VARIANTS.LOADING:
      return <Loading />;
    default:
      return '+ Upload image (or drop image here)';
  }
};

export default Image;
