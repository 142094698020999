// @flow

// Libraries
import * as React from 'react';
import { CompositeDecorator } from 'draft-js';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Box from '@material-ui/core/Box';

import Button from '@components/Button';
import { H2 } from '@components/Typography';

// Styles
import styles from './editorDecorators.scss';

// Icons
import icon_delete from '@assets/icons/delete.svg';

// Services
import { updateBoards } from '@services/boards';
import { openModal, closeModal } from '@services/modals';

// Data
import { EDIT_SECTION_MODAL_ID } from '@containers/modals/sections/Edit';
import { DELETE_SECTION_MODAL_ID } from '@containers/modals/sections/Delete';

export const findEntities = ({ contentBlock, callback, contentState, type }) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      const entity = entityKey && contentState.getEntity(entityKey);
      const entityType = entity && entity.getType();
      return entityType === type;
    },
    callback
  );
};

export const findLinkEntities = (contentBlock, callback, contentState) => {
  findEntities({
    contentBlock,
    callback,
    contentState,
    type: 'LINK'
  });
};

export const findSectionsEntities = (contentBlock, callback, contentState) => {
  contentBlock.getType() === 'header-two' && contentBlock.findStyleRanges(
    (character) => get(character, 'size', 0),
    callback
  );
};

export const Link = ({ children, contentState, entityKey }: any) => {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <a className={styles.Link} href={url}>
      {children}
    </a>
  );
};

const mapStateToProps = (state: Object): Object => ({
  isEdit: get(state, 'session.isEdit', false),
  isLogin: get(state, 'session.token', false),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  openModal: (id, data) => dispatch(openModal(id, data)),
  closeModal: (id) => dispatch(closeModal(id)),
});

export const Section = compose(connect(mapStateToProps, mapDispatchToProps))((props: any) => {
  // const data = contentState.getEntity(entityKey).getData();
  const { children, blockKey, isEdit, isLogin, openModal } = props;
  return (
    <div className={styles.Section}>
      <H2 className={styles.Title}>
        {children}
      </H2>
      {
        isLogin && isEdit && 
        (
          <Box className={styles.Actions}>
            <Button
              variant="outlined"
              color="primary"
              className={styles.Button}
              onClick={() => openModal(EDIT_SECTION_MODAL_ID, { blockKey })}
            >
              Edit section
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className={styles.Button}
              startIcon={<img src={icon_delete} alt="Delete section" />}
              onClick={() => openModal(DELETE_SECTION_MODAL_ID, { blockKey })}
            >
              Delete section
            </Button>
          </Box>
        )
      }
    </div>
  );
});

export const linkDecorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

export const sectionDecorator = new CompositeDecorator([
  {
    strategy: findSectionsEntities,
    component: Section,
  },
]);

export default new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
  {
    strategy: findSectionsEntities,
    component: Section,
  },
]);
