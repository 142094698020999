import { get } from 'lodash';
import Cookies from 'js-cookie';

import {
  SESSION_SIGN_IN,
  SESSION_SIGN_OUT,
  SESSION_UPDATE
} from './types';

const initialState = {
  email: null,
  token: null,
  expire: 0,
  isEdit: false
};

export default (state = initialState, action) => {
  const isExpired = state.expire > 0 && state.expire < Date.now();
  const type = isExpired ? SESSION_SIGN_OUT : action.type;
  const token = get(action, 'payload.id', get(state, 'token'));
  const isEdit = get(action, 'payload.isEdit', get(state, 'isEdit'));
  switch (type) {
    case SESSION_SIGN_IN:
      return { ...initialState, token, expire: Date.now() + 86400000, ...action.payload, isEdit };
    case SESSION_UPDATE:
      return { ...state, token, ...action.payload };
    case SESSION_SIGN_OUT:
      return { ...initialState };
    default:
      return {
        ...state,
        token
      };
  }
};
