// Libraries
import React from 'react';
import {
  useLocation,
} from 'react-router-dom';
// import { get } from 'lodash';
import Cookies from 'js-cookie';

// Hooks
import { useGetBoards, useGetCurrentUser } from '@hooks/data';

type PROPS_TYPE = {
};

export default (props: PROPS_TYPE) => {

  const { pathname } = useLocation();

  const {
    load: loadGetBoards,
    loading: loadingGetBoards,
    result: resultGetBoards,
    error: errorGetBoards,
  } = useGetBoards();

  const {
    load: loadGetCurrentUser,
    loading: loadingGetCurrentUser,
    result: resultGetCurrentUser,
    error: errorGetCurrentUser,
  } = useGetCurrentUser(
    { isLoader: false }
  );

  React.useEffect(() => {
    loadGetCurrentUser();
    loadGetBoards({ variables: { name: 'wiki' } });
  }, []);

  React.useEffect(() => {
    if (loadingGetBoards || !resultGetBoards) { return; }
  }, [loadingGetBoards, resultGetBoards]);

  return {
    pathname,
  };
};
