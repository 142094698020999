// Libraries
import React from 'react';
import { get, set, isFunction, forEach } from 'lodash';
import { useLazyQuery } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';

// Services
import { updateLoader } from '@services/loader';
import { signIn, signOut } from '@services/session';

// GraphQL
const query = loader('src/graphql/queries/getCurrentUser.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

const HANDLE_LOAD = (data?: Object, dispatch?: Function) => dispatch(get(data, 'id') ? signIn(data) : signOut());

export const useGetCurrentUser = ({ isLoader = true, onLoad = HANDLE_LOAD }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    const user = get(data, 'currentUser');
    setNormalized({
      ...user
    });
  }, [loading, data]);

  React.useEffect(() => {
    if (!normalized || !isFunction(onLoad)) { return; }
    onLoad(normalized, dispatch);
  }, [normalized, onLoad]);

  return {
    load,
    loading,
    result: normalized,
    error,
  };
};

export default useGetCurrentUser;
