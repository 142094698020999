// @flow
import {
  UPDATE_LOADER
} from './types';

export type Action = {
  type: String,
  payload?: any
};

export const updateLoader: Function = (payload: Object): Action => ({
  type: UPDATE_LOADER,
  payload
});
