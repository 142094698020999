// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get, filter } from 'lodash';
import {v4 as uuidv4} from 'uuid';

// Components
import { IconButton } from '@material-ui/core';

import Button from '@components/Button';
import StyleSelector from './Selector';

// Styles
import styles from './EditorPanel.scss';

// Data
import {lineTypeData, blockTypeData} from './data';

const EditorPanel = ({
  boldButtonHandler,
  listButtonHandler,
  linkButtonHandler,
  textStyleHandler,
  editorState,
  editorNode,
  isLimited,
  isSelected,
  focusPosition
}: Types) => {
  const [URL, setURL] = React.useState();
  const [isOpenLinkField, setLinkState] = React.useState(false);
  const toolBarNode = React.useRef(null);

  const getSelectionRect = (root) => {
    const selection = root.getSelection();
    if (selection.rangeCount === 0) {
      return null;
    }
    const range = selection.getRangeAt(0);
    const rangeClientRect = range?.getBoundingClientRect();

    const focusNode = selection?.focusNode;

    let node = focusNode;
    do {
      if (node.getAttribute && node.getAttribute('data-block') === 'true') {
        break;
      }
      node = node.parentNode;
    } while (node !== null);

    const rect = {
      x: get(rangeClientRect, 'x', 0),
      y: get(rangeClientRect, 'y', 0),
      top: get(rangeClientRect, 'top', 0),
      left: get(rangeClientRect, 'left', 0),
      width: get(rangeClientRect, 'width', 0),
      height: get(rangeClientRect, 'height', 0),
      offsetTop: node?.offsetTop,
      offsetLeft: node?.offsetLeft,
    };

    return rect;
  };

  React.useEffect(() => {
    if (!isSelected) { return; }

    const contentState = editorState.getCurrentContent();
    const startKey = editorState.getSelection().getStartKey();
    const startBlock = contentState.getBlockForKey(startKey);
    const startOffset = editorState.getSelection().getStartOffset();
    const linkKey = startBlock.getEntityAt(startOffset);
    const linkInstance = linkKey && contentState.getEntity(linkKey);
    setURL(get(linkInstance?.getData(), 'url', ''));

    const {
      offsetTop,
      x,
      width
    } = getSelectionRect(window);

    const {
      height: toolBarNodeHeight
    } = toolBarNode.current.getBoundingClientRect();

    const {
      x: editorNodeX,
    } = editorNode.current.getBoundingClientRect();

    toolBarNode.current.style.transform = `translate3d(${ x - editorNodeX + width * 0.5 }px, ${ offsetTop - toolBarNodeHeight }px, 0)`;
  }, [editorNode, focusPosition, isSelected]);

  const panelHandler = (handlerType: string) => {
    if (handlerType === 'convertToBoldHandler') {
      boldButtonHandler();
    }

    if (handlerType === 'convertToListHandler') {
      listButtonHandler();
    }

    if (handlerType === 'linkButtonHandler') {
      setLinkState(!isOpenLinkField);
    }
  };

  const onKeyPressHandler = (event) => {
    event.stopPropagation();
    if (event.which === 13) {
      linkButtonHandler(event.target.value);
      setLinkState(false);
    }
  };

  const checkType = (itemType) => {
    const selection = editorState.getSelection();

    const block = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey());

    const blockType = block.getType();

    const currentStyle = editorState.getCurrentInlineStyle();

    return ((itemType === blockType) || (currentStyle.has(itemType)))
  };

  return (isSelected) && (
    <div ref={toolBarNode} className={styles.Root} id="EDITOR_PANEL">
      <StyleSelector
        data={isLimited ? filter(blockTypeData, item => (get(item, 'title') !== 'Headline')) : blockTypeData}
        onChangeHandler={textStyleHandler}
      />
      <div className={styles.BlockWrapper}>
        {lineTypeData.map((item) => (
          <Button
            key={get(item, 'id')}
            className={classNames(
              styles.ItemButton,
              {
                [styles.ItemButtonIsActive]: !!checkType(item.type) || (URL && item.type === 'LINK')
              }
            )}
            variant="text"
            onClick={() => {panelHandler(item.handler)}}
          >
            <item.icon />
          </Button>
        ))}
        {isOpenLinkField && (
          <input
            key={uuidv4()}
            className={styles.Input}
            type="text"
            placeholder="Paste a link"
            onKeyUp={(e) => {onKeyPressHandler(e)}}
            onKeyPress={onKeyPressHandler}
            defaultValue={URL}
          />
        )}
      </div>
    </div>
  )
};

// Types
type Types = {
  boldButtonHandler: () => void;
  listButtonHandler: () => void;
  linkButtonHandler: () => void;
  textStyleHandler: (typeStyle: string) => void;
};

// Exports
export default EditorPanel;
