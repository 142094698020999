// @flow

// Libraries
import React, { Element } from 'react';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import {
  requiredField,
  passwordMustMatch,
  minLength,
  checkOnUpperAndLowerCase,
  checkOnDigits,
  checkOnSpecialSymbols
} from '../../../utils/validators';

// Components
import { Box, Button } from '@material-ui/core';
import Form, { Input } from '../../../components/Form';
import Fieldset from '../../../components/Form/components/Fieldset';
import Actions from '../../../components/Form/components/Actions';
import Grid from '@material-ui/core/Grid';
import ListValidateRules from './ListValidateRules';
import { Link } from 'react-router-dom';

// Styles
import styles from '../Profile.scss';
import stylesReset from './ResetPassword.scss';

type PropTypes = {
  handleSubmit: Function;
  valid: boolean;
}

const EnterNewPasswordForm = ({ handleSubmit, valid }: PropTypes): Element<Box> => {
  return (
    <Box className={styles.FormContainer}>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} className={stylesReset.Inputs}>
            <Fieldset>
              <Input
                name="new_password"
                placeholder="New Password"
                type="password"
                validate={
                  [
                    requiredField,
                    minLength,
                    checkOnUpperAndLowerCase,
                    checkOnDigits,
                    checkOnSpecialSymbols
                  ]
                }
              />
              <Input
                name="confirm_password"
                placeholder="Confirm Password"
                type="password"
                validate={[requiredField, passwordMustMatch]}
              />
            </Fieldset>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            className={stylesReset.MustContain}
          >
            <ListValidateRules />
          </Grid>
        </Grid>
        <Box className={classNames(styles.ReCaptcha)}>
          Тут будет капча
        </Box>
        <Actions classes={{root: stylesReset.Actions}}>
          <Button
            variant="contained"
            color="primary"
            className={styles.Button}
            disabled={!valid}
            type="submit"
            disableRipple
          >
            Renew Password
          </Button>
        </Actions>
      </Form>
      <Box className={classNames(styles.IsTrouble)}>
        <p>
          or
          {' '}
          <Link to="/login">Go back</Link>
          {' '}
          to Login Page
        </p>
      </Box>
    </Box>
  )
}

export default reduxForm({
  form: 'enterNewPassword'
})(EnterNewPasswordForm);
