// @flow

// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get, filter } from 'lodash';
import { Editor } from 'draft-js';

// Components
import Box from '@material-ui/core/Box';

import Button from '@components/Button';
import { H1 } from '@components/Typography';
import { Input } from '@components/Form';

import AddButton from '@views/Builder/components/AddButton';

import EditorPanel from '../../components/EditorPanel';

// Icons
import { ReactComponent as IconPlus } from '@assets/icons/plus.svg';
import { ReactComponent as IconList } from '@assets/icons/list.svg';

// Styles
import 'draft-js/dist/Draft.css';
import styles from './Text.scss';

// Services
import { updateBoards } from '@services/boards';
import { openModal, closeModal } from '@services/modals';

// Hooks
import useTextContainer from './hooks';

const STYLE_MAP = {
  HIGHLIGHT: {
    backgroundColor: '#d2f3ec !important',
  }
};

const TextContainer = (props) => {
  const {
    convertToBoldHandler,
    convertToListHandler,
    convertToLinkHandler,
    convertTextStyleHandler,
    editorState,
    editorRef,
    isSelected,
    focusPosition,
    // setEditorState,
    blockRendererFn,
    readOnly,
    setReadOnlyState,
    handleReturn,
    handleKeyCommand,
    page,
    handleSectionsOrder,
    handleSectionEdit,
    handleBlocker,
    handleCreate,
    handleChange,
    editorFocusHandler,
    session,
    isEdit,
    isLogin,
    isLimited,
    isLocked,
    error,
  } = useTextContainer(props);
  
  return (
    <div
      className={styles.Root}
      id="EDITOR_CONTAINER"
      // onClick={() => {
      //   console.log('editorFocusHandler', editorFocusHandler);
      //   editorFocusHandler();
      // }}
    >
      {
        isLimited ? (
          <Input
            classes={{
              base: styles.TitleBase,
              input: styles.Title
            }}
            key="title"
            label="Enter section name"
            name="title"
            placeholder="Enter section name"
            error={error}
            onFocus={() => setReadOnlyState(true)}
            onBlur={() => setReadOnlyState(false)}
            required
          />
        ) : (
          <div
            className={styles.Headline}
          >
            <H1
              className={styles.TitleHeading}
            >
              {get(page, 'title')}
            </H1>
            {
              isLogin && (
                <Box className={styles.Actions}>
                  {
                    isEdit 
                      && (
                        <>
                          <Button
                            variant="text"
                            className={styles.Button}
                            startIcon={<IconPlus />}
                            onClick={handleSectionEdit}
                          >
                            Add section
                          </Button>
                          {
                            filter(get(page, 'sections', []), { isUntitled: false }).length > 1
                            && (
                              <Button
                                variant="text"
                                className={styles.Button}
                                startIcon={<IconList />}
                                onClick={handleSectionsOrder}
                              >
                                Change sections order
                              </Button>
                            )
                          }
                        </>
                      )
                  }
                </Box>
              )
            }
          </div>
        )
      }
      <div
        className={styles.EditorWrapper}
      >
        <Editor
          customStyleMap={STYLE_MAP}
          name="content"
          editorRef={editorRef}
          editorState={editorState}
          onChange={handleChange}
          preserveSelectionOnBlur
          blockRendererFn={blockRendererFn}
          placeholder={isEdit && 'Enter text'}
          readOnly={readOnly || (!get(page, 'isLocked')) || (!isLimited && !isEdit)}
          handleReturn={handleReturn}
          handleKeyCommand={(command) => handleKeyCommand(command)}
        />
        <EditorPanel
          boldButtonHandler={convertToBoldHandler}
          listButtonHandler={convertToListHandler}
          linkButtonHandler={convertToLinkHandler}
          textStyleHandler={convertTextStyleHandler}
          editorState={editorState}
          editorNode={editorRef}
          isLimited={isLimited}
          isSelected={(isLimited || isEdit) && isSelected}
          focusPosition={focusPosition}
        />
        <AddButton
          onCreate={handleCreate}
        />
      </div>
    </div>
  )
};

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  isEdit: get(state, 'session.isEdit', false),
  session: get(state, 'session', false),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  openModal: (id) => dispatch(openModal(id)),
  closeModal: (id) => dispatch(closeModal(id)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(TextContainer);
