// Libraries
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';

// Selectors

// GraphQL
const query = loader('../graphql/queries/getRecentSingleAssets.gql');

export const useGetRecentSingleAssets = () => {
  const { id } = useSelector(state => state.session);
  const [recentSingleAssets, setRecentSingleAssets] = useState([]);

  const { data, loading } = useQuery(query, { variables: { userId: id } });

  useEffect(() => {
    if (data?.recentSingleAssets) {
      setRecentSingleAssets(data.recentSingleAssets);
    }
  }, [data]);

  return { loadRecentAssets: loading, recentSingleAssets };
};
