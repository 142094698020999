import {
  SESSION_SIGN_IN,
  SESSION_SIGN_OUT,
  SESSION_UPDATE
} from './types';

export const signIn = data => ({ type: SESSION_SIGN_IN, payload: data });

export const signOut = () => ({ type: SESSION_SIGN_OUT });

export const updateSession = data => ({ type: SESSION_UPDATE, payload: data });
