// @flow

// Libraries
import * as React from 'react';
import { EditorState } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { get, isFunction } from 'lodash';

// Context
import LongReadContext from '../../containers/LongRead/context';

type UseSeparatedLinkTypes = {
  block: {
    getEntityAt: (number) => void,
  },
  contentState: {
    getEntity: (string) => void,
    replaceEntityData: (string) => void,
  },
  data?: Object,
  onUpdate?: Function,
  onAdd?: Function,
  onRemove?: Function,
  onDelete?: Function,
};

export default ({
  contentState,
  block,
  data,
  onUpdate,
  onAdd,
  onRemove,
  onDelete,
  ...props
}: UseSeparatedLinkTypes) => {
  
  const CONTEXT = React.useContext(LongReadContext);

  const blockEntity = block && block.getEntityAt(0);
  const currentEntity = blockEntity && contentState.getEntity(blockEntity);
  
  const entity = currentEntity && currentEntity.getData ?
      currentEntity.getData() :
      {};

  const [isModal, setIsModal] = React.useState(false);
  const [src, setSrc] = React.useState(get(data, 'src'));
  const [caption, setCaption] = React.useState(get(data, 'caption'));
  const [isExternal, setIsExternal] = React.useState(get(data, 'isExternal'));

  React.useEffect(() => {
    CONTEXT && CONTEXT.setReadOnlyState(isModal);
  }, [isModal]);

  const handleUpdate = () => {

    const data = {
      src,
      caption,
      isExternal
    };
    isFunction(onUpdate) && onUpdate(data);
    setIsModal(false);
  };

  const handleDelete = props => {
    isFunction(onDelete) && onDelete(block?.key);
  };

  return {
    CONTEXT,
    data,
    entity,
    handleUpdate,
    handleDelete,
    isModal, setIsModal,
    src, setSrc,
    caption, setCaption,
    isExternal, setIsExternal,
  };
};
