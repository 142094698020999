import * as React from 'react';
import { v4 as uuid4 } from 'uuid';

// Data
import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';

export const DO_OPTIONS = [
  {
    id: uuid4(),
    title: 'Do',
    value: 'Do',
    icon: <CheckIcon />
  },
  {
    id: uuid4(),
    title: 'Don\'t',
    value: 'Dont',
    icon: <CrossIcon />
  },
];
