// Libraries
import { loader } from 'graphql.macro';
import { useLazyQuery, useQuery } from 'react-apollo';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

// GraphQL
const query = loader('../graphql/queries/getCollectionAssets.gql');

export const useGetCollectionAssets = id => {
  const user = useSelector(state => get(state, 'session'));
  const [assets, setAssets] = useState([]);
  const [getAssets, { loading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-first',
    onCompleted: data => {
      if (data?.collection) {
        setAssets(data.collection?.assets)
      }
    },
    onError: error => console.error(error)
  });

  useEffect(() => {
    if (user.id) {
      getAssets({ variables: { id } });
    }
  }, [user]);

  return {
    loading,
    assets
  };
};
