// Libraries
import * as yup from 'yup';

import { asyncValidator } from '@components/Form';

// Config
import { ERROR_FIELD_REQUIRED } from '@config';

export const validate = (values: object) => ({});

export const asyncValidate = asyncValidator(
  yup.object().shape({
    title: yup
      .string()
      .required(ERROR_FIELD_REQUIRED),
    // .max(32)
    body: yup
      .string()
      .required(ERROR_FIELD_REQUIRED),
  })
);
