// @flow
import * as React from 'react';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import Button from '@components/Button';
import { Caption } from '@components/Typography';

import { Loading } from './Loading';
import { Preview } from './Preview';

// Styles
import styles from './Video.scss';

// Contexts
import DropzoneContext from '../context';

// Data
import { VARIANTS } from '../data';

type LabelPropTypes = {
  variant?: String
};

export const Video = ({
  variant = VARIANTS.DEFAULT
}: LabelPropTypes) => {

  const {
    setIsModal,
    handleEdit,
  } = React.useContext(DropzoneContext);
  
  switch (variant) {
    case VARIANTS.PREVIEW:
      return <Preview />;
    case VARIANTS.HOVER:
      return 'Drop video file here';
    case VARIANTS.LOADING:
      return <Loading />;
    default:
      return (
        <>
          <Button
            variant="outlined"
            color="primary"
            className={styles.Button}
            onClick={handlePrevent(handleEdit)}
          >
            Upload video
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={styles.Button}
            onClick={handlePrevent(() => setIsModal(true))}
          >
            Add video by link
          </Button>
          <Caption
            className={styles.Caption}
          >
            or drop video file here
          </Caption>
        </>
      );
  }
};

export default Video;
