export const ErrorMessages = {
  PwdDoestMatch: 'Passwords must be match',
  MinLength: 'Min. length must be 10 symbols',
  Required: 'Field is required',
  UpperAndLowerCase: 'Field must be contains upper + lowercase symbols',
  Digits: 'Field must be contains digits from 0-9',
  SpecialSymbol: 'Field must be contains special characters'
}

type Passwords = {
  new_password: string;
  confirm_password: string;
}

export const requiredField = (value: string): boolean => {
  return !value && ErrorMessages.Required;
}

export const checkValidEmail = (value: string): boolean =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    && 'Invalid email address';

export const passwordMustMatch = (value: string, allValue: Passwords): boolean => {
  return allValue.new_password !== value &&
    ErrorMessages.PwdDoestMatch;
}

export const minLength = (value: string): boolean => {
  return value?.length < 10 && ErrorMessages.MinLength;
}

export const checkOnUpperAndLowerCase = (value: string): boolean => {
  return !/^(?=.*[a-z])(?=.*[A-Z])/.test(value) &&
    ErrorMessages.UpperAndLowerCase;
}

export const checkOnDigits = (value: string): boolean => {
  return !/^(?=.*[0-9])/.test(value) && ErrorMessages.Digits;
}

export const checkOnSpecialSymbols = (value: string): boolean => {
  return !/(?=.*[-+_!@#$%^&*., ?])/.test(value)
}
