import { AnyAction } from 'redux';

const initialState = {
  favoriteAssets: []
};

export const SET_FAVORITE_ASSETS = 'SET_FAVORITE_ASSETS';

export default (
  state = initialState,
  action: AnyAction
): ReturnType<typeof state> => {
  switch (action.type) {
    case SET_FAVORITE_ASSETS:
      return {
        ...state,
        favoriteAssets: action.payload.favoriteAssets
      };
    default:
      return state;
  }
};

export const setFavoriteAssets = (assets): AnyAction => {
  return {
    type: SET_FAVORITE_ASSETS,
    payload: { favoriteAssets: assets }
  };
};
