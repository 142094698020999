// Libraries
import React, { memo, useCallback, useState } from 'react';
import classNames from 'classnames';

// Selectors

// Components
import { DropDown } from '../DropDown';
import Button from '../../../../components/Button';
import { Subtitle } from '../../../../components/Typography';
import { AssetCard } from '../../../../components/AssetCard';

// Hooks
import { useGetRecentCollections } from '../../../../hooks/useGetRecentCollections';
import {
  useGetRecentSingleAssets,
  loadRecentAssets
} from '../../../../hooks/useGetRecentSingleAssets';

// Icons
import { ReactComponent as Backup } from '@assets/icons/gray_backup.svg';

// Styles
import styles from './Header.scss';

const Recent = () => {
  const { recentSingleAssets } = useGetRecentSingleAssets();
  const { recentCollections } = useGetRecentCollections();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderItems = useCallback((title, items = []) => {
    return (
      <div className={styles.RecentItems}>
        <Subtitle className={styles.RecentTitle}>Recent {title}</Subtitle>
        {items.map((item, index, items) => (
          <AssetCard {...item} items={items} key={item.id} short />
        ))}
      </div>
    );
  }, []);

  return (
    <>
      <Button
        size="small"
        startIcon={<Backup />}
        className={classNames({
          [styles.BackUp]: true,
          [styles.Active]: open
        })}
        onClick={handleOpen}
      >
        <span className={styles.ButtonText}>Recent</span>
        <DropDown hidden={!open}>
          <div className={styles.Recent}>
            {renderItems('Assets', recentSingleAssets.slice(0, 5))}
            {renderItems('Collections', recentCollections.slice(0, 5))}
          </div>
        </DropDown>
      </Button>
      {open && <div className={styles.Modal} onClick={handleClose} />}
    </>
  );
};

export default memo(Recent);
