// Libraries
import { loader } from 'graphql.macro';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useSelector } from 'react-redux';

// Actions

// Selectors

// GraphQL
const query = loader('../graphql/queries/getRecentCollections.gql');

export const useGetRecentCollections = () => {
  const { id } = useSelector(state => state.session);
  const [recentCollections, setRecentCollections] = useState([]);

  const { data, loading } = useQuery(query, { variables: { userId: id } });

  useEffect(() => {
    if (data?.recentCollections) {
      setRecentCollections(data.recentCollections);
    }
  }, [data]);

  return { loading, recentCollections };
};
