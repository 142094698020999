// @flow
import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

// Components
import BaseAutocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextField from '@components/TextField';

// Styles
import styles from './Autocomplete.scss';

export type PropTypes = {
  classes?: {
    root?: String,
    textField?: String,
  },
  className?: String,
  name?: String,
  type?: String,
  value?: any,
  variant?: String,
  placeholder?: String,
};

const Autocomplete = ({
  className,
  classes = {},
  name = '',
  type = 'text',
  value,
  variant = 'standard',
  placeholder = 'Enter to search',
  ...props
}: PropTypes): React.Element<BaseAutocomplete> => {
  return (
    <BaseAutocomplete
      className={classNames(
        className,
        get(classes, 'root'),
        styles.Root,
      )}
      loadingText={<CircularProgress size={20} className={styles.Progress} />}
      value={value}
      renderInput={(inputParams) => (
        <TextField
          {...inputParams}
          className={classNames(
            get(classes, 'textField'),
            styles.TextField,
          )}
          name={name}
          type={type}
          variant={variant}
          placeholder={placeholder}
        />
      )}
      {...props}
    />
  );
};

export default Autocomplete;
