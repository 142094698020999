// @flow
export const APP_NAME = 'kaspersky-wiki';
export const APP_VERSION = 1;

export const PRODUCTION = 'brand.kaspersky.com';
export const BETA = 'beta-bp.dnadev.ru';
export const DEVELOP = 'localhost:3000';

const API = {
  [PRODUCTION]: 'https://brand.kaspersky.com/graphql',
  [BETA]: 'https://beta-bp.dnadev.ru/graphql',
  [DEVELOP]: 'https://kasp-brand-staging.dnadev.ru/graphql'
};

export const HOST = window.location.host;

export const API_URI = API[HOST] || API[DEVELOP];
