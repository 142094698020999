// @flow

// Libraries
import * as React from 'react';

import { get, isFunction } from 'lodash';
import { useDropzone as useBaseDropzone } from 'react-dropzone';

// Data
import { TYPES, VARIANTS, FILE_TYPES } from './data';

type PropTypes = {
  type?: String,
  loading?: Boolean,
  onFocus?: Function,
  onBlur?: Function,
  onDropped?: Function,
  onLink?: Function,
  onEdit?: Function,
  onDelete?: Function,
  onSlide?: Function,
  slideIndex?: Number,
  slidesLength?: Number,
  data?: Any,
  external?: Boolean,
  maxFiles?: Number,
  multiple?: Boolean,
  onChange?: Function,
  disabled?: Boolean,
};

const useDropzone = ({
  type = TYPES.IMAGE,
  loading,
  onFocus,
  onBlur,
  onDropped,
  onEdit,
  onLink,
  onDelete,
  onSlide,
  slideIndex,
  slidesLength,
  data,
  external = false,
  maxFiles = 1,
  multiple = false,
  onChange,
  disabled = false,
  ...props
}: PropTypes): Object => {

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    open,
  } = useBaseDropzone({
    ...(FILE_TYPES[type] ? { accept: FILE_TYPES[type] } : {}),
    onDrop: (files) => {
      const result = isFunction(onDropped) ? onDropped(files) : files;
      isFunction(onChange) && onChange(result);
    },
    maxFiles,
    multiple,
    noDragEventsBubbling: true,
    disabled
  });

  const [isModal, setIsModal] = React.useState(false);
  const [link, setLink] = React.useState();

  const getVariant = () => {
    switch (true) {
      case isDragActive:
        return VARIANTS.HOVER;
      case !!get(data, 'src'):
        return VARIANTS.PREVIEW;
      case loading:
        return VARIANTS.LOADING;
      default:
        return VARIANTS.DEFAULT;
    }
  };

  React.useEffect(() => {
    
  }, [isModal]);

  const handleFocus = (event) => {
    isFunction(onFocus) && onFocus(event); 
  };

  const handleBlur = (event) => {
    isFunction(onBlur) && onBlur(event); 
  };
  
  const handleEdit = (event) => {
    isFunction(onEdit) && onEdit(event); 
    open();
  };

  const handleDelete = (event) => {
    isFunction(onDelete) && onDelete(event);
  };

  const handleSlide = onSlide;

  const handleLink = (event) => {
    isFunction(onLink) && onLink(link);
    setIsModal(false);
  };

  return {
    // props
    loading,
    slideIndex,
    slidesLength,
    external,
    data,
    type,
    disabled,
    // hook
    isModal, setIsModal,
    link, setLink,
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    // inner
    handleFocus,
    handleBlur,
    handleEdit,
    handleDelete,
    handleSlide,
    handleLink,
    getVariant,
  };
};

export default useDropzone;
