import arrayMove from 'array-move';
import {v4 as uuidv4} from 'uuid';
import { get, set, find, filter, reject, forEach, map, join } from 'lodash';

// Types
import {
  BUFFERS_UPDATE,
  BOARDS_UPDATE,
  FILTER_PAGES,
  DELETE_PAGE,
  MOVE_PAGES,
  CREATE_PAGE,
  DELETE_SECTION,
  CREATE_SECTION,
  UPDATE_SECTION,
  UPSERT_SECTIONS,
  UPDATE_TABS
} from '@services/boards/types';

// Data
import DATA, { pageTemplate, GENERATOR } from './data/boards';

const INITITAL_STATE = {
  list: {
    ...DATA
  },
  buffers: {}
};

export default (state = INITITAL_STATE, action) => {
  const board = get(action, 'payload.board', 'wiki');
  const list = get(state, 'list', {});
  const sys = get(state, 'list.sys', {});
  const mainPage = get(state, 'list.sys.pages[0]', {});
  const buffers = get(state, 'buffers', {});
  const {
    pages,
    mapped
  } = get(action, `payload.pages`) ? GENERATOR(get(action, `payload.pages`)) : get(state, `list.[${board}]`);
  const keyword = get(action, 'payload.keyword');
  // const section = get(mapped, `[${get(action, 'payload.sectionId')}]`);
  const sectionData = get(action, 'payload.sectionData', {});
  // const page = buffers[pageId] || find(pages, { id: pageId });
  const page = { ...get(action, 'payload.page', {}) };
  const pageId = get(page, 'id');
  const sections = get(action, 'payload.sections');

  const isHard = get(action, 'isHard', false);

  const tabs = get(action, 'payload');

  // Cleaner
  // set(state, 'buffers', filter(buffers, item => {
  //   const original = get(mapped, `[${get(item, 'id')}]`);
  //   const originalDate = (new Date(get(item, `updatedAt`, 0))).getTime();
  //   const itemDate = (new Date(get(original, `updatedAt`, 0))).getTime();
  //   return original && originalDate < itemDate;
  // }));

  switch (get(action, 'type')) {
    case BUFFERS_UPDATE:
      return {
        ...state,
        buffers: {
          ...(!isHard && buffers),
          ...get(GENERATOR(get(action, 'payload')), 'mapped')
        }
      };
    case BOARDS_UPDATE:
      return {
        ...state,
        list: {
          ...list,
          ...(forEach(get(action, 'payload'), (board, key) => {
            set(action, `payload[${key}]`, {
              ...board,
              ...GENERATOR(get(board, 'pages', []))
            });
          }))
        }
      };
    case FILTER_PAGES:
      return { 
        ...state,
        list: {
          ...list,
          [board]: {
            pages,
            mapped,
            filtered: highlightKeyword(
              filterPages(pages, keyword),
              keyword
            )
          }
        }
      };
    case DELETE_PAGE:
      return {
        ...state,
        list: {
          ...list,
          [board]: {
            mapped: reject(mapped, mapped[get(action, 'payload.id')]),
            pages: reject(pages, { id: get(action, 'payload.id') })
          }
        }
      };
    case MOVE_PAGES:
      return {
        ...state,
        list: {
          ...list,
          [board]: {
            mapped,
            pages: arrayMove(pages, get(action, 'payload.oldIndex', 0), get(action, 'payload.newIndex', 0))
          }
        }
      };
    case CREATE_PAGE:
      return {
        ...state,
        list: {
          ...list,
          [board]: {
            mapped,
            pages: createPage(pages)
          }
        }
      };
    case DELETE_SECTION:
      return {
        ...state,
        buffers: {
          ...buffers,
          [pageId]: upsertSections(
            {
              page,
              sectionData,
              isDrop: true
            }
          )
        }
      }
    case CREATE_SECTION:
    case UPDATE_SECTION:
      return {
        ...state,
        buffers: {
          ...buffers,
          [pageId]: upsertSections(
            {
              page,
              sectionData,
            }
          )
        }
      }
    case UPSERT_SECTIONS:
      return {
        ...state,
        buffers: {
          ...buffers,
          [pageId]: upsertSections(
            {
              page,
              sectionData: get(sections, '[0]'),
              sections
            }
          )
        }
      }
    case UPDATE_TABS:
      return {
        ...state,
        list: {
          ...list,
          sys: {
            ...sys,
            pages: [
              {
                ...mainPage,
                tabs
              }
            ]
          }
        }
      };
    default:
      return {...state};
  }
};

const upsertSections = ({
  page,
  sectionData,
  isDrop = false,
  sections
}) => {
  const sectionBlockKey = get(sectionData, 'blockKey');
  // const page = find(pages, { id: get(sectionData, 'pageId')});
  const pageSections = sections ? sections : get(page, 'sections', []);

  const blocks = [];
  let entityMap = {};
  let entityMapIndex = 0;

  const filledSections = (isDrop
    ? filter(pageSections, (section) => get(section, 'blockKey') !== sectionBlockKey)
    : !sectionBlockKey
        ? [ ...pageSections, sectionData]
        : pageSections
        )
    .map(item => {
      const section = get(item, 'blockKey') === sectionBlockKey ? sectionData : item;
      const sectionBlocks = [...get(section, 'content.blocks', [])];
      const sectionEntityMap = {...get(section, 'content.entityMap', {})};
      let sectionEntityMapIndex = 0;
  
      forEach(sectionBlocks, (block) => {
        if (get(block, 'type') === 'atomic') {
          set(entityMap, `[${entityMapIndex}]`, sectionEntityMap[sectionEntityMapIndex++]);
          set(block, 'entityRanges[0].key', entityMapIndex++);
        }
      });
      
      !get(section, 'isUntitled') && blocks.push(
        {
          key: get(section, 'blockKey', uuidv4()),
          text: get(section, 'title'),
          type: "header-two",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {}
        }
      );

      blocks.push(
        ...sectionBlocks
      );

      return section;
    });

  set(
    page,
    'sections',
    filledSections
  );

  set(
    page,
    'content',
    {
      blocks,
      entityMap
    }
  );

  set(page, 'updatedAt', Date.now());

  return get(GENERATOR([page]), 'pages.[0]');

};

const createPage = (items) => {
  const newItem = pageTemplate();
  return [...items.slice(0, 0), newItem, ...items.slice(0)];
};

const filterPages = (items, keyword) => {
  const lowerKeyword = keyword.toLowerCase();
  return filter(items, item => (
    get(item, 'title', '')?.toLowerCase().includes(lowerKeyword)
    || get(item, 'description', '')?.toLowerCase().includes(lowerKeyword)
    || filter(get(item, 'content.blocks', []), item => (
      get(item, 'text', '')?.toLowerCase().includes(lowerKeyword)
    )).length
    // || item.text.toLowerCase().includes(keyword.toLowerCase())
  ));
};

const highlightKeyword = (items, keyword) => {
  const lowerKeyword = keyword.toLowerCase();
  return items.map(item => {
    const newItem = { ...item };
    newItem.title = replaceStr(newItem.title, lowerKeyword);
    newItem.description = replaceStr(newItem.description, lowerKeyword);
    forEach(get(newItem, 'content.blocks', []), item => {
      if (get(item, 'text', '').toLowerCase().includes(lowerKeyword)){
        set(newItem, 'description', `${get(newItem, 'description')}...<br/>${replaceStr(get(item, 'text', ''), lowerKeyword)}`);
      }
    });
    // newItem.text = replaceStr(newItem.text, keyword);
    return newItem;
  });
};

const replaceStr = (str, keyword) => {
  const subStrPos = str?.toLowerCase().indexOf(keyword.toLowerCase());
  return str?.replace(
      new RegExp(keyword, 'gi'),
      `<span>${str.slice(subStrPos, subStrPos + keyword.length)}</span>`
  );
};
