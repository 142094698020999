// @flow

// Libraries
import * as React from 'react';
import { get } from 'lodash';
import Iframe from 'react-iframe'

// Components
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Button from '@components/Button';
import Modal from '@components/Modal';
import TextAreaField from '@components/TextAreaField';
import Link from '@components/Link';

// Styles
import styles from './FigmaFrame.scss';

// Hooks
import useSeparatedLink from './hooks';

// Icons
import { ReactComponent as EditIcon } from '@assets/icons/SeparateLink/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/SeparateLink/delete.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/SeparateLink/arrow.svg';

type SeparatedLinkTypes = any;

const FigmaFrame = React.forwardRef((props: SeparatedLinkTypes, ref) => {

  const {
    entity,
    data,
    handleUpdate,
    handleDelete,
    isModal, setIsModal,
    src, setSrc,
    caption, setCaption,
    isExternal, setIsExternal,
    CONTEXT
  } = useSeparatedLink(props);

  const isEdit = get(CONTEXT, 'isEdit');

  const parseIframeBlock = () => {
    const c = document.createElement('div');
    c.innerHTML = get(data, 'caption') || 'Link';

    return {
      width: c.firstChild.width,
      height: c.firstChild.height,
      src: c.firstChild.src,
    }
  };

  return (
    <div
      className={styles.Root}
      ref={ref}
    >
      <Iframe
        url={parseIframeBlock().src}
        width="1044"
        height={parseIframeBlock().height}
        id=""
        className=""
        display="block"
        position="relative"
      />

      {isEdit && (
        <>
          <ButtonGroup
            className={styles.ActionsPanel}
            disableElevation
            disableRipple
          >
            <Button
              onClick={() => setIsModal(true)}
              variant="text"
              className={styles.ButtonEdit}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={handleDelete}
              variant="text"
              className={styles.ButtonDelete}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
          <Modal
            classes={{
              content: styles.ModalContent
            }}
            title="Copy public Figma embed code"
            onClose={(event) => setIsModal(false)}
            open={isModal}
            maxWidth="md"
            fullWidth
          >
            <TextAreaField
              className={styles.ModalInput}
              name="name"
              variant="outlined"
              onChange={(event) => setCaption(get(event, 'currentTarget.value'))}
              defaultValue={caption}
              placeholder="Paste Figma URL here"
            />
            <Button
              className={styles.ModalButton}
              variant="contained"
              onClick={(event) => handleUpdate()}
            >
              Add figma link
            </Button>
          </Modal>
        </>
      )}
    </div>
  )
});

// Exports
export default FigmaFrame;
