// Libraries
import classNames from 'classnames';
import React, { memo, ReactNode } from 'react';

// Styles
import styles from './DropDown.scss';

type PropTypes = {
  hidden: boolean,
  className?: string,
  children: ReactNode | string
};

const Dropdown = ({ children, hidden, className = '' }: PropTypes) => {
  if (hidden) {
    return null;
  }

  return (
    <div className={classNames(styles.BackDrop, className)}>{children}</div>
  );
};

export default memo(Dropdown);
