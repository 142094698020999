// Libraries
import React from 'react';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';

// Services
import { updateLoader } from '@services/loader';

// GraphQL
const query = loader('src/graphql/mutations/contactUs.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

export const useContactUs = ({ isLoader = true, board }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useMutation(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    setNormalized(get(data, 'createWikiFeedback.wikiFeedback'));
  }, [loading, data]);

  return {
    load,
    loading,
    result: normalized,
    error: error,
  };
};

export default useContactUs;
