// @flow
import { isFunction } from 'lodash';

export const handlePrevent = (handler: Function): Function => {
  return event => {
    event.preventDefault();
    event.stopPropagation();
    return isFunction(handler) ? handler(event) : event;
  };
};
