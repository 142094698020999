import React from 'react';
import classNames from 'classnames';

// Components

// Hooks
import useBlock from './hooks';

// Styles
import styles from './Block.scss';

type PropTypes = {
  classes: {
    root?: string,
    dropzone?: string,
  };
  className: String,
  data: Object,
};

const Block = ({
  className,
  classes = {},
  data,
  ...props
}: PropTypes) => {
  const {
    CONTEXT,
    type,
    entities,
    blockRef,
    inSelectedRange,
    Component,
    extraProps,
    handleUpdate,
    handleAdd,
    handleRemove,
    handleDelete,
  } = useBlock(props);
  return (
    <div
      className={
        classNames(
          className,
          classes.root,
          styles.Root
        )
      }
      data-type={type}
      ref={blockRef}
    >
      {
        entities.map((item, index) => (
          <Component
            key={item.id}
            index={index}
            blockType={type}
            {...extraProps}
            {...props}
            selected={inSelectedRange}
            onUpdate={handleUpdate(index)}
            onAdd={handleAdd}
            onRemove={handleRemove(index)}
            onDelete={handleDelete}
            data={item}
          />
        ))
      }
    </div>
  );
};

export default Block;
