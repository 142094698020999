//Libraries
import { find, get } from "lodash";
import React, { memo } from "react"
import { useSelector } from "react-redux";

//Components
import Tab from "./Tab";
import { EditMode } from "./EditMode";

import styles from "./TableMenu.scss";

const TableMenu = (props) => {
  const { tabs } = useSelector(state => find(get(state, 'boards.list.sys.pages'), { name: 'main_banner' }));
  const isEdit = useSelector(state => get(state, 'session.isEdit', false));
  const filtered = tabs?.filter(tab => tab).length;

  if (!filtered && !isEdit) {
    return null;
  }

  return (
    <div className={styles.TableMenu} data-is-edit={isEdit}>
      <EditMode {...props} tabs={tabs}>
        {filtered > 1 && tabs?.map((tab, idx) => {
          return <Tab key={idx} {...tab} />
        })}
      </EditMode>
    </div>
  )
}

export default memo(TableMenu);
