// @flow

// Libraries
import React, { useState, Element } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { get, find } from 'lodash';

// Components
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '../../../../components/Button';

// Icons
import { ReactComponent as BackIcon } from '@assets/icons/back.svg';
import { ReactComponent as UserIcon } from '@assets/icons/gray_user.svg';
import { ReactComponent as EditModeIcon} from '@assets/icons/inactive_edit_mode.svg';

// Styles
import styles from './Menu.scss';
import classNames from 'classnames';

type Item = {
  value: string,
  wrapped: boolean
}

type PropsType = {
  orientation: String,
  classes?: Object,
  items: Item[],
  isLogin?: Boolean
}

const HOME_LINK = '/wiki';

const Menu = ({ orientation, classes, items, isLogin }: PropsType): Element<'div'> => {
  const history = useHistory();
  const isMatchHome = get(useRouteMatch(HOME_LINK), 'isExact');
  const { pathname } = useLocation();
  
  const [value, setValue] = useState(get(items, '[0].link'));

  const handleChange = (event, value) => {
    history.push(get(find(items, { link: value }), 'link', value || HOME_LINK));
    // window.location.reload();
    setValue(value);
  };

  const isVertical = orientation === 'vertical';

  return (
    <>
      <Tabs
        variant="standard"
        orientation={orientation}
        className={classes.root}
        classes={{root: styles.Tabs}}
        value={value}
        onChange={handleChange}
      >
        {
          isVertical && !isMatchHome && (
            <Tab
              classes={{root: styles.TabHome}}
              icon={<BackIcon />}
              label="Home"
              selected={false}
              wrapped={false}
              key={HOME_LINK}
            />
          )
        }
        {
          items.map((item: Item, index: number): Element<Tab> =>
            (
              <Tab
                classes={{root: classNames({
                  [styles.Tab]: true,
                  // [styles.Selected]: item.link === pathname
                })}}
                value={item.link}
                label={item.title}
                wrapped={item.wrapped}
                key={`${item.link + index}`}
              />
            )
          )
        }
      </Tabs>
      {
        isVertical && isLogin && 
        (
          <div className={styles.SubMenu}>
            {/* <Button
              size="small"
              startIcon={<UserIcon />}
              to="/login"
            >
              <span>User Profile</span>
            </Button> */}
            <Button
              size="small"
              startIcon={<EditModeIcon />}
            >
              <span>
                Edit mode
                <span>(Not available at mobile version)</span>
              </span>
            </Button>
          </div>
        )
      }
    </>
  )
}

export default Menu;
