// @flow
import { get } from 'lodash';

// Types
import {
  UPDATE_LOADER,
} from './types';

export const INITIAL_STATE = {
  loading: false,
  progress: 0,
};

export default (state: Object = INITIAL_STATE, action: Action) => {
  switch (get(action, 'type')) {
    case UPDATE_LOADER:
      return {
        ...state,
        ...get(action, 'payload'),
      };
    default:
      return state;
  }
};
