// Libraries
import { v4 as uuidv4 } from 'uuid';

// Assets
import imagesIcon from '@assets/icons/images.svg';
import videoIcon from '@assets/icons/video.svg';
import assetLinkIcon from '@assets/icons/asset_link.svg';
import assetCardIcon from '@assets/icons/asset_card.svg';
import linkIcon from '@assets/icons/link.svg';
import figmaIcon from '@assets/icons/figma.svg';

import oneImage from '@assets/icons/actionButtons/image/1image.svg';
import twoImages from '@assets/icons/actionButtons/image/2images.svg';
import threeImages from '@assets/icons/actionButtons/image/3images.svg';
import carousel from '@assets/icons/actionButtons/image/carousel.svg';
import slider from '@assets/icons/actionButtons/image/slider.svg';
import twoDo_dont from '@assets/icons/actionButtons/image/2do&dont.svg';
import threeDo_dont from '@assets/icons/actionButtons/image/3do&dont.svg';

import horisontalCard from '@assets/icons/actionButtons/cards/horisontal.svg';
import verticalCard from '@assets/icons/actionButtons/cards/vertical.svg';

// Types
import { WIKI_ENTITIES } from '@services/boards/types';

const {
  IMAGE,
  TWO_IMAGES,
  THREE_IMAGES,
  TWO_DO_IMAGES,
  THREE_DO_IMAGES,
  VIDEO,
  SLIDER,
  CAROUSEL,
  HORISONTAL_CARDS,
  VERTICAL_CARDS,
  ASSET_BUTTON,
  SEPARATED_LINK,
  FIGMA_FRAME
} = WIKI_ENTITIES;

export default [
  {
    id: uuidv4(),
    title: 'Add images',
    icon: imagesIcon,
    type: 'image',
    subMenu: [
      {
        id: uuidv4(),
        icon: oneImage,
        title: '1 image',
        type: IMAGE
      },
      {
        id: uuidv4(),
        icon: twoImages,
        title: '2 images',
        type: TWO_IMAGES
      },
      {
        id: uuidv4(),
        icon: threeImages,
        title: '3 images',
        type: THREE_IMAGES
      },
      {
        id: uuidv4(),
        icon: carousel,
        title: 'Carousel',
        type: CAROUSEL
      },
      {
        id: uuidv4(),
        icon: slider,
        title: 'Slider',
        type: SLIDER
      },
      {
        id: uuidv4(),
        icon: twoDo_dont,
        title: '2 DOs and DON\'Ts',
        type: TWO_DO_IMAGES
      },
      {
        id: uuidv4(),
        icon: threeDo_dont,
        title: '3 DOs and DON\'Ts',
        type: THREE_DO_IMAGES
      },
    ]
  },
  {
    id: uuidv4(),
    title: 'Add video',
    icon: videoIcon,
    type: VIDEO
  },
  {
    id: uuidv4(),
    title: 'Add asset button',
    icon: assetLinkIcon,
    type: ASSET_BUTTON
  },
  {
    id: uuidv4(),
    title: 'Add asset card',
    icon: assetCardIcon,
    type: 'asset-card',
    subMenu: [
      {
        id: uuidv4(),
        icon: horisontalCard,
        title: 'Horisontal card',
        type: HORISONTAL_CARDS
      },
      {
        id: uuidv4(),
        icon: verticalCard,
        title: 'Vertical cards',
        type: VERTICAL_CARDS
      }
    ]
  },
  {
    id: uuidv4(),
    title: 'Add separate link',
    icon: linkIcon,
    type: SEPARATED_LINK
  },
  {
    id: uuidv4(),
    title: 'Add figma live embded',
    icon: figmaIcon,
    type: FIGMA_FRAME
  },
];
