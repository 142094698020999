// @flow

// Libraries
import * as React from 'react';
import { get } from 'lodash';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@components/Button';
import Modal from '@components/Modal';
import TextField from '@components/TextField';
import Link from '@components/Link';

// Styles
import styles from './SeparatedLink.scss';

// Hooks
import useSeparatedLink from './hooks';

// Icons
import { ReactComponent as EditIcon } from '@assets/icons/SeparateLink/edit.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/SeparateLink/delete.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/SeparateLink/arrow.svg';

type SeparatedLinkTypes = any;

const SeparatedLink = React.forwardRef((props: SeparatedLinkTypes, ref) => {

  const {
    entity,
    data,
    handleUpdate,
    handleDelete,
    isModal, setIsModal,
    src, setSrc,
    caption, setCaption,
    isExternal, setIsExternal,
    CONTEXT
  } = useSeparatedLink(props);

  const isEdit = get(CONTEXT, 'isEdit');

  return (
    <div
      className={styles.Root}
      ref={ref}
    >
      <Link
        className={styles.Link}
        to={get(data, 'src')}
      >
        {get(data, 'caption') || 'Link'}
        <ArrowIcon />
      </Link>
      {isEdit && (
        <>
          <ButtonGroup 
            className={styles.ActionsPanel}
            disableElevation
            disableRipple
          >
            <Button
              onClick={() => setIsModal(true)}
              variant="text"
              className={styles.ButtonEdit}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={handleDelete}
              variant="text"
              className={styles.ButtonDelete}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
          <Modal
            classes={{
              content: styles.ModalContent
            }}
            title="Edit link"
            onClose={(event) => setIsModal(false)}
            open={isModal}
            maxWidth="md"
            fullWidth
          >
            <TextField
              className={styles.ModalInput}
              name="name"
              type="text"
              variant="outlined"
              onChange={(event) => setCaption(get(event, 'currentTarget.value'))}
              defaultValue={caption}
              placeholder="Enter link name"
            />
            <TextField
              className={styles.ModalInput}
              name="link"
              type="text"
              variant="outlined"
              onChange={(event) => setSrc(get(event, 'currentTarget.value'))}
              defaultValue={src}
              placeholder="Paste a link"
            />
            <FormControlLabel
              className={styles.ModalInput}
              name="external"
              onClick={(event, value) => {
                setIsExternal(!isExternal);
                event.preventDefault();
              }}
              control={(
                <Checkbox
                  disableRipple
                  color="primary"
                  checked={!!isExternal}
                  defaultChecked={!!isExternal}
                />
              )}
              label="Show for external users"
            />
            <Button
              className={styles.ModalButton}
              variant="contained"
              onClick={(event) => handleUpdate()}
            >
              Save changes
            </Button>
          </Modal>
        </>
      )}
    </div>
  )
});

// Exports
export default SeparatedLink;
