// @flow

// Libraries
import * as React from 'react';
import { Link } from "react-router-dom";

// Assets
import { ReactComponent as LogoImg } from '@assets/logo.svg';

// Styles
import styles from './Logo.scss';

const Logo = () => {
  return (
    <Link to="./" className={styles.Root}>
      <LogoImg />
    </Link>
  )
};

// Exports
export default Logo;
