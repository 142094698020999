// @flow

// Libraries
import * as React from 'react';
import { EditorState } from 'draft-js';
import { get, throttle, isFunction } from 'lodash';

// Hooks
import {
  useGetAssets
} from '@hooks/data';

// Context
import LongReadContext from '../../containers/LongRead/context';

type UseAssetButtonTypes = {
  block: {
    getEntityAt: (number) => void,
  },
  contentState: {
    getEntity: (string) => void,
    replaceEntityData: (string) => void,
  },
  data?: Object,
  onUpdate?: Function,
  onAdd?: Function,
  onRemove?: Function,
  onDelete?: Function,
};

export default ({
  block,
  contentState,
  data,
  onUpdate,
  onAdd,
  onRemove,
  onDelete,
  ...props
}: UseAssetButtonTypes) => {

  const CONTEXT = React.useContext(LongReadContext);

  const blockEntity = block && block.getEntityAt(0);
  const currentEntity = blockEntity && contentState.getEntity(blockEntity);
  
  const entity = currentEntity && currentEntity.getData ?
      currentEntity.getData() :
      {};

  const [src, setSrc] = React.useState(get(data, 'src'));
  const [isExternal, setIsExternal] = React.useState(get(data, 'isExternal'));
  
  const [assets, setAssets] = React.useState([]);
  const [option, setOption] = React.useState();

  const {
    load: loadGetAssets,
    loading: loadingGetAssets,
    result: resultGetAssets,
    error: errorGetAssets,
  } = useGetAssets({ isLoader: false });

  React.useEffect(() => {
    if (loadingGetAssets || !resultGetAssets){ return; }
    const nodes = get(resultGetAssets, 'nodes', []).map(node => ({
      ...node,
      title: get(node, 'name'),
      value: get(node, 'accessUrl'),
    }));
    setAssets(nodes);
  }, [loadingGetAssets, resultGetAssets]);


  React.useEffect(() => {
    setIsExternal(get(data, 'isExternal'));
  }, [data]);

  React.useEffect(() => {
    if (!option) { return; }
    const payload = {
      // ...data,
      id: get(option, 'id', get(data, 'id')),
      uid: get(option, 'uid'),
      src: get(option, 'accessUrl'),
      title: get(option, 'title'),
      isExternal,
    };
    isFunction(onUpdate) && onUpdate(payload);
    CONTEXT.setReadOnlyState(false);
  }, [option]);
  

  const handleUpdate = (event: object, option: any, reason: string) => {
    setOption(option);
  };

  const handleClose = () => {
    const payload = {
      // ...data,
      id: get(option, 'id', get(data, 'id')),
      uid: get(option, 'uid'),
      src: get(option, 'accessUrl'),
      isExternal,
    };
    isFunction(onUpdate) && onUpdate(payload);
  };

  const handleDelete = props => {
    isFunction(onDelete) && onDelete(block?.key);
  };

  const handleInput = (event: object, value: string, reason: string) => {
    setAssets([]);
    const throttled = throttle(
      () => loadGetAssets({
        variables: {
          nameContains: value
        }
      }),
      1000
    );
    value.length > 2 && throttled();
  };

  return {
    CONTEXT,
    data,
    entity,
    handleUpdate,
    handleDelete,
    handleClose,
    src, setSrc,
    option, setOption,
    isExternal, setIsExternal,
    handleInput,
    loading: loadingGetAssets,
    assets
  };
};
