// @flow

// Libraries
import * as React from 'react';
import { isFunction } from 'lodash';

// Context
import LongReadContext from '../../containers/LongRead/context';

// Types
import { getEntity } from '@services/boards/data/entities';

export default ({
  contentState,
  block,
  data,
  onUpdate,
  onAdd,
  onRemove,
  onDelete,
  ...props
}) => {
  
  const CONTEXT = React.useContext(LongReadContext);

  const blockEntity = block && block.getEntityAt(0);
  const currentEntity = blockEntity && contentState.getEntity(blockEntity);
  
  const entity = currentEntity && currentEntity.getData ?
      currentEntity.getData() :
      {};


  const handleUpdate = (data) => {
    isFunction(onUpdate) && onUpdate(data);
  };

  const handleDelete = props => {
    isFunction(onDelete) && onDelete();
  };

  const handleFocus = () => {
    CONTEXT.setReadOnlyState(true);
  };
  
  const handleBlur = () => {
    CONTEXT.setReadOnlyState(false);
  };

  const handleRemove = () => {
    isFunction(onRemove) && onRemove();
  };

  const handleAdd = () => {
    isFunction(onAdd) && onAdd(getEntity());
  };

  return {
    CONTEXT,
    entity,
    handleUpdate,
    handleDelete,
    handleFocus,
    handleBlur,
    handleAdd,
    handleRemove,
  };
};
