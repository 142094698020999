// @flow

// Libraries
import * as React from 'react';

type PropTypes = {
  children: React.Node,
  classes?: {
    root?: string,
    container?: string,
    error?: BannerClassesType
  },
  onSubmit: (SyntheticEvent<HTMLFormElement>) => ?void,
  onChange: (SyntheticEvent<HTMLFormElement>) => ?void
};

const Form = ({
  children,
  classes = {},
  onSubmit,
  onChange
}: PropTypes): React.Element<'form'> => (
  <form className={classes.root} onSubmit={onSubmit} onChange={onChange}>
    <div className={classes.container}>{children}</div>
  </form>
);

export default Form;
