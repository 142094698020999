// Libraries
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, forEach } from 'lodash';
import axios from 'axios';

// Hooks
import { useDeletePage, useUpdatePage, useGetBoards } from '@hooks/data';

// Services
import { updateLoader } from '@services/loader';

type PROPS_TYPE = {
  field?: Object,
  data?: Object
};

export default ({ field, data }: PROPS_TYPE) => {
  const reader = window.URL || window.webKitURL;

  const dispatch = useDispatch();

  const isEdited = useSelector(state => {
    const { title, description, preview, isExternal, tab } = get(
      state,
      `boards.list.wiki.mapped[${get(data, 'id')}]`,
      data
    );
    return (
      title !== get(data, 'title') ||
      description !== get(data, 'description') ||
      preview !== get(data, 'preview') ||
      isExternal !== get(data, 'isExternal') ||
      tab !== get(data, 'tab')
    );
  });

  // console.log(data);

  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState();
  const [fileMeta, setFileMeta] = React.useState();

  const {
    load: handleGetBoards,
    loading: loadingGetBoards,
    result: resultGetBoards
    // error: errorGetBoards,
  } = useGetBoards();

  const {
    load: handleDeletePage,
    loading: loadingDeletePage,
    result: resultDeletePage,
    error: errorDeletePage
  } = useDeletePage();

  const {
    load: loadUpdatePage,
    loading: loadingUpdatePage,
    result: resultUpdatePage,
    error: errorUpdatePage
  } = useUpdatePage({ onLoad: () => setLoading(false) });

  const { id, preview, title, name, description, isExternal, tab } = data;

  React.useEffect(() => {
    if (loadingGetBoards || !resultGetBoards) {
      return;
    }
    setLoading(false);
  }, [loadingGetBoards, resultGetBoards]);

  React.useEffect(() => {
    // const uploadURL = get(resultUpdatePage, 'uploadUrl');
    const uploadInfo = JSON.parse(get(resultUpdatePage, 'uploadInfo', '{}'));
    if (loadingUpdatePage || !uploadInfo || !file) {
      return;
    }
    const uploadURL = get(uploadInfo, 'url');
    const fields = get(uploadInfo, 'fields');
    const formData = new FormData();
    forEach(fields, (field, key) => {
      formData.append(key, field);
    });
    formData.append('file', file);
    setLoading(true);
    axios({
      method: 'post',
      url: uploadURL,
      data: formData,
      headers: {
        'Content-Type': get(file, 'type')
      }
    })
      .then(response => {
        // handle success
        // setLoading(false);
        handleGetBoards();
      })
      .catch(thrown => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });
  }, [loadingUpdatePage, resultUpdatePage]);

  React.useEffect(() => {
    dispatch(
      updateLoader({
        loading:
          loading || loadingGetBoards || loadingDeletePage || loadingUpdatePage
      })
    );
  }, [loading, loadingGetBoards, loadingDeletePage, loadingUpdatePage]);

  const handleDrop = files => {
    const file = get(files, '[0]');
    if (!file) {
      return;
    }
    const src = reader.createObjectURL(file);
    setFile(file);
    setFileMeta({
      imageSize: get(file, 'size'),
      imageName: get(file, 'name'),
      imageContentType: get(file, 'type')
    });
    return src;
  };

  const handleDelete = () => {
    // console.log('handleDelete');
  };

  return {
    handleDeletePage,
    handleUpdatePage: props => {
      setLoading(true);
      loadUpdatePage(props);
    },
    loading:
      loading || loadingGetBoards || loadingDeletePage || loadingUpdatePage,
    result: resultDeletePage || resultUpdatePage,
    error: errorDeletePage || errorUpdatePage,
    handleDrop,
    handleDelete,
    data,
    field,
    preview,
    isEdited,
    id,
    title,
    name,
    description,
    fileMeta,
    tab,
    isExternal
  };
};
