/* eslint-disable complexity */
// Libraries
import React, { useContext, ReactNode } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

// Components
import {
  Card as BaseCard,
  CardActions,
  CardContent,
  CardMedia
} from '@material-ui/core';
import { H6 } from '../Typography';

// Hooks

// Actions

// Contexts


// Styles
import styles from './AssetCard.scss';

export type CardProps = {
  variant?: typeof VARIANT.HORISONTAL | typeof VARIANT.VERTICAL,
  size?: typeof SIZE.LARGE | typeof SIZE.MEDIUM | typeof SIZE.SMALL,
  onRemove?: Function,
  id: string,
  title?: string,
  preview?: string,
  name?: string,
  additional?: string,
  description?: string,
  mediaWidth?: string | number,
  mediaHeight?: string | number,
  updatedAt?: string,
  short?: boolean,
  children?: ReactNode,
  shortClassName?: { Root?: string, Media?: String, Name?: string },
  className?: { Root?: string, Media?: String, Name?: string }
};

export const AssetCard = ({
  id,
  title,
  preview,
  name,
  additional,
  description,
  mediaWidth,
  mediaHeight,
  variant = VARIANT.VERTICAL,
  size = SIZE.MEDIUM,
  short,
  children,
  shortClassName,
  className,
  expireDate,
  ...other
}) => {
  const { updatedAt, previewFileName, type, items } = other;
  const history = useHistory();

  const isAsset = TYPE.SINGLE_ASSET === type;
  const isCollection = TYPE.COLLECTION === type;
  const isLongread = TYPE.ASSET_LONGREAD === type;
 

  const handleClick = () => {
    if (!type) {
      return;
    }

    window.history.replaceState(
      null,
      '',
      `/portal/${isAsset ? 'asset' : type?.split(':')[2].toLowerCase()}/${id}`
    );
    window.location.reload();
  };

  return (
    <BaseCard
      className={classNames(styles.Card, styles.Short, shortClassName?.Root)}
      onClick={handleClick}
    >
      <CardMedia className={classNames(styles.Media, styles.Short)}>
        <img
          alt={previewFileName}
          title={previewFileName}
          src={preview}
          width={mediaWidth}
          height={mediaHeight}
        />
      </CardMedia>
      <CardContent
        className={classNames(styles.Content, styles.Short, {
          [styles.WithActions]: children
        })}
      >
        {name && (
          <H6
            className={classNames(
              styles.Name,
              styles.Short,
              shortClassName?.Name
            )}
          >
            {name}
          </H6>
        )}
        {updatedAt && !children && (
          <div className={classNames(styles.LastUpdate, styles.Short)}>
            {/* {moment(lastUpdate).format('LL')} */}
            {moment(updatedAt).format('D MMMM YYYY')}
          </div>
        )}
        {children && (
          <CardActions className={styles.CardActions}>{children}</CardActions>
        )}
      </CardContent>
    </BaseCard>
  );
};

export const VARIANT = {
  HORISONTAL: 'horisontal',
  VERTICAL: 'vertical'
};

export const TYPE = {
  SINGLE_ASSET: 'Asset::SingleAsset',
  ASSET_LONGREAD: 'Asset::Longread',
  COLLECTION: 'Asset::Collection',
  TIP: 'Asset::Tip',
  NEWS: 'Asset::News'
};

export const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

