// Libraries
import React from 'react';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';

// Services
import { updateLoader } from '@services/loader';

// Hooks
import { useGetBoards } from '@hooks/data';

// GraphQL
const query = loader('src/graphql/mutations/deletePage.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

export const useDeletePage = ({ isLoader = true }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const {
    load: loadBoards,
    loading: loadingBoards,
    result: resultBoards,
    error: errorBoards,
  } = useGetBoards();

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useMutation(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    loadBoards({ isLoader });
  }, [loading, data]);

  React.useEffect(() => {
    if (loadingBoards || !resultBoards) { return; }
    // onLoad(resultBoards, dispatch);
    setNormalized(resultBoards);
  }, [loadingBoards, resultBoards]);

  return {
    load,
    loading,
    result: normalized,
    error: error || errorBoards,
  };
};

export default useDeletePage;
