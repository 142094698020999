// @flow

// Libraries
import classNames from 'classnames';
import React, { Element } from 'react';
import { Field } from 'redux-form';

// Components
import { TextareaAutosize } from '@material-ui/core';

import Base from './Base';

// Styles
import styles from './TextArea.scss';

type PropTypes = {
  placeholder?: string,
  name: string,
  validate: Function[],
  classes?: any,
}

const renderField = (props: any): Element<TextareaAutosize> => {
  const {
    input,
    placeholder,
    meta: { touched, error, invalid },
    classes
  } = props;
  return (
    <Base {...props}>
      <TextareaAutosize
        {...input}
        {...props}
        placeholder={placeholder}
        className={classNames(
          classes?.textArea,
          styles.TextArea,
          {
            [styles.Error]: touched && invalid
          }
        )}
      />
      {
        touched && error &&
        <span className={classNames(styles.ErrorMessage)}>{error}</span>
      }
    </Base>
  )
}

const FormTextArea = ({ name, placeholder, validate, ...props }: PropTypes): Element<Field> => {
  return (
    <Field
      name={name}
      placeholder={placeholder}
      component={renderField}
      validate={validate}
      {...props}
    />
  )
}

export default FormTextArea;
