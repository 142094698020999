// @flow

// Libraries
import React, { Element, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { compose } from 'recompose';
import { get, forEach } from 'lodash';
import { FieldArray } from 'redux-form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

// Components
import Grid from '@material-ui/core/Grid';

import { VARIANT, MODE } from '@components/Card/data';

import EditCard from './EditCard';
import EditModeForm from './EditModeForm';

// Styles
import styles from '../Main/Main.scss';

// Hooks
import { useUpdateBoard } from '@hooks/data';

// Services
import { updateBoards } from '@services/boards';
import { movePages } from '@services/boards/actions';

const SortableItem = SortableElement(({ field, sortIndex, item }) => (
  <Grid item sm={12} xs={12} md={12} key={field.id}>
    <EditCard
      variant={VARIANT.HORISONTAL}
      mode={MODE.EDIT}
      field={field}
      index={sortIndex}
      data={item}
    />
  </Grid>
));

const SortableList = SortableContainer(({ fields }) => {
  return (
    <ul className={styles.EditContainer}>
      {
        fields.map((field, index) => (
          <SortableItem
            key={field.id}
            field={field}
            item={fields.get(index)}
            sortIndex={index}
            index={index}
          />
        ))
      }
    </ul>
  )
});

const useRenderList = ({ fields, board }: any) => {
  // const items = useSelector(state => get(state, 'search.filterResults'));
  // const board = useSelector(state => get(state, 'boards.wiki'));
  const pages = get(board, 'pages', []);
  // const items = useSelector(state => get(state, 'form.editCards.values.items'));
  // const fields = useSelector(state => get(state, 'form.editCards.fields.items'));
  const dispatch = useDispatch();

  const {
    load: loadUpdateBoard,
    // loading: loadingUpdateBoard,
    // result: resultUpdateBoard,
    // error: errorUpdateBoard,
  } = useUpdateBoard();

  const initFields = (): void => forEach(pages, item => fields.push(item));

  useEffect(() => {
    fields.removeAll();
    initFields();
  }, [pages]);

  const onSortEnd = ({ oldIndex, newIndex }): void => {
    loadUpdateBoard({
      variables: {
        id: get(board, 'id'),
        pages: arrayMove([...pages], oldIndex, newIndex).map(page => get(page, 'id'))
      }
    });
    // dispatch(movePages(oldIndex, newIndex))
  }

  return (
    <SortableList
      useDragHandle
      fields={fields}
      onSortEnd={onSortEnd}
    />
  )
};

const mapStateToProps = (state: Object): Object => ({
  formValues: get(state, 'form.editCards.values'),
  board: get(state, 'boards.list.wiki')
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data))
});

const RenderList = compose(connect(mapStateToProps, mapDispatchToProps))(useRenderList);

type EditModeTypes = {
  formValues: Array<Object>,
  updateBoards: Function
};

const EditMode = ({ formValues, updateBoards }: EditModeTypes): Element<EditModeForm> => {
  // const onSubmit = (event, data) => {
  //   get(event, 'preventDefault') && event.preventDefault();
  //   const pages = get(formValues, 'items');
  //   updateBoards({ pages });
  // }

  return (
    <EditModeForm>
      <FieldArray name='items' component={RenderList} />
    </EditModeForm>
  )
};

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(EditMode);


