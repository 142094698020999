// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';
// import $ from 'jquery';

// Components
import { IconButton } from '@material-ui/core';

import ActionButton from '../ActionButton'

// Styles
import styles from './AddButton.scss';

// Data
import items from './data';

// Context
import LongReadContext from '../../containers/LongRead/context';

// Types
import { WIKI_ENTITIES } from '@services/boards/types';

// Icons
import { ReactComponent as AddIcon } from '@assets/icons/add_plus.svg';

// Context
import AddButtonContext from './context';

const {
  TWO_IMAGES,
  THREE_IMAGES,
  TWO_DO_IMAGES,
  THREE_DO_IMAGES,
} = WIKI_ENTITIES;

type AddButtonsTypes = {
  onCreate: () => void,
};

const AddButton = ({
  onCreate,
}: AddButtonsTypes) => {

  const {
    editorRef,
    readOnly,
    editorState,
    isLimited,
    isEdit
  } = React.useContext(LongReadContext);

  const [isOpen, setIsOpen] = React.useState(false);
  const [style, setStyle] = React.useState({ top: 0 });
  const [visible, setVisible] = React.useState(isEdit);

  const [selectedItem, setSelectedItem] = React.useState();

  React.useEffect(() => {

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    if (
      !isEdit
      || readOnly
      || !selectionState.isCollapsed()
      || selectionState.anchorKey !== selectionState.focusKey
      || contentState.getBlockForKey(selectionState.getAnchorKey())?.getType()?.indexOf('atomic') >= 0
    ) {
      hideBlock();
      return;
    }
    const block = contentState.getBlockForKey(selectionState.focusKey);
    // const bkey = block?.getKey();
    
    if (block && block.getType() === 'unstyled' && block.getText() === '') {
      findNode();
      return;
    }

    // if (block.getLength() > 0) {
    //   hideBlock();
    //   return;
    // }
    hideBlock();
    // setBlockType(block.getType());
    // setBlockKey(bkey);
    
    // if (blockKey === bkey) {
    //   if (block.getLength() > 0) {
    //     hideBlock();
    //   } 
    //   else {
    //     setVisible(true);
    //   }
    //   return;
    // }
  }, [editorState, readOnly, isEdit]);

  const getSelectedBlockNode = (root) => {
    const selection = root.getSelection();
    if (selection.rangeCount === 0) {
      return null;
    }
    let node = selection.getRangeAt(0).startContainer;
    do {
      if (node.getAttribute && node.getAttribute('data-block') === 'true') {
        return node;
      }
      node = node.parentNode;
    } while (node !== null);
    return null;
  };

  const hideBlock = () => {
    setVisible(false);
    setIsOpen(false);
  };

  React.useEffect(() => {
    isOpen !== true && setSelectedItem(false);
  }, [ isOpen ]);

  const openToolbar = () => {
    setIsOpen(!isOpen);
  };

  const findNode = () => {
    const currentNode = getSelectedBlockNode(window);
    // const editorNode = get(editorRef, 'current');
    
    if (currentNode) {
      setStyle({
        top: currentNode.offsetTop
      });
      setVisible(true);
      // hideBlock();
    } else {
      hideBlock();
    }
  };

  const handleClickItem = (item) => {
    const type = get(item, 'type');
    onCreate({
      type,
      count: 
        type === THREE_IMAGES || type === THREE_DO_IMAGES ? 3 :
        type === TWO_IMAGES || type === TWO_DO_IMAGES ? 2 : 1
    });
    setIsOpen(false);
  };

  return (
    <div className={classNames(styles.Root, { [styles.RootIsVisible]: visible })} style={{...style}}>
      {/* <div
        className={classNames(styles.AddButton, { [styles.AddButtonActive]: isOpen})}
        onClick={() => openToolbar()}
      /> */}
      <IconButton
        className={classNames(styles.AddButton, { [styles.AddButtonActive]: isOpen})}
        variant="contained"
        disableRipple
        onClick={openToolbar}
      >
        <AddIcon />
      </IconButton>
      {isOpen && (
        <AddButtonContext.Provider
          value={{
            selectedItem, 
            setSelectedItem,
            handleClickItem
          }}
        >
          <div className={styles.Items}>
            {items.map((item) => (
              <ActionButton key={item?.id} item={item} />
            ))}
          </div>
        </AddButtonContext.Provider>
      )}
    </div>
  )
};

// Exports
export default AddButton;
