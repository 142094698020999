// @flow

// Libraries
import * as React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { get } from 'lodash';

// Utils
// import { getCurrentBlock, addNewBlockAt } from '@utils/editorUtils';
import {
  Block,
  HANDLED,
  NOT_HANDLED,
  // ContinuousBlocks
} from '@utils/constants';
import { linkDecorator, sectionDecorator } from '@utils/editorDecorators';

// Context
import BuilderContext from '../../context';
import LongReadContext from '../LongRead/context';

// Data
import { ORDER_SECTIONS_MODAL_ID } from '@containers/modals/sections/Order';
import { EDIT_SECTION_MODAL_ID } from '@containers/modals/sections/Edit';

type TEXT_CONTAINER_PROPS_TYPES = {
  editorState: {
    getSelection: () => Array<Object>,
    getCurrentContent: () => Object,
  },
  editorRef: React.Ref,
  readOnly: boolean,
  setEditorState: () => void,
  blockRendererFn: () => void,
  pages: Array<Object>,
  updateBoards: () => void,
  openModal: () => void,
  isEdit: Boolean,
  session: Object,
  isLimited: Boolean,
  error?: any
};

export default (props: TEXT_CONTAINER_PROPS_TYPES) => {
  const { page, handleBlocker } = React.useContext(BuilderContext);
  const {
    editorState,
    setEditorState,
    readOnly,
    setReadOnlyState,
    editorRef,
    isLimited,
    blockRendererFn,
    handleCreate,
    handleChange,
    editorFocusHandler,
  } = React.useContext(LongReadContext);

  const {
    // pages,
    // updateBoards,
    openModal,
    isEdit,
    session,
    error,
  } = props;

  const isLogin = get(session, 'id');

  const selectionState = editorState && editorState.getSelection();
  const isSelected = selectionState && selectionState.isCollapsed() === false && selectionState.anchorOffset !== selectionState.focusOffset;
  const focusPosition = selectionState && selectionState.getFocusOffset();
  
  // React.useEffect(() => {
  //   if (isLimited) { return; }
  //   const content = get(page, 'content');
  //   const newEditorState = content ? EditorState.createWithContent(convertFromRaw(JSON.parse(content)), decorators) : EditorState.createEmpty();
  //   setEditorState(newEditorState);
  // },[get(page, 'id')]);

  const convertToBoldHandler = () => {
    setEditorState(EditorState.forceSelection(RichUtils.toggleInlineStyle(editorState, Block.BOLDTEXT), selectionState));
  };

  const convertToListHandler = () => {
    setEditorState(EditorState.forceSelection(RichUtils.toggleBlockType(editorState, Block.LIST), selectionState));
  };

  const convertTextStyleHandler = (typeStyle: string) => {
    // const newEditorState = EditorState.set(editorState, { decorator: editorDecorator });
    setEditorState(EditorState.forceSelection(RichUtils.toggleBlockType(editorState, typeStyle, sectionDecorator), selectionState));
  };

  const convertToLinkHandler = (url: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      Block.LINK,
      'MUTABLE',
      {url}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity, decorator: linkDecorator });
    // const newEditorState = EditorState.push(editorState, contentStateWithEntity, 'remove-range');
    setEditorState(EditorState.forceSelection(RichUtils.toggleLink(newEditorState, selectionState, entityKey), selectionState));
  };

  const handleReturn = (event) => {
    if (isSoftNewlineEvent(event)) {
    // if (!isSelected) {
      setEditorState(RichUtils.insertSoftNewline(EditorState.moveFocusToEnd(editorState)));
      // return NOT_HANDLED;
    }
    // if (!event.altKey && !event.metaKey && !event.ctrlKey) {
    //   const currentBlock = getCurrentBlock(editorState);
    //   const blockType = currentBlock.getType();

    //   const selection = editorState.getSelection();

    //   if (selection.isCollapsed() && currentBlock.getLength() === selection.getStartOffset()) {
    //     if (ContinuousBlocks.indexOf(blockType) < 0) {
    //       setEditorState(addNewBlockAt(editorState, currentBlock.getKey()));
    //       return HANDLED;
    //     }
    //     return NOT_HANDLED;
    //   }
    //   return NOT_HANDLED;
    // }
    // return NOT_HANDLED;
  };

  const handleKeyCommand = (command) => {
    const newContent = RichUtils.handleKeyCommand(editorState, command);
    if (newContent) {
      setEditorState(newContent);
      return HANDLED;
    } else {
      return NOT_HANDLED;
    }
  };

  const handleSectionsOrder = () => {
    openModal(ORDER_SECTIONS_MODAL_ID);
  };

  const handleSectionEdit = () => {
    openModal(EDIT_SECTION_MODAL_ID);
  };

  return {
    convertToBoldHandler,
    convertToListHandler,
    convertToLinkHandler,
    convertTextStyleHandler,
    editorState,
    editorRef,
    isSelected,
    focusPosition,
    setEditorState,
    blockRendererFn,
    readOnly,
    setReadOnlyState,
    handleReturn,
    handleKeyCommand,
    page,
    handleCreate,
    handleChange,
    editorFocusHandler,
    handleSectionsOrder,
    handleSectionEdit,
    handleBlocker,
    session,
    isEdit,
    isLogin,
    isLimited,
    isLocked: get(page, 'isLocked'),
    error
  };
};
