// Libraries
import React from 'react';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';

// Services
import { updateLoader } from '@services/loader';

// Hooks
import { useUpdateBoard } from '@hooks/data';

// GraphQL
const query = loader('src/graphql/mutations/createPage.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

export const useCreatePage = ({ isLoader = true, board }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const [normalized, setNormalized] = React.useState();

  const {
    load: loadUpdateBoard,
    loading: loadingUpdateBoard,
    result: resultUpdateBoard,
    error: errorUpdateBoard,
  } = useUpdateBoard();

  const [load, {
    loading,
    data,
    error
  }] = useMutation(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    const id = get(data, 'createWikiPage.wikiPage.id');
    id && loadUpdateBoard({
      isLoader,
      variables: {
        id: get(board, 'id'),
        pages: [
          id,
          ...get(board, 'pages', []).map(page => get(page, 'id'))
        ]
      }
    });
  }, [loading, data]);

  React.useEffect(() => {
    if (loadingUpdateBoard || !resultUpdateBoard) { return; }
    setNormalized(get(data, 'wikiPage'));
  }, [loadingUpdateBoard, resultUpdateBoard]);

  // const handleCreate = (data) => {
  //   const variables = get(data, 'variables');
  //   const content = get(variables, 'content');
  //   load({
  //     ...data,
  //     ...{
  //       variables: {
  //         ...variables,
  //         ...(content ? { content: JSON.stringify(content) } : {})
  //       }
  //     }
  //   });
  // };

  return {
    load,
    loading,
    result: normalized,
    error: error || errorUpdateBoard,
  };
};

export default useCreatePage;
