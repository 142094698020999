// @flow

// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import {
  SortableContainer as BaseSortableContainer,
  SortableElement as BaseSortableElement,
  SortableHandle
} from 'react-sortable-hoc';

// Components
import IconButton from '@material-ui/core/IconButton';

import { H6 } from '@components/Typography';
import Modal from '@components/Modal';
import Button from '@components/Button';

// Icons
import { ReactComponent as IconDnD } from '@assets/icons/drug_and_drop.svg';
import { ReactComponent as IconDelete } from '@assets/icons/delete_gray.svg';

// Styles
import styles from './Order.scss';

// Services
import { updateBoards, upsertSections } from '@services/boards';
import { closeModal, openModal } from '@services/modals';

// Hooks
import useOrder from './hooks';

// Data
import { DELETE_SECTION_MODAL_ID } from '@containers/modals/sections/Delete';

export const ORDER_SECTIONS_MODAL_ID = 'ORDER_SECTIONS_MODAL_ID';

const Order = (props) => {
  const {
    // page,
    sections,
    // editorState,
    // setEditorState,
    handleSort,
    handleChange,
    openModal,
    handleDelete,
    closeModal,
    // isEdit,
    // isLogin,
    isOpened,
  } = useOrder(props);

  const DragHandle = SortableHandle((): Element<'span'> => (
    <IconButton
      className={styles.DnDButton}
      color="secondary"
      aria-label="Drag"
      component="span"
    >
      <IconDnD />
    </IconButton>
  ));
  
  const SortableElement = BaseSortableElement(({ item }): Element<'li'> => (
    <li id={get(item, 'id')} className={styles.SortableElement} data-hidden={get(item, 'isUntitled')}>
      <DragHandle />
      <H6 className={styles.Title}>
        {
          get(item, 'title')
        }
      </H6>
      <IconButton
        className={styles.DeleteButton}
        color="secondary"
        aria-label="Delete"
        component="span"
        onClick={() => handleDelete(get(item, 'blockKey'))}
      >
        <IconDelete />
      </IconButton>
    </li>
  ));
  
  const SortableContainer = BaseSortableContainer(({ items = [] }): Element<'ul'> => {
    return (
      <ul className={styles.SortableContainer}>
        {
          items.map((item, index) => (
            <SortableElement
              key={item.id}
              item={item}
              sortIndex={index}
              index={index}
            />
          ))
        }
      </ul>
    )
  });

  return (
    <Modal
      id={ORDER_SECTIONS_MODAL_ID}
      classes={{
        content: styles.ModalContent
      }}
      title="Change section order"
      onClose={closeModal}
      open={!!isOpened}
      maxWidth="md"
      fullWidth
    >
      <SortableContainer
        useDragHandle
        onSortEnd={handleSort}
        items={sections}
        onDelete={handleDelete}
      />
      <Button
        className={styles.ModalButton}
        variant="contained"
        onClick={handleChange}
      >
        Save changes
      </Button>
    </Modal>
  )
};

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  isEdit: get(state, 'session.isEdit', false),
  isLogin: get(state, 'session.token', false),
  isOpened: get(state, `modals[${ORDER_SECTIONS_MODAL_ID}]`),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  upsertSections: (data) => dispatch(upsertSections(data)),
  openModal: (id, data) => dispatch(openModal(id, data)),
  closeModal: () => dispatch(closeModal(ORDER_SECTIONS_MODAL_ID)),
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Order);
