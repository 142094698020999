import { createSelector } from 'reselect';

export const selectCurrentLightbox = createSelector(
  state => state.lightbox,
  lightbox => lightbox.currentLightbox
);

export const selectCurrentLightboxSharingLink = createSelector(
  state => state.lightbox,
  lightbox => lightbox.currentLightboxSharingLink
);