// @flow
import { PURGE } from 'redux-persist';

import { get, omit } from 'lodash';

import type { Action } from './actions';

import { CLOSE_MODAL, CLOSE_MODALS, OPEN_MODAL } from './types';

export type State = Object;

const INITIAL_STATE: State = {};

export default (state: State = INITIAL_STATE, action: Action): State => {
  const id = get(action, 'id');
  switch (action.type) {
    case CLOSE_MODAL:
      return omit(state, id);
    case CLOSE_MODALS:
      return INITIAL_STATE;
    case OPEN_MODAL:
      return {
        ...state,
        [id]: action.payload || true,
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
