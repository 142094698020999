// @flow

// Libraries
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';

// Containers
import TextContainer from '@views/Builder/containers/Text';

// Components
import Pagination from '../../components/Pagination';

// Styles
import styles from './LongRead.scss';

// Hooks
import useLongRead from './hooks';

// Context
import LongReadContext from './context';

// Services
import { updateBuffers, updateBoards } from '@services/boards';

const LongRead = (props) => {

  const {
    page,
    pages,
    CONTEXT,
    blockRenderer,
    handleCreate,
    handleChange,
    isLimited,
    error,
  } = useLongRead(props);

  return (
    <LongReadContext.Provider
      value={{
        ...CONTEXT,
        blockRendererFn: blockRenderer,
        handleCreate,
        handleChange,
        isLimited
      }}
    >
      <div
        className={classNames(
          styles.Root,
          {
            [styles.RootIsLimited]: isLimited
          }
        )}
      >
        <TextContainer
          error={error}
        />
        {!isLimited && <Pagination page={page} pages={pages} />}
      </div>
    </LongReadContext.Provider>
  )
};

// Exports
const mapStateToProps = (state: Object): Object => ({
  boards: get(state, 'boards', {}),
  pages: get(state, 'boards.list.wiki.pages', []),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  updateBoards: (data) => dispatch(updateBoards(data)),
  updateBuffers: (data) => dispatch(updateBuffers(data))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(LongRead);
