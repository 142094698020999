// Libraries
import React from 'react';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';
import { isFunction } from 'lodash';

// Services
import { updateLoader } from '@services/loader';

// Hooks
import { useGetBoards } from '@hooks/data';

// GraphQL
const query = loader('src/graphql/mutations/updatePage.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  onLoad?: Function
};

export const useUpdatePage = ({ isLoader = true, isLoadBoards = true, onLoad }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const [normalized, setNormalized] = React.useState();

  const {
    load: loadBoards,
    loading: loadingBoards,
    result: resultBoards,
    error: errorBoards,
  } = useGetBoards();

  const [load, {
    loading,
    data,
    error
  }] = useMutation(query, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading: isLoader }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    isLoadBoards ? loadBoards({ isLoader }) : setNormalized(get(data, 'updateWikiPage'));
  }, [loading, data, isLoadBoards]);


  React.useEffect(() => {
    if (loadingBoards || !resultBoards) { return; }
    // onLoad(resultBoards, dispatch);
    setNormalized(get(data, 'updateWikiPage'));
    isFunction(onLoad) && onLoad();
  }, [loadingBoards, resultBoards]);

  // const handleUpdate = (data) => {
  //   const variables = get(data, 'variables');
  //   const content = get(variables, 'content');
  //   load({
  //     ...data,
  //     ...{
  //       variables: {
  //         ...variables,
  //         ...(content ? { content: JSON.stringify(content) } : {})
  //       }
  //     }
  //   });
  // };

  return {
    load,
    loading: loading || loadingBoards,
    result: normalized,
    error: error || errorBoards,
  };
};

export default useUpdatePage;
