// Libraries
import React from 'react';
import { get } from 'lodash';
import { useMutation } from 'react-apollo';
import { loader } from 'graphql.macro';
import { useDispatch } from 'react-redux';

// Services
import { updateLoader } from '@services/loader';

// Hooks
import { useGetBoards } from '@hooks/data';

// GraphQL
const lockQuery = loader('src/graphql/mutations/lockPage.gql');
const unlockQuery = loader('src/graphql/mutations/unlockPage.gql');

type PROPS_TYPE = {
  isLoader?: Boolean,
  isLocked?: Boolean,
  onLoad?: Function
};

export const useBlockerPage = ({ isLoader = true, isLocked = true }: PROPS_TYPE = {}) => {

  const dispatch = useDispatch();

  const {
    load: loadGetBoards,
    loading: loadingGetBoards,
    result: resultGetBoards,
    error: errorGetBoards,
  } = useGetBoards({
    isLoader
  });

  const [normalized, setNormalized] = React.useState();

  const [load, {
    loading,
    data,
    error
  }] = useMutation(isLocked ? lockQuery : unlockQuery, {
    fetchPolicy: 'no-cache',
    onError: error => {}
  });

  React.useEffect(() => {
    isLoader && dispatch(updateLoader({ loading }));
  }, [loading]);

  React.useEffect(() => {
    if (loading || !data) { return; }
    loadGetBoards();
  }, [loading, data]);

  React.useEffect(() => {
    if (loadingGetBoards || !resultGetBoards) { return; }
    // onLoad(resultGetBoards, dispatch);
    setNormalized(get(data, 'unlockWikiPage.wikiPage'));
  }, [loadingGetBoards, resultGetBoards]);

  return {
    load,
    loading,
    result: normalized,
    error: error || errorGetBoards,
  };
};

export default useBlockerPage;
