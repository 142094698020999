// @flow

// Libraries
import * as React from 'react';
import { get, throttle, isFunction } from 'lodash';

// Hooks
import {
  useGetAssets
} from '@hooks/data';

type PropTypes = {
  loading?: Boolean,
  data?: Any,
  onUpdate?: Function,
  onAdd?: Function,
  onRemove?: Function,
  onFocus?: Function,
  onBlur?: Function,
};

const useActions = ({
  loading,
  data,
  onUpdate,
  onAdd,
  onRemove,
  onFocus,
  onBlur,
  ...props
}: PropTypes): Object => {

  const [isModal, setIsModal] = React.useState(false);
  const [link, setLink] = React.useState();

  const [assets, setAssets] = React.useState([]);
  const [selected, setSelected] = React.useState(data);
  

  const {
    load: loadGetAssets,
    loading: loadingGetAssets,
    result: resultGetAssets,
    error: errorGetAssets,
  } = useGetAssets({ isLoader: false });

  React.useEffect(() => {
    if (loadingGetAssets || !resultGetAssets){ return; }
    const nodes = get(resultGetAssets, 'nodes', []).map(node => ({
      ...node,
      title: get(node, 'name'),
      value: get(node, 'accessUrl'),
    }));
    setAssets(nodes);
  }, [loadingGetAssets, resultGetAssets]);

  const handleInput = (event: object, value: string, reason: string) => {
    const throttled = throttle(
      () => loadGetAssets({
        variables: {
          nameContains: value
        }
      }),
      1000
    );
    value.length > 2 && throttled();
  };

  const handleAdd = () => {
    setIsModal(true);
    isFunction(onAdd) && onAdd(selected);
  };

  const handleRemove = () => {
    isFunction(onRemove) && onRemove(selected);
  };

  const handleFocus = () => {
    isFunction(onFocus) && onFocus();
  };

  const handleBlur = () => {
    isFunction(onBlur) && onBlur();
  };

  const handleChange = (event, data) => {
    setSelected(data);
  };

  const handleUpdate = () => {
    isFunction(onUpdate) && onUpdate(selected);
    setIsModal(false);
  };

  return {
    // props
    loading,
    data,
    // hook
    loadingGetAssets,
    selected, setSelected,
    isModal, setIsModal,
    link, setLink,
    // inner
    assets, setAssets,
    handleInput,
    handleUpdate,
    handleAdd,
    handleRemove,
    handleChange,
    handleFocus,
    handleBlur,
  };
};

export default useActions;
