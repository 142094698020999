// @flow

// Libraries
import React, { Element } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

// Components
import Page from '@containers/Page';

import LoginForm from './LoginForm';

// Services
import { signIn } from '@services/session';

// Styles
import styles from '../Profile.scss';

type LoginTypes = {
  signIn: Function
};

const Login = (props: LoginTypes): Element<Page> => {
  const { signIn } = props;
  const { state } = useLocation();
  const history = useHistory();

  const onSubmit = (formData) => {
    signIn({
      email: get(formData, 'email'),
      token: 'token'
    });
    history.push('/');
  }

  return (
    <Page
      classes={{
          content: styles.Content,
          root: styles.Root
        }}
      isHideNav
    >
      <h1 className={classNames(styles.Title)}>Log into BrandPortal</h1>
      {
        state?.message && <div className={styles.Message}>{state.message}</div>
      }
      <LoginForm onSubmit={onSubmit} />
    </Page>
  )
}

const mapStateToProps = (state: Object): Object => ({
  isLogin: get(state, 'session.token', false),
  pages: get(state, 'boards.list.wiki.pages', []),
  isEdit: get(state, 'session.isEdit', false),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
  signIn: (data) => dispatch(signIn(data))
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Login);
