// @flow

// Libraries
import * as React from 'react';
import { get } from 'lodash';

// Hooks
import {
  useCreateAsset
} from '@hooks/data';

// Context
import BuilderContext from '../../context';
import LongReadContext from '../../containers/LongRead/context';

export default ({
  onUpdate,
  onSlide,
  data,
  ...props
}) => {

  const CONTEXT = React.useContext(LongReadContext);
  const { page } = React.useContext(BuilderContext);

  const reader = window.URL || window.webKitURL;
  
  // const [loadingTimeout, setLoadingTimeout] = React.useState();
  const [loading, setLoading] = React.useState(false);
  // const [objectURL, setObjectURL] = React.useState();

  const handleSlide = onSlide;

  const {
    load: loadCreateAsset,
    loading: loadingCreateAsset,
    result: resultCreateAsset,
    error: errorCreateAsset,
  } = useCreateAsset({ isLoader: false });

  React.useEffect(() => {
    const accessUrl = get(resultCreateAsset, 'accessUrl');
    if (loadingCreateAsset || !resultCreateAsset){ return; }
    onUpdate({ src: accessUrl, assetData: resultCreateAsset });
    setLoading(false);
  }, [loadingCreateAsset, resultCreateAsset]);

  // React.useEffect(() => () => {
  //   setLoading(loadingCreateAsset);
  // }, [loadingCreateAsset]);
  
  // React.useEffect(() => () => {
  //   clearTimeout(loadingTimeout);
  //   reader.revokeObjectURL(objectURL);
  // }, []);

  const handleDo = action => {
    onUpdate({ action });
  };

  const handleLink = src => {
    onUpdate({ src });
  };
  
  const handleCaption = caption => {
    onUpdate({ caption });
  };

  const handleDrop = (files) => {
    handleFiles(files);
  };

  const handleDelete = () => {
    onUpdate({ src: '' });
  };

  const handleFiles = files => {
    const file = files[0];

    if (file) {
      // const url = reader.createObjectURL(file);
      // setLoading(true);
      onUpdate({ src: '' });
      // setLoadingTimeout(setTimeout(() => {
      //   onUpdate({ src: url, file });
      //   setObjectURL(url);
      //   setLoading(false);
      // }, 1000));

      setLoading(true);

      loadCreateAsset({
        file,
        variables: {
          name: get(data, 'caption', get(file, 'name')),
          wikiPageId: get(page, 'id'),
        } 
      });
      
      // const reader = new FileReader();
      // reader.readAsDataURL(src);
      // reader.onload = (event) => {
      //   onUpdate({ src: get(event, 'target.result') });
      //   setLoading(false);
      // };
    }
  };

  return {
    CONTEXT,
    loading,
    handleSlide,
    handleDo,
    handleLink,
    handleCaption,
    handleDrop,
    handleDelete,
  };
};
