// @flow
import * as React from 'react';
import classNames from 'classnames';

// Components
import { InputBase } from '@material-ui/core';
import BaseTextField from '@material-ui/core/TextField';

// Styles
import styles from './TextField.scss';

const VARIANT = {
  STANDARD: 'standard',
  FILLED: 'filled',
  OUTLINED: 'outlined',
};

export type PropTypes = {
  children?: React.Node,
  classes?: {
    root?: string
  },
  className?: string,
  color?: ColorType,
  disabled?: boolean,
  fullWidth?: boolean,
  loaded?: boolean,
  onClick?: (SyntheticEvent<HTMLButtonElement>) => ?void,
  to?: string,
  type?: string,
  outlined?: boolean,
  variant?: typeof VARIANT.FILLED | typeof VARIANT.OUTLINED | typeof VARIANT.STANDARD
};

const TextField = ({
  children,
  classes = {},
  className,
  variant = VARIANT.STANDARD,
  outlined,
  ...props
}: PropTypes): React.Element<BaseTextField | InputBase> => {

  if (outlined) {
    return (
      <InputBase
        classes={classes}
        className={classNames(
          className,
          styles.Root
        )}
        {...props}
      />
    );
  };

  return (
    <BaseTextField
      classes={classes}
      className={classNames(
        styles.Root,
        className,
        {
          [styles.RootVariantStandart]: variant === VARIANT.CONTAINED,
          [styles.RootVariantFilled]: variant === VARIANT.TEXT,
          [styles.RootVariantOutlined]: variant === VARIANT.OUTLINED,
        }
      )}
      {...props}
    />
  );
};

export default TextField;
