// @flow

// Libraries
import * as React from 'react';
import { EditorState, AtomicBlockUtils, convertFromRaw, convertToRaw, Modifier, SelectionState } from 'draft-js';
import { v4 as uuidv4 } from 'uuid';
import { get, fill, find, isFunction, isEqual } from 'lodash';

// Components
import Block from "../Block";

// Hooks
import { useBlockerPage } from '@hooks/data';

// Context
import BuilderContext from '../../context';

// Utils
import decorators from '@utils/editorDecorators';

// Data
import { TYPES } from '@components/Dropzone/data';

// Types
import { WIKI_ENTITIES } from '@services/boards/types';
import { getEntity } from '@services/boards/data/entities';

const {
  SLIDER,
  CAROUSEL,
} = WIKI_ENTITIES;

type LONGREAD_PROPS_TYPES = {
  isLimited: Boolean,
  section?: Object,
  error?: any,
  updateBoards: () => void,
  updateBuffers: () => void,
  change?: Function,
  pages: Array<Object>,
  data?: Object,
  boards?: Object,
};

export default ({ isLimited, section, error, updateBoards, updateBuffers, change, pages, data, boards }: LONGREAD_PROPS_TYPES) => {
  const FULL_CONTEXT = React.useContext(BuilderContext);
  const LIMITED_CONTEXT = get(FULL_CONTEXT, 'limitedContext');
  const CONTEXT = isLimited ? LIMITED_CONTEXT : FULL_CONTEXT;
  // const editorState = CONTEXT.editorState;
  const { page } = FULL_CONTEXT;

  const [updateTimeout, setUpdateTimeout] = React.useState();

  const {
    load: loadBlockerPage,
    loading: loadingBlockerPage,
    result: resultBlockerPage,
    error: errorBlockerPage,
  } = useBlockerPage({
    // isLoader: false
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(updateTimeout);
    };
  }, []);

  React.useEffect(() => {
    if (!isLimited) { return; }
    const { setEditorState } = CONTEXT;
    // const section = blockKey ? find(get(page, 'sections'), { blockKey }) : data;
    const content = get(section || data, 'content');
    const newEditorState = content ? EditorState.createWithContent(convertFromRaw(content), decorators) : EditorState.createEmpty();
    // clearFields('editSection');
    // reset('editSection');
    if (isFunction(change)) {
      change('title', get(section, 'title', null));
      change('content', get(section, 'content', null));
    }
    setEditorState(newEditorState);
  },[isLimited, section]);

  React.useEffect(() => {
    if (loadingBlockerPage || !resultBlockerPage) { return; }
    handleChange(get(CONTEXT, 'editorState'));
  }, [loadingBlockerPage, resultBlockerPage]);

  // React.useEffect(() => {
  //   handleChange(get(CONTEXT, 'editorState'));
  // }, [get(CONTEXT, 'editorState')]);

  const createCustomBlock = (context, blockType, blockData) => {
    const { editorState, setEditorState } = context;
    
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const key = selectionState.getStartKey();
    
    const contentStateWithEntity = contentState.createEntity(
      blockType,
      'IMMUTABLE',
      blockData,
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    
    const newEditorState = EditorState.set(editorState, {
      // currentContent: contentStateWithEntity,
      currentContent: contentStateWithEntity,
    });
    const withAtomic = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, blockType);
    const withAtomicContentState = withAtomic.getCurrentContent();
    const atomicBlock = withAtomicContentState.getBlockMap().remove(key);
    // console.log('withAtomicContentState.getBlockMap()', withAtomicContentState.getBlockMap());
    // const withMoveAtomic = AtomicBlockUtils.moveAtomicBlock(
    //   newEditorState,
    //   atomicBlock,
    //   selectionState,
    //   'replace'
    // );
    // const newContentState = withMoveAtomic.getCurrentContent();
    // const blockMap = newContentState.getBlockMap().set('blockMap', blockMap);
    
    // setEditorState(EditorState.push(editorState, newContentState.set('blockMap', blockMap), 'remove-range'));
    handleChange(EditorState.push(newEditorState, withAtomicContentState.set('blockMap', atomicBlock), 'remove-range'));
    // handleChange(withAtomic);
  };

  const blockRenderer = block => {
    if (block.getType() === 'atomic') {
      return {
        component: Block,
        editable: false,
        props: {},
      };
    }
    return null;
  };

  const handleCreate = ({ count = 1, type }) => {
    if (!get(page, 'isLocked')){ return; }
    const data = {
      id: uuidv4(),
      entities: fill(Array(count), {}).map(item => (
        [
          SLIDER,
          CAROUSEL,
        ].indexOf(type) >= 0 ? {
          id: uuidv4(),
          type,
          entities: fill(Array(2), {}).map(item => getEntity({ type: TYPES.IMAGE }))
        } : getEntity({ type })
      ))
    };
    createCustomBlock(
      CONTEXT,
      type,
      data
    );
  };

  const handleChange = (state) => {
    clearTimeout(updateTimeout);

    if (!get(page, 'isLocked')){ return; }
    const { setEditorState } = CONTEXT;
    setEditorState(state);

    const pageId = get(page, 'id');
    const data = state.getCurrentContent();
    const content = convertToRaw(data);

    if (isLimited) { return change('content', content); }

    const handleTimeout = () => {
      
      // if (isEqual(content, get(page, 'content'))) { return; }
      updateBuffers({
        [pageId]: {
          ...page,
          ...get(boards, `buffers[${pageId}]`),
          content,
          updatedAt: Date.now(),
          isLocked: true
        }
      });
    };

    // if (!get(page, 'isLocked')) {
    //   return !loadingBlockerPage && loadBlockerPage({ variables: { id: pageId } });
    // }
    
    // const pageIndex = pages.indexOf(page);
    
    // setUpdateTimeout(setTimeout(
    //   handleTimeout,
    //   500
    // ));
    
    handleTimeout();

  };

  return {
    CONTEXT,
    isLimited,
    blockRenderer,
    handleCreate,
    handleChange,
    error,
    page,
    pages
  };
};
