import {v4 as uuidv4} from 'uuid';

export const getEntity = props => ({
  id: uuidv4(),
  src: '',
  preview: '',
  caption: '',
  action: 'Do',
  title: '',
  ...props
});
