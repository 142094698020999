// @flow

// Libraries
import * as React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Autocomplete from '@components/Autocomplete';
import Button from '@components/Button';

// Styles
import styles from './AssetButton.scss';

// Hooks
import useAssetButton from './hooks';

// Icons
import { ReactComponent as LinkIcon } from '@assets/icons/white_link.svg';
import { ReactComponent as DeleteIcon } from '@assets/icons/SeparateLink/delete.svg';

type AssetButtonTypes = any;

const AssetButton = React.forwardRef((props: AssetButtonTypes, ref) => {

  const {
    CONTEXT,
    handleUpdate,
    handleDelete,
    handleClose,
    option, setOption,
    src,
    isExternal, setIsExternal,
    loading,
    handleInput,
    assets,
    entity,
    data
  } = useAssetButton(props);

  const isEdit = get(CONTEXT, 'isEdit');
  // const isLogin = get(CONTEXT, 'isLogin');
  const isLogin = useSelector(state => !!get(state, 'session.token', false));

  if (!isLogin && !isExternal) {
    return null;
  }

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log(src);
    window.location = src;
  }

  return (
    <div
      className={styles.Root}
      ref={ref}
    >
      <Button
        className={styles.Button}
        variant="contained"
        startIcon={<LinkIcon />}
        onClick={handleClick}
        disabled={isEdit}
      >
        Download assets
      </Button>
      {isEdit && (
        <>
          <Autocomplete
            classes={{
              root: styles.Autocomplete
            }}
            options={assets.filter(({value}) => value)}
            getOptionLabel={(item) => item.title}
            freeSolo={true}
            disableClearable
            loading={loading}
            onInputChange={handleInput}
            onChange={handleUpdate}
            onFocus={() => CONTEXT.setReadOnlyState(true)}
            onBlur={() => CONTEXT.setReadOnlyState(false)}
            onMouseLeave={() => CONTEXT.setReadOnlyState(false)}
            name="asset"
            id="asset"
            type="text"
            variant="standard"
            defaultValue={{
              ...data,
              title: get(data, 'title'),
              value: get(data, 'src')
            }}
            placeholder="Search by asset name or paste a link to asset"
          />
          <ButtonGroup
            className={styles.ActionsPanel}
            disableElevation
            disableRipple
          >
            <Button
              onClick={handleDelete}
              variant="text"
              className={styles.ButtonDelete}
            >
              <DeleteIcon />
            </Button>
          </ButtonGroup>
          <FormControlLabel
            className={styles.ControlLabel}
            name="external"
            onClick={(event, value) => {
              setIsExternal(!isExternal);
              props.onUpdate({...data, isExternal: !isExternal});
              event.preventDefault();
            }}
            control={(
              <Checkbox
                className={styles.Checkbox}
                disableRipple
                color="primary"
                checked={!!isExternal}
              />
            )}
            label="Show for external users"
          />
        </>
      )}
    </div>
  )
});

// Exports
export default AssetButton;
