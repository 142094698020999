// @flow

// Libraries
import React, { Element, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { get } from 'lodash';

// Components
import Container from '@material-ui/core/Container';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Logo from '../Logo';
import ContactUs from '@views/Profile/ContactUs/ContactUs';

// Styles
import styles from './Footer.scss';

// Types
type PageTypes = {
  className: String,
  isLogin: boolean;
};

type ContentTypes = {
  isLogin: boolean;
}

const Content = ({ isLogin, role, ...props }: ContentTypes): Element<Container> => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);

  const openModal = (): void => {
    setIsShowModal(true);
  }

  const closeModal = (): void => {
    setIsShowModal(false);
  }

  return (
    <Container
      maxWidth="lg"
      classes={{
        root: classNames(styles.Container)
      }}
      data-is-login={isLogin}
    >
      <Logo />
      {
        // isLogin
        //   ?
            (
              <div className={styles.Items}>
                <BottomNavigationAction
                  classes={{
                    root: classNames(styles.Item)
                  }}
                  href="mailto:brand@kaspersky.com"
                  label="Contact us"
                  disableRipple
                  {...props}
                />
                {role && (
                  <BottomNavigationAction
                    classes={{
                      root: classNames(styles.Item)
                    }}
                    onClick={(): void => {}}
                    label="Brand team"
                    href="https://intranet.kaspersky.com/Pages/ru/Departments/Brand%20Team/Brand-Team.aspx"
                    disableRipple
                    {...props}
                  />
                )}
              </div>
            )
          // :
          //   (
          //     <div className={styles.Items}>
          //       <BottomNavigationAction
          //         classes={{
          //           root: classNames(styles.Item)
          //         }}
          //         // onClick={(): void => openModal()}
          //         label="Contact us"
          //         href="mailto:brand@kaspersky.com"
          //         disableRipple
          //         {...props}
          //       />
          //     </div>
          //   )
      }
      <ContactUs isShowModal={isShowModal} onClose={closeModal} />
    </Container>
  );
};

const Footer = ({ className, isLogin, role }: PageTypes): Element<BottomNavigation>  => {
  return (
    <BottomNavigation
      // value={value}
      // onChange={(event, newValue) => {
      //   setValue(newValue);
      // }}
      showLabels
      classes={{
        root: classNames(className, styles.Root)
      }}
      variant="dense"
    >
      <Content isLogin={isLogin} role={role}/>
    </BottomNavigation>
  )
};

const mapStateToProps = (state: Object): Object => ({
  isLogin: get(state, 'session.token', false),
  role: get(state, 'session.role', ''),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Footer);
