// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

// Components
import IconButton from '@material-ui/core/IconButton';
import { Card as BaseCard } from '@components/Card';
import Button from '@components/Button';

// Styles
import styles from './Card.scss';

// Hooks
import useCard from './hooks';
import { useGetCollectionAssets } from './../../../../hooks/useGetCollectionAssets';
import { useWindowDimensions } from '../../../../hooks/data/useWindowDimensions';

// Breakpoints
import { SM, MD } from '@styles/breakpoints.scss';

// Types
import { WIKI_ENTITIES } from '@services/boards/types';

// Icons
import AddIcon from '@material-ui/icons/Add';

const {
  HORISONTAL_CARDS,
  VERTICAL_CARDS,
} = WIKI_ENTITIES;

type CardTypes = {
  selected: Boolean,
  block: {
    getEntityAt: (number) => void,
  },
  blockType: String,
  contentState: {
    getEntity: (string) => void,
    replaceEntityData: (string) => void,
  },
  onUpdate?: Function,
  onAdd?: Function,
  onRemove?: Function,
  onDelete?: Function,
  data?: Object,
  index?: Number
};

const VARIANTS_MAP = {
  [HORISONTAL_CARDS]: 'horisontal',
  [VERTICAL_CARDS]: 'vertical',
};

const Card = React.forwardRef(({
  data,
  index,
  selected,
  contentState,
  block,
  blockType,
  onUpdate,
  onAdd,
  onRemove,
  onDelete,
  ...props
}: CardTypes, ref) => {
  // const {
  //   CONTEXT,
  //   entity,
  //   handleUpdate,
  //   handleDelete,
  //   src, setSrc,
  //   caption, setCaption,
  //   external, setExternal,
  // } = useCard({
  //   contentState,
  //   block,
  // });

  const {
    CONTEXT,
    entity,
    handleUpdate,
    handleDelete,
    handleFocus,
    handleBlur,
    handleAdd,
    handleRemove,
  } = useCard({
    contentState,
    block,
    onUpdate,
    onAdd,
    onRemove,
    onDelete,
  });

  const entities = get(entity, 'entities', []);
  const entitiesLength = get(entities, 'length');
  const isLast = index === entity.entities.length - 1;
  const { assets } = useGetCollectionAssets(data?.id);
  const { width } = useWindowDimensions();

  const isEdit = get(CONTEXT, 'isEdit');

  const variant = VARIANTS_MAP[blockType];

  const isCollection = data?.type?.split(':').pop() === 'Collection';

  return (
    <div
      className={classNames(
        styles.Root,
        {
          [styles.RootIsSelected]: selected,
          [styles.RootVariantVertical]: +SM.replace(/\D/g,'') < width ? blockType === VERTICAL_CARDS : true,
        }
      )}
      ref={ref}
    >
      <BaseCard
        data-variant={+SM.replace(/\D/g,'') < width ? variant : 'vertical'}
        classes={{
          root: styles.CardRoot,
          title: styles.CardTitle
        }}
        variant={+SM.replace(/\D/g,'') < width ? variant : 'vertical'}
        type={isCollection ? 'collection' : 'asset'}
        lastUpdate={data?.updatedAt}
        collectionCount={assets?.length}
        mode={isEdit ? 'edit' : 'read'}
        data={data}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onUpdate={handleUpdate}
        onRemove={handleRemove}
      />

      {
        isEdit
        && blockType === VERTICAL_CARDS
        && isLast
        && entitiesLength < 3
        && (
          <Button
            className={styles.AddButton}
            variant="text"
            onClick={handleAdd}
            startIcon={(
              <IconButton
                className={styles.PlusButton}
                color="secondary"
                aria-label="Add more"
                component="span"
              >
                <AddIcon />
              </IconButton>
            )}
          >
            Add more
          </Button>
        )
      }
    </div>
  )
});

// Exports
export default Card;
