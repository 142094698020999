// @flow

// Libraries
import React, { useState, useEffect, useRef, Element } from 'react';
import { reduxForm, formValues } from 'redux-form';
import classNames from 'classnames';
import { requiredField } from '../../utils/validators';

// Components
import Form, { Input } from '../../components/Form';
import { Button } from '@material-ui/core';
import Actions from '../../components/Form/components/Actions';

// Icons
import {ReactComponent as SearchIcon} from '@assets/icons/green_search.svg';

// Hooks
import { useWindowDimensions } from './../../hooks/data/useWindowDimensions';

// Styles
import styles from './SearchResult.scss';
import { MD } from '../../styles/breakpoints.scss';

type PropTypes = {
  handleSubmit: Function;
  classnames?: Object;
  hide?: boolean;
  valid: boolean;
  isLogin: boolean;
  search: string;
}

const SearchForm = ({ handleSubmit, classnames, hide, valid, isLogin, search }: PropTypes): Element<'div'> => {
  const [isShowSearch, setIsSearch] = useState<boolean>(false);
  const {width, height} = useWindowDimensions();
  const element = useRef(null);

  const handleClickOutside = (e): void => {
    if (!element.current.contains(e.target) && hide) {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    }
  }, []);

  return (
    <div
      className={classNames(styles.Root, classnames?.root )}
      data-show={hide ? search ? true : isShowSearch : true}
      onClick={() => setIsSearch(true)}
      ref={element}
    >
      <Form
        classes={{container: styles.Container}}
        data-show={isShowSearch}
        onSubmit={handleSubmit}
      >
        <div className={styles.SearchContainer}>
          <Input
            name="search"
            placeholder={hide ? ' ' : "Search GuideLines..."}
            type="text"
            classes={{input: styles.Input}}
            validate={[requiredField]}
          />
        </div>
        {
          !hide && (
            <Actions classes={{root: classNames(styles.Actions)}}>
              {width < +MD.replace(/\D/g,'')
              ?
              <button
                type="submit"
                disabled={!valid}
                className={styles.SearchButtonShort}
              >
                <SearchIcon/>
              </button>
              :
              <Button
                variant="outlined"
                color="primary"
                disabled={!valid}
                type="submit"
                className={styles.SearchButton}
                disableRipple
              >
                Search
              </Button>}
            </Actions>
          )
        }
      </Form>
    </div>
  )
}


export default reduxForm({
  form: 'searchResult'
})(formValues('search')(SearchForm));
