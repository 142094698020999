// @flow

// Libraries
import React, { Element } from 'react';
import classNames from 'classnames';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Field } from 'redux-form';
import { ReactComponent as CheckboxIcon } from '@assets/icons/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@assets/icons/checked_checkbox.svg';

// Styles
import styles from './Input.scss';

type FormCheckboxProps = {
  name: String,
  label: String,
}

const renderCheckbox = (props: any): Element<'div'> => {
  const {
    input,
    label,
    classes,
    meta: { touched, error, invalid }
  } = props;

  return (
    <div>
      <FormControlLabel
        control={(
          <Checkbox
            classes={classes}
            defaultChecked={!!input.value}
            checked={!!input.value}
            onChange={input.onChange}
            onClick={() => input.onChange(!input.value)}
            data-checked={input.value}
            disableRipple
            color="primary"
            icon={<CheckboxIcon />}
            checkedIcon={<CheckboxCheckedIcon />}
          />
        )}
        label={label}
      />
    </div>
  );
}

const FormCheckbox = ({ name, label }: FormCheckboxProps): Element<Field> => (
  <Field name={name} type="checkbox" component={renderCheckbox} label={label} />
);

export default FormCheckbox;
