// @flow
import { CLOSE_MODAL, CLOSE_MODALS, OPEN_MODAL } from './types';

export type Action = {
  type: string,
  id?: string,
  payload?: any,
};

export const closeModal = (id: string): Action => ({ type: CLOSE_MODAL, id });

export const closeModals = (): Action => ({ type: CLOSE_MODALS });

export const openModal = (id: string, payload: Object): Action => ({
  type: OPEN_MODAL,
  id,
  payload,
});
