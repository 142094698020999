import { combineReducers } from 'redux';

// Reducers
import { reducer as form } from 'redux-form';

import session from './session';
import modals from './modals';
import loader from './loader';
import boards from './boards';
import tab from './tab';
import lightbox from './lightbox';
import favorites from './favorites';


const servicesReducer = combineReducers({
  form,
  session,
  modals,
  loader,
  boards,
  tab,
  lightbox,
  favorites
});

export default servicesReducer;
