// Components
import Dropzone from '@views/Builder/components/Dropzone';
import Slider from '@views/Builder/components/Slider';
import Carousel from '@views/Builder/components/Carousel';
import AssetButton from '@views/Builder/components/AssetButton';
import SeparatedLink from '@views/Builder/components/SeparatedLink';
import FigmaFrame from '@views/Builder/components/FigmaFrame';
import Card from '@views/Builder/components/Card';

// Types
import { WIKI_ENTITIES } from '@services/boards/types';

const {
  IMAGE,
  TWO_IMAGES,
  THREE_IMAGES,
  TWO_DO_IMAGES,
  THREE_DO_IMAGES,
  VIDEO,
  SLIDER,
  CAROUSEL,
  HORISONTAL_CARDS,
  VERTICAL_CARDS,
  ASSET_BUTTON,
  SEPARATED_LINK,
  FIGMA_FRAME
} = WIKI_ENTITIES;

export default {
  [IMAGE]: {
    component: Dropzone,
  },
  [TWO_IMAGES]: {
    component: Dropzone,
    props: {
      type: IMAGE,
    },
  },
  [THREE_IMAGES]: {
    component: Dropzone,
    props: {
      type: IMAGE,
    },
  },
  [TWO_DO_IMAGES]: {
    component: Dropzone,
    props: {
      withDo: true,
      type: IMAGE,
    },
  },
  [THREE_DO_IMAGES]: {
    component: Dropzone,
    props: {
      withDo: true,
      type: IMAGE,
    },
  },
  [SLIDER]: {
    component: Slider
  },
  [CAROUSEL]: {
    props: {
      withCaption: false
    },
    component: Carousel
  },
  [VIDEO]: {
    props: {
      type: VIDEO,
      withCaption: false,
      external: true,
    },
    component: Dropzone
  },
  [HORISONTAL_CARDS]: {
    component: Card
  },
  [VERTICAL_CARDS]: {
    component: Card,
    props: {
      type: 'vertical',
    },
  },
  [ASSET_BUTTON]: {
    component: AssetButton
  },
  [SEPARATED_LINK]: {
    component: SeparatedLink
  },
  [FIGMA_FRAME]: {
    component: FigmaFrame
  },
};
