// @flow
import classNames from 'classnames';
import * as React from 'react';
import { get, join } from 'lodash';

// Utils
import { handlePrevent } from '@utils/event';

// Components
import Button from '@components/Button';
import Modal from '@components/Modal';
import TextField from '@components/TextField';

import {
    Image,
    Video,
    Thumbnail
} from './components';

// Styles
import styles from './Dropzone.scss';

// Hooks
import useDropzone from './hooks';

// Contexts
import DropzoneContext from './context';

// Data
import { TYPES } from './data';

export type DropzoneClassesType = {
  root?: string,
  label?: string
};

type PropTypes = {
  children?: React.Node,
  className?: String,
  name?: String,
  classes?: DropzoneClassesType,
};

const SCREENS = {
  [TYPES.IMAGE]: Image,
  [TYPES.VIDEO]: Video,
  [TYPES.THUMBNAIL]: Thumbnail,
};

const Dropzone = ({
  children,
  className,
  classes = {},
  name,
  ...props
}: PropTypes): React.Element<'div'> => {
  
  const {
    // props
    loading,
    slideIndex,
    slidesLength,
    data,
    type,
    external,
    disabled,
    // hook
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    // inner
    isModal, setIsModal,
    setLink,
    handleFocus,
    handleBlur,
    handleEdit,
    handleDelete,
    handleSlide,
    handleLink,
    getVariant,
  } = useDropzone(props);

  const Screen = SCREENS[type];
  
  return (
    <div
      className={classNames(
        className,
        classes?.root,
        styles.Root,
        {
          [styles.RootIsHover]: isDragActive,
          [styles.RootIsDisabled]: disabled,
        }
      )}
      {...getRootProps()}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <input
        name={name}
        className={styles.Input}
        type="file"
        {...getInputProps()}
      />
      <div className={classNames(classes?.label, styles.Label)}>
        <DropzoneContext.Provider 
          value={{
            loading,
            isModal, setIsModal,
            handleEdit,
            handleDelete,
            handleSlide,
            handleLink,
            slideIndex,
            slidesLength,
            data,
            external,
            disabled
          }}
        >
          {
            Screen ?
              <Screen variant={getVariant()} /> :
              join(acceptedFiles.map(item => get(item, 'name')), ',') || '+ Upload or drop files here'
          }
          <Modal
            classes={{
              container: styles.ModalContainer,
              content: styles.ModalContent
            }}
            title="Add video by link"
            onClick={handlePrevent((event) => {})}
            onClose={handlePrevent((event) => { setIsModal(false); })}
            open={isModal}
            maxWidth="md"
            fullWidth
          >
            <TextField
              className={styles.ModalInput}
              name="link"
              type="text"
              variant="outlined"
              onChange={handlePrevent((event) => setLink(get(event, 'currentTarget.value')))}
              placeholder="Paste link to video (YouTube, Vimeo, VK...)"
            />
            <Button
              className={styles.ModalButton}
              variant="contained"
              onClick={handlePrevent((event) => handleLink())}
            >
              {`Add ${get(data, 'type')}`}
            </Button>
          </Modal>
        </DropzoneContext.Provider>
      </div>
    </div>
  );
};

export default Dropzone;
