// @flow

// Libraries
import React from 'react';
import classNames from 'classnames';
import {
  checkOnDigits,
  checkOnSpecialSymbols,
  checkOnUpperAndLowerCase,
  minLength,
  passwordMustMatch
} from '../../../utils/validators';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { v4 as uuid4 } from 'uuid';

// Styles
import stylesReset from './ResetPassword.scss';

type Values = {
  new_password: string;
  confirm_password: string;
}

type PropTypes = {
  values?: Values;
}

type ValidateRule = {
  id: number;
  validate: (values: Values) => boolean;
  title: string
}

const validateRules: ValidateRule[] = [
  {
    id: uuid4(),
    validate: (values: Values) => !minLength(values.new_password),
    title: '10 characters or more'
  },
  {
    id: uuid4(),
    validate: (values: Values) => !checkOnUpperAndLowerCase(values.new_password),
    title: 'Upper + lowercase letters'
  },
  {
    id: uuid4(),
    validate: (values: Values) => !checkOnDigits(values.new_password),
    title: 'Digits from 0 to 9'
  },
  {
    id: uuid4(),
    validate: (values: Values) => !checkOnSpecialSymbols(values.new_password),
    title: 'Special characters'
  },
  {
    id: uuid4(),
    validate: (values: Values) => !passwordMustMatch(values.confirm_password, values),
    title: 'Passwords match'
  },
];

const ListValidateRules = ({ values }: PropTypes) => {
  return (
    <div>
      <p>Password must contain:</p>
      <ul>
        {
          validateRules.map((rule: ValidateRule): JSX.Element =>
            (
              <li
                className={classNames({
                  [stylesReset.Valid]: values && rule.validate(values)
                })}
                key={rule.id}
              >
                {rule.title}
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

export default connect(
  state =>
    ({
      values: getFormValues('enterNewPassword')(state)
    })
)(ListValidateRules);
