// @flow

// Libraries
import * as React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { get, find } from 'lodash';

// Components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

// Styles
import styles from './Tree.scss';

// Hooks
import useTree from './hooks';

// Icons
import LockIcon from '@material-ui/icons/Lock';

const Tree = (props: {session: { isEdit: boolean }}) => {
  const { pages, buffers, isLogin, session, handleClick, collapsed, page } = useTree(props);
  const { pathname } = useLocation();
  return (
    <List className={styles.Root}>
      {
        pages.filter(p => !isLogin ? p.isExternal : p).map((item) => {
          const buffered = isLogin && get(buffers, `[${get(item, 'id')}]`);
          const current = buffered || item;
          const isSaved = !buffered;
          return (
            <ListItem
              key={get(current, 'id')}
              className={styles.ListItem}
            >
              <ListItemText
                className={classNames(
                  styles.ListItemText,
                  {
                    [styles.ListItemTextNotSaved]: isLogin && !isSaved,
                    [styles.ListItemTextSelected]: get(current, 'id') === get(page, 'id')
                  }
                )}
                primary={(
                  <>
                    {get(current, 'title')}
                    {
                      ['admin'].indexOf(get(session, 'role')) >=0
                      && get(current, 'isLocked')
                      && get(current, 'user.id') !== get(session, 'id')
                      && (
                        <Tooltip title="Locked by other user" placement="top">
                          <LockIcon className={styles.LockIcon} />
                        </Tooltip>
                      )
                    }
                    {(!isSaved && props.session.isEdit)  && <Tooltip title="Unpublished changes" placement="top"><i /></Tooltip>}
                  </>
                )}
                onClick={handleClick(current)}
              />
              {
                get(current, 'sections', []).map((sub) => get(sub, 'title') && (
                  <Collapse
                    key={get(sub, 'id')}
                    in={!!find(collapsed, { id: get(current, 'id') })}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List>
                      <ListItem
                        className={styles.ListSubItem}
                      >
                        <ListItemText className={classNames({
                          [styles.ListSubItemText]: true,
                          [styles.Selected]: get(sub, 'link') === pathname,
                        })} primary={get(sub, 'title')} onClick={handleClick(sub)} />
                      </ListItem>
                    </List>
                  </Collapse>
                ))
              }
            </ListItem>
          );
        })
      }
    </List>
  )
};

const mapStateToProps = (state: Object): Object => ({
  pages: get(state, 'boards.list.wiki.pages', []),
  buffers: get(state, 'boards.buffers', {}),
  session: get(state, 'session', {}),
});

const mapDispatchToProps = (dispatch) => ({
  // Services
});

// Exports
export default compose(connect(mapStateToProps, mapDispatchToProps))(Tree);
