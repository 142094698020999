// @flow

// Libraries
import React, { Element, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { reduxForm } from 'redux-form';

// Components
import Form, { Captcha, Input, TextArea } from '../../../components/Form';
import Fieldset from '../../../components/Form/components/Fieldset';
import { Box, Button } from '@material-ui/core';
import Actions from '../../../components/Form/components/Actions';
import { requiredField, checkValidEmail } from '../../../utils/validators';

// Styles
import stylesContactUs from './ContactUs.scss';
import styles from '../Profile.scss';

type PropTypes = {
  handleSubmit: Function;
  valid: boolean;
}

const ContactUsForm = ({ handleSubmit, valid }: PropTypes): Element<Box> => {
  const [disabled, setDisabled] = useState(true);

  useLayoutEffect(() => {
    setDisabled(!valid);
  }, [valid]);

  return (
    <Box className={stylesContactUs.Content}>
      <Form onSubmit={handleSubmit}>
        <Fieldset>
          <Input
            name="name"
            placeholder="Your name"
            type="text"
            validate={[requiredField]}
            outlined={true}
            classes={{input: styles.Name}}
          />
          <Input
            name="email"
            placeholder="Email"
            type="text"
            validate={[requiredField, checkValidEmail]}
            outlined={true}
            classes={{input: styles.Email}}
          />
          <TextArea
            name="message"
            placeholder="Your message"
            validate={[requiredField]}
          />
        </Fieldset>
        <Box className={stylesContactUs.Code}>
          <Captcha name="captcha"/>
          {/* <Input
            name="code"
            placeholder="Code"
            type="text"
            validate={[requiredField]}
            outlined={true}
            classes={{input: styles.Code}}
          /> */}
        </Box>
        <Actions classes={{root: classNames(stylesContactUs.Actions)}}>
          <Button
            variant="contained"
            disabled={disabled}
            type="submit"
            className={classNames(stylesContactUs.Button)}
            disableRipple
          >
            Send
          </Button>
        </Actions>
      </Form>
    </Box>
  )
}

export default reduxForm({
  form: 'contactUs'
})(ContactUsForm);
