// @flow

// Libraries
import React, { Element } from 'react';
import { Route, Link } from 'react-router-dom';
import classNames from 'classnames';

// Components
import PaginationItem from '@material-ui/lab/PaginationItem';
import Pagination from '@material-ui/lab/Pagination';

// Styles
import paginationStyles from './Pagination.scss';

type PropsType = {
  count: number;
}

const CustomPagination = ({ count }: PropsType): Element<'div'> => {
  return (
    <Route>
      {({ location }) => {
          const query = new URLSearchParams(location.search);
          const page = parseInt(query.get('page') || '1', 10);
          const key = location.pathname.split('/').pop();
          return (
            <div className={classNames(paginationStyles.Pagination)}>
              <Pagination
                page={page}
                count={Math.ceil(count / 8)}
                defaultPage={1}
                siblingCount={0}
                boundaryCount={1}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`/search-result/${key}${item.page === 1 ? '' : `?page=${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </div>
          );
        }}
    </Route>
  )
};

export default CustomPagination;

