// Libraries
import React from 'react';
import { get } from 'lodash';
import {
  useHistory,
} from 'react-router-dom';

// import { get } from 'lodash';
import Cookies from 'js-cookie';

// Hooks
import { useCreatePage, useUpdatePage } from '@hooks/data';


type PROPS_TYPE = {
  board: Object,
  pages: Array<Object>,
  isEdit: Boolean,
  isLogin: Boolean,
  initialize?: Function,
  mainBannerData?: Object,
  anyTouched?: Boolean,
  formValues?: any
};

export default ({
  board = {},
  pages = [],
  isEdit,
  isLogin,
  initialize,
  mainBannerData,
  anyTouched,
  formValues
}: PROPS_TYPE) => {

  const history = useHistory();

  const [isModal, setIsModal] = React.useState(false);
  const [editButton, setEditButton] = React.useState('first');

  const {
    load: loadUpdatePage,
    loading: loadingUpdatePage,
    result: resultUpdatePage,
    error: errorUpdatePage,
  } = useUpdatePage();

  React.useEffect(() => {
    initialize({
      description: get(mainBannerData, 'description'),
      firstButtonTitle: get(mainBannerData, 'content.blocks.firstButton.title'),
      firstButtonLink: get(mainBannerData, 'content.blocks.firstButton.link'),
      secondButtonTitle: get(mainBannerData, 'content.blocks.secondButton.title'),
      secondButtonLink: get(mainBannerData, 'content.blocks.secondButton.link'),
      thirdButtonTitle: get(mainBannerData, 'content.blocks.thirdButton.title'),
      thirdButtonLink: get(mainBannerData, 'content.blocks.thirdButton.link'),
    });
  }, [mainBannerData]);

  const {
    load: handleCreate,
  } = useCreatePage({ board });

  const handleSubmit = (event) => {
    console.log('handleSubmit event', event);
  };

  return {
    board,
    pages,
    isEdit,
    isLogin,
    mainBannerData,
    history,
    isModal, setIsModal,
    editButton, setEditButton,
    handleCreate,
    handleSubmit,
    anyTouched,
    formValues,
    handleUpdatePage: loadUpdatePage
  };
};
