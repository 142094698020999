// Libraries
import React, { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

// Components
import { DropDown } from '../DropDown';
import Button from '../../../../components/Button';
import FavoriteItem from './FavoriteItem';

// Selectors
import { selectFavoriteAssets } from '../../../../services/favorites';

// Hooks
import { useGetFavoriteAssets } from '../../../../hooks/useGetFavoriteAssets';

// Icons
import { ReactComponent as HeartGrayIcon } from '@assets/icons/favorites/heart_gray.svg';
import { ReactComponent as ArrowIcon } from '@assets/icons/favorites/arrow.svg';

// Styles
import styles from './Header.scss';
import { useSelector } from 'react-redux';

const Favorites = () => {
  const history = useHistory();
  const favoriteAssets = useSelector(selectFavoriteAssets);
  const { getFavoriteAssets, loading } = useGetFavoriteAssets();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (favoriteAssets?.length) {
      setOpen(true);
    }
  };

  const handleShowAllFavorites = () => {
    history.push('/portal/assets?in_favorites=true');
  };

  useEffect(() => {
    if (!favoriteAssets?.length) {
      setOpen(false);
    }
  }, [favoriteAssets]);

  useEffect(() => {
    if (open) {
      getFavoriteAssets();
    }
  }, [open]);

  const renderFavoriteAssets = useMemo(() => {
    if (loading && favoriteAssets.length === 0) {
      return <div className={styles.Loader}>Please Wait...</div>;
    }

    return (
      <div className={styles.FavoritesList}>
        {favoriteAssets.map(item => (
          <FavoriteItem {...item} key={item.id} />
        ))}
      </div>
    );
  }, [favoriteAssets, loading]);

  return (
    <>
      <Button
        size="small"
        startIcon={<HeartGrayIcon height={20} width={20} />}
        className={classNames({
          [styles.FavoritesButton]: true,
          [styles.Active]: open
        })}
        onClick={handleOpen}
      >
        <span className={styles.ButtonText}>Favorites</span>
        <DropDown hidden={!open} className={styles.FavoritesBackDrop}>
          <div className={styles.Favorites}>
            <Button
              endIcon={<ArrowIcon />}
              className={styles.OpenAllFavorites}
              onClick={handleShowAllFavorites}
            >
              All Favorites
            </Button>
            {renderFavoriteAssets}
          </div>
        </DropDown>
      </Button>
      {open && <div className={styles.Modal} onClick={handleClose} />}
    </>
  );
};

export default memo(Favorites);
