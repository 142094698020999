// @flow

// Libraries
import React, { Element, useState } from 'react';

// Components
import { Button } from '@material-ui/core';

import Modal from '@components/Modal';
import Page from '@containers/Page';

import ContactUsForm from './ContactUsForm';

// Hooks
import { useContactUs } from '@hooks/data';

// Styles
import styles from './ContactUs.scss';

type PropsType = {
  onClose: () => void;
  isShowModal: (flag: boolean) => void
}

const ContactUs = ({ onClose, isShowModal }: PropsType): Element<Page> => {
  const [isShowMessageModal, setIsMessageModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const {
    load,
    loading,
    result
  } = useContactUs();

  React.useEffect(() => {
    if (loading || !result) { return; }
    setIsMessageModal(true);
  }, [loading, result]);

  const onSubmit = (formData) => {
    onClose(false);
    setEmail(formData.email);
    load({ variables: {
      userName: formData.name,
      userEmail: formData.email,
      body: formData.message,
      captcha: formData.captcha
    } });
  }

  return (
    <>
      <Modal
        title='Contact us'
        onClose={onClose}
        isOpened={isShowModal}
        classes={{
          container: styles.Container,
          title: styles.Title
        }}
      >
        <ContactUsForm onSubmit={onSubmit} />
      </Modal>
      <Modal
        isOpened={isShowMessageModal}
        onClose={(): void => setIsMessageModal(false)}
        classes={{
          close: styles.CloseIcon,
          container: styles.AltContainer
        }}
      >
        <div className={styles.SendTitle}>Send</div>
        <div className={styles.Message}>
          Your message has been successfully send.
          <br />
          We contact you by your e-mail
          {' '}
          <strong>{email}</strong>
        </div>
        <Button
          onClick={(): void => setIsMessageModal(false)}
          variant="contained"
          color="primary"
          className={styles.CloseButton}
          disableRipple
        >
          Close
        </Button>
      </Modal>
    </>
  )
}

export default ContactUs;


